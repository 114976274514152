#vue_header {
  // Coeur du Var
  .header-coeurduvar .container-fluid {
    background-color: #96368b;

    .main-nav ul li a {
      color: white;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .main-nav .active {
      background-color: white;
      border-top: none;
      height: 100%;

      a {

        color: black !important;
      }
    }
  }
// Fin Coeur du Var
  .user-menu-bloc {
    top: 100%;
    right: 0;
    width: 300px;
    margin-top: 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0 1px 0, rgba(17, 17, 26, 0.1) 0 8px 24px, rgba(17, 17, 26, 0.1) 0 16px 48px;
    opacity: 0;
    visibility: hidden;
    z-index: 50;
    cursor: default;
    transition: all 0.3s ease-in-out;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  .user-menu-head {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;

    &__title {
      position: relative;
      width: 100%;
      margin-bottom: 15px;
      padding: 15px 0;
      background-color: var(--primary-color);
      color: white;
      text-align: center;
      font-family: $font-1;
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: uppercase;

      a {
        display: flex;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        padding: 0 10px;
        border-left: 1px solid white;
        align-items: center;
        line-height: 1rem;
        text-align: center;
        background: $red;

        &:hover, &:focus {
          color: white;
          text-decoration: none;
        }

        i {
          font-size: 1.6rem;
          margin-left: -1px;
        }
      }
    }
    &__avatar, &__name {
      display: inline-block;
      vertical-align: top;
      font-size: 0;
    }
    &__avatar {
      width: 25%;
      padding-left: 15px;

      img {
        width: 50px;
        height: 50px;
      }
    }
    &__name {
      width: calc(75% - 15px);
      text-align: left;

      span {
        display: block;
        font-size: 1.2rem;
      }
      span:first-child {
        margin-bottom: 10px;
        font-size: 1.4rem;
        font-weight: 700;
      }
    }
  }
  .user-menu-content {
    position: relative;
    width: 100%;
    max-height: 350px;
    margin-bottom: 20px;
    padding: 0 15px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(33,33,33,0.2);
      border-radius: 5px;
    }
    &__perso {
      margin-bottom: 20px;

      .btn {
        display: block;
        padding: 15px 20px;
        border-radius: 10px;
        text-align: center;
        color: white;
        background-color: $dark;
        border: 1px solid $dark;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: var(--primary-color);
          border: 1px solid var(--primary-color);
        }
      }
    }
    &__pro, &__pos {
      a {
        display: block;
        padding: 10px 0 10px 10px;
        transition: all 0.3s ease-in-out;

        &:hover {
          text-decoration: none;
        }
      }
    }
    &__pos {
      a.has-myterminal {
        padding: 5px 0 5px 10px;
      }
      .myterminal {
        width: 50px;
        min-width: 50px;
      }
    }
    &__title {
      position: relative;
      width: 100%;
      margin-top: -1px;
      padding: 10px 0;
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
      font-family: $font-1;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      color: $grey;

      &.collapsed {
        i {
          transform: rotate(180deg);
        }
      }
      i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.8rem;
        transition: all 0.3s ease-in-out;
      }
    }
    &__list {
      text-align: left;
    }
  }
  .main-nav.pays-basque {
    .menu > li > a {
      font-size: 15px;
      text-align: center;
    }
    .menu:after {
      content: none;
    }
  }
  .container.pays-basque {
    max-width: 1400px;

    .menu {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > li {
        margin-right: 0;
      }
    }
  }
  // Responsive
  // Format bâtard pour les tablettes haute résolution
  @media (max-width: 1280px) and (min-width: 998px) {
    .main-nav {
      .menu > li {
        margin-right: 2.4rem;
      }
    }
  }
  // Mobile
  @media (max-width: 576px) {
    .header-content {
      .image-logo {
        img {
          max-height: 60px;
        }
      }
    }
    .header-user {
      width: 34px;
    }
  }
}


