#section-offre-cadeaux {
  // Override de container
  .container {
    @media (min-width: 992px) {
      max-width: 1440px;
    }
  }
  // Override pour les boutons dans la modal parce qu'ils ont la classe btn
  .btn.btn-secondary {
    color: $dark;
    background-color: transparent;
    border: none;
  }
  // Override taille de la modal (format bureau)
  .modal-dialog {
    @media (min-width: 992px) {
      max-width: 900px;
    }
  }
  #vue_login {
    border: 1px solid $light;
  }
  .border-top {
    border-top: 2px solid #E7E7E7 !important;
  }
  .payment-state {
    margin: 0 auto;
  }
  .checkout-progress-bar{
    margin: 0;

    li > span {
      font-size: 1.4rem;
      font-weight: 400;
      color: #000;

      &:after {
        font-size: 1.8rem;
      }
    }
    li.active > span {
        color: var(--primary-color);
    }
  }
  .toggle-display {
    display: flex;
    justify-content: flex-end;
  }
  .offre-bloc {
    margin-bottom: 20px;

    &__single {
      display: flex;
      height: 100%;
      padding: 15px;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #ddd;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: var(--primary-color);
      }

      &.disabled {
        .offre-img, .offre-textes {
          opacity: 0.5;
        }
      }
      .offre-infos {
        flex-direction: column;
        gap: 10px;
      }
      .offre-img {
        display: flex;
        width: auto;
        max-width: 200px;
        height: auto;
        max-height: 190px;
        align-items: center;
        margin: 0 auto 15px auto;

        img {
          margin: 0 auto;
          max-height: 190px;
        }
      }
      .offre-textes {
        text-align: center;

        .title {
          margin-bottom: 5px;
          font-family: $font-1;
          font-size: 1.4rem;
          font-weight: 700;
          text-transform: uppercase;
          color: $dark;
        }
        .sub-title {
          font-size: 1.2rem;
          color: $grey;
        }
        .desc {
          font-size: 1.2rem;
          color: $dark;
        }
      }
      .offre-action {
        width: 100%;
        text-align: center;
      }
    }
    &.as-list {
      .offre-infos {
        flex-direction: row;
        align-items: center;
      }
      .offre-content {
        display: flex;
        width: 100%;
        align-items: flex-start;
        gap: 10px;
      }
      .offre-img {
        max-width: 120px;
        max-height: 120px;
        margin: 0;
      }
      .offre-textes {
        flex: 1;
        text-align: left;
      }
      .offre-action {
        width: auto;
      }
    }
  }
  .offre-detail-img {
    text-align: center;

    img {
      max-width: 230px;
      margin: 0 auto 20px auto;
    }
    .gift-cards {
      width: 180px;
      height: 180px;
      margin: 0 auto 20px auto;

      > div {
        position: absolute;
        width: 100%;
        height: auto;
        top: 60px;
        left: 20px;
        border-radius: 10px;
        transform-origin: 0 100%;
        z-index: 5;

        &:nth-child(2) {
          transform: rotate(-10deg);
          z-index: 4;
        }
        &:last-child {
          transform: rotate(-20deg);
          z-index: 3;
        }
      }
    }
  }
  .offre-detail-content {
    margin-bottom: 30px;

    &__title {
      display: flex;
      height: 50px;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 15px;
      padding-left: 15px;
      background-color: var(--primary-color);
      color: white;

      p {
        display: inline-flex;
        margin: 0;
        font-family: $font-1;
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;

        span {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-left: 5px;
          font-size: 1.2rem;
          font-weight: 400;
          font-style: italic;
          text-transform: none;
          color: #AAAAAA;
        }
      }
    }
    &__pro {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      p {
        margin: 0 20px 0 0;
        color: $dark;
      }
      label {
        margin: 0;
      }
    }
    &__text {
      color: $dark;
    }
    &__basket {
      .input-bloc-number {
        margin-bottom: 15px;
      }
      .add-cart {
        height: 40px;
        padding: 0 20px;
      }
      p {
        font-size: 1.2rem;
      }
    }
    .error {
      color: $red;
      font-size: 1.2rem;
    }
  }
  .selector-bloc {
    display: flex;
    margin: 20px 0;
  }
  .sidebar-basket {
    .widget {
      border: 1px solid #DDDFE4;
    }
    .widget-title {
      padding: 15px 2rem;
    }
  }
  .cart-list-articles {
    .quantity-selector {
      vertical-align: top;

      .quantity-input {
        width: 40px;
        height: 25px;
        font-size: 1.2rem;
      }
    }
    .article-single {
      position: relative;
      height: 40px;
      margin-bottom: 15px;
      justify-content: space-between;

      &-libelle, &-title, &-total, &-actions {
        display: inline-block;
        vertical-align: top;
        font-size: 0;
      }
      &-libelle {
        width: calc(100% - 75px);

        .article-single-title {
          width: calc(100% - 50px);
          margin-left: 10px;
          line-height: 25px;
          font-size: 1.4rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .article-single-actions + .article-single-title {
          width: calc(100% - 65px);
          margin-left: 0;
        }
      }
      &-total {
        min-width: 70px;
        text-align: right;

        span {
          font-size: 1.4rem;
          line-height: 25px;
          font-weight: 700;
          color: $grey;
          text-align: right;
        }
      }
      &-actions {
        width: 25px;
        font-size: 1.2rem;
        cursor: pointer;

        i {
          padding: 0 5px;
          line-height: 25px;
          color: $red;
        }
      }
    }
  }
  .cart-perso {
    position: relative;
    padding-top: 15px;
    border-top: 1px solid #CCC;

    &-details {
      margin: 15px 0;
      border-left: 3px solid $grey;

      p {
        margin: 0;
        padding-left: 10px;
        font-size: 1.2rem;
        color: $grey;
      }
    }
    .btn-modify-perso {
      position: absolute;
      top: 8px;
      right: 0;
      padding: 5px 8px;
      border: 1px solid var(--primary-color);
      background-color: white;
      color: var(--primary-color);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: var(--primary-color);
        color: white;
      }
    }
    span {
      font-weight: 700;
    }
  }
  .cart-pro {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;

    label.pro {
      margin-bottom: 0;
      padding: 20px 0;
      line-height: 1.2rem;
      font-weight: 400;
      color: $grey;
    }
    input {
      margin-right: 10px;

      &:checked {
        accent-color: var(--primary-color);

        + label {
          font-weight: 700;
          color: $dark;
        }
      }
    }
  }
  .cart-totals, .cart-subtotals {
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
    padding: 15px 0;
    border-top: 1px solid #CCC;
    font-weight: 700;
    color: $dark;
  }
  .cart-totals {
    border-bottom: 1px solid #CCC;
  }
  .cart-subtotals-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .price {
      min-width: 50px;
      font-weight: 700;
      text-align: right;
      color: $grey;
    }
  }
  .fa-level-up-alt {
    margin: 0 5px;
    color: $dark;
    transform: rotate(90deg);
  }
  .edit-infos-container {
    .btn-link {
      background-color: transparent;
      color: $dark;
      border: none;
    }
  }
  h2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: $font-1;
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .refus-commande, .attente-commande {
    .icon {
      display: block;
      margin: 0 auto 20px;
      text-align: center;
      font-size: 60px;
      color: var(--primary-color);
      opacity: 0.2;
    }
    .outline-dark {
      max-width: 150px;
      margin: 0 auto;
    }
  }
  .validation-commande {
    h2 {
      color: $green;
    }
  }
  .refus-commande {
    h2 {
      color: $red;
    }
  }
  .attente-commande {
    h2 {
      color: $orange;
    }
  }
  .comment-bloc {
    padding-top: 20px;
  }
  //-- Title Step --//
  .step-title {
    display: flex;
    height: 50px;
    padding: 0 0 0 15px;
    border: none;
    flex-direction: column;
    justify-content: center;
    font-family: $font-1;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    background: var(--primary-color);
    color: white;
  }
  .shipping-address-box-in {
    position: relative;

    &.active {
      border-color: var(--primary-color);

      &:after {
        background: var(--primary-color);
        position: absolute;
        top: 0;
        right: 0;
        width: 2.7rem;
        height: 2.7rem;
        padding-top: .2rem;
        color: #fff;
        font-family: 'porto', sans-serif;
        font-size: 1.6rem;
        line-height: 2.1rem;
        text-align: center;
        content: '\e84e';
      }
    }
  }
  //-- Societe --//
  .siret-bloc {
    .btn {
      padding: 10px 15px;
      border-radius: 0;
    }
  }
  // -- Delivery -- //
  .shipping-address-box {
    width: 50%;
    padding: 0 5px;
    border: none;

    &-in {
      min-height: 240px;
      padding: 15px;
      border: 1px solid $light;
      transition: all 0.3s ease-in-out;

      .shipping-title {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $light;
        line-height: 2rem;
      }
      .shipping-price {
        position: absolute;
        right: 6px;
        bottom: 1px;
        padding: 0 5px;
        font-family: $font-1;
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
  }
  //-- Billing -- //
  .billing-step-addresses {
    margin-bottom: 15px;

    .shipping-address-box {
      min-height: 140px;

      &-in {
        min-height: 140px;

        &.active {
          .address-box-action {
            display: none;
          }
        }
        .address-box-action {
          position: absolute;
          bottom: -1px;
          right: -1px;

          .btn {
            padding: 5px 10px;
            border-color: $light;
            border-radius: 0;
            background-color: white;
            color: $grey;
          }
        }
      }
    }
  }
  .btn-new-address {
    width: 180px;
    margin: 0 auto;
  }
  //-- Paiement --//
  .cb-single {
    &-container {
      position: relative;
      display: flex;
      width: 100%;
      height: 65px;
      margin-bottom: 10px;
      padding: 0 15px;
      border: 1px solid $grey;
      border-radius: 10px;
      align-items: center;
      overflow: hidden;
      transition: all 0.3s ease-in;

      &.active {
        border-color: var(--primary-color);
      }
      &.error {
        border-color: $red;

        .cb-single__state {
          background: $red;
        }
      }
    }
    &__logo {
      margin-right: 10px;
    }
    &__name, &__holder, &__expiration {
      font-size: 1.4rem;
    }
    &__name {
      width: 30%;
      font-weight: 700;
    }
    &__holder {
      width: 30%;
    }
    &__expiration {
      width: 60px;
      text-align: right;
    }
    &__cvc {
      display: flex;
      width: calc(40% - 130px);
      justify-content: center;

      .input-red {
        input {
          border-color: $red;
        }
        label {
          color: $red;
        }
      }
      .input-bloc {
        margin-bottom: 0.8rem;

        &:focus-within label {
          top: -8px;
        }

        input {
          width: 50px;
          height: 30px;
          padding: 8px 0 0 0;

          &:not(:placeholder-shown) + label {
            top: -8px;
          }
        }
        label {
          top: 10px;
        }
      }
      .cvc-txt {
        font-weight: 700;
        color: $green;
      }
    }
    &__action {
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
    }
    &__state {
      position: absolute;
      padding: 5px 10px;
      top: 0;
      right: 0;
      border-radius: 0 0 0 10px;
      font-size: 1.2rem;
      line-height: 1em;
      background: var(--primary-color);
      color: white;

      span {
        font-weight: 700;
      }
    }
  }
  //-- Pas d'offres --//
  .no-offers-bloc {
    min-height: 30vh;

    &-content {
      position: relative;
      width: 250px;
      height: auto;
      margin: 15px auto;

      #stars {
        position: absolute;
        width: 70%;
        top: 10px;
        left: -10px;
      }
    }
  }
  //-- Panier vide --//
  .cart-empty {
    text-align: center;
    opacity: 1.0;

    img {
      width: 50%;
      max-width: 150px;
      height: auto;
      margin: 0 auto 10px auto;
    }

    p {
      margin-bottom: 0;

      &:nth-child(2) {
        margin-bottom: 10px;
        font-size: 1.6rem;
      }
    }
  }
  // Responsive
  @media (max-width: 992px) {
    #vue_offre_selection {
      margin-bottom: 30px;
    }
    .offre-bloc {
      margin: 0 auto 20px auto;
    }
  }
  @media (max-width: 768px) {
    .carousel-offres {
      &__single {
        img {
          max-width: 230px;
        }
      }
      .slick-slide {
        width: 230px;
      }
    }
    .offre-detail-img {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .gift-cards {
        width: 120px;
        height: 130px;
        margin: 0;

        & > div {
          top: 30px;
          left: 10px;
        }
      }

      img {
        display: inline-block;
        width: 40%;
        margin: 0 0 20px 0;
      }
    }
    .shipping-address-box {
      width: 100%;
    }
    .cb-single {
      &-container {
        height: inherit;
        padding: 15px;
        flex-wrap: wrap;
      }
      &__name {
        width: calc(50% - 60px);
      }
      &__holder {
        width: 50%;
      }
      &__expiration {
        width: calc(50% - 60px);
        height: 40px;
        margin-left: 60px;
        line-height: 4rem;
        text-align: left;
      }
      &__cvc {
        width: calc(50% - 30px);
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: 576px) {
    .offre-bloc {
      margin: 0 0 20px 0;

      &.as-list {
        .offre-infos {
          flex-direction: column;
        }
        .offre-img {
          width: auto;
          max-width: 85px;
          max-height: 85px;
        }
        .offre-textes {
          width: 100%;
        }
        .offre-action {
          width: 100%;
          text-align: right;
        }
      }
      .btn {
        padding: 10px 15px;
      }
    }
    .cb-single {
      &__name, &__holder {
        width: calc(100% - 60px);
      }
      &__holder {
        margin-top: 10px;
        margin-left: 60px;
      }
      &__expiration, &__cvc {
        margin-top: 5px;
      }
      &__cvc {
        justify-content: center;
      }
    }
  }
}
