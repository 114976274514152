#vue_events {
  h2 {
    color: white;
  }
  .container {
    padding: 30px 20px;
    background-color: $red;
  }
  .events-single {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    background-color: white;

    &__date {
      display: flex;
      width: 200px;
      padding-right: 15px;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #D1D1D1;

      .double-date {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .date-single {
          width: 45%;
        }
        .fleche {
          width: 10%;
        }
      }
      .date-single {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__jour, &__num, &__mois {
          text-align: center;
          text-transform: uppercase;
        }
        &__jour {
          color: $red;
          font-size: 1.2rem;
          font-weight: 600;
        }
        &__num {
          color: $dark;
          font-size: 3.2rem;
          line-height: 3.4rem;
          font-weight: 700;
        }
        &__mois {
          color: $grey;
          font-size: 1.2rem;
          line-height: 2rem;
          font-weight: 600;
        }
      }
    }
    &__content {
      width: calc(100% - 200px);
    }
    &__img {
      display: flex;
      width: 200px;
      max-height: 150px;
      margin: 0 15px;
      justify-content: center;

      img {
        max-height: 100%;
      }
    }
    &__desc {
      width: calc(100% - 230px);
    }
  }
  .add-event {
    &__date, &__desc {
      display: inline-block;
      width: 30%;
      vertical-align: top;
    }
    &__desc {
      display: inline-flex;
      height: 75px;
      margin-left: 5%;
      align-items: center;

      p {
        font-size: 1.2rem;
      }
    }
  }
  .event-menu {
    i {
      font-size: 2rem;

      &.fa-ellipsis-h {
        color: var(--primary-color);
      }
    }
  }
  // Responsive
  @media (max-width: 992px) {
    .events-single {
      flex-direction: column;

      &__date {
        width: 100%;
        padding-right: 0;
        padding-bottom: 15px;
        border-right: none;
        border-bottom: 1px solid #D1D1D1;

        .double-date {
          width: 200px;
        }
      }
      &__content {
        width: 100%;
        margin: 15px 0 0 0;
      }
      &__img {
        margin: 0 15px 0 0;
      }
      &__desc {
        width: calc(100% - 215px);
      }
    }
    .add-event {
      &__date {
        width: 49%;
      }
      &__desc {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 560px) {
    .events-single {
      position: relative;

      &__content {
        flex-direction: column;
        align-items: center;
      }
      &__img {
        width: 100%;
        max-height: 300px;
        margin: 0 0 15px 0;
      }
      &__desc {
        width: 100%;
      }
      .admin-dropdown {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
}