//-- Pour des inputs sexy --//
.component-input-bloc {
  position: relative;

  .input-bloc {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    z-index: 5;

    &:focus-within {
      input, .simple-select {
        background: transparent;
        border-bottom: 1px solid var(--primary-color);
      }
      label, label.tiny {
        top: 0;
        font-size: 1.2rem;
        color: var(--primary-color);
        font-weight: 700;
      }
    }
    &.disabled {
      opacity: 0.5;
    }
    &.quizz-type {
      width: calc(50% - 10px);
    }
    &.input-plain {
      & + .input-error-msg {
        top: 100%;
      }
      input, textarea {
        border-bottom: 1px solid $light;
        border-radius: 10px;
        background: $light !important;

        &:not(:placeholder-shown) {
          border-color: $light;

          & + label {
            top: -8px;
            opacity: 1;
            z-index: 0;
          }
        }
      }
      input {
        height: 42px;
        padding: 0 15px;
      }
      textarea {
        height: inherit;
        padding: 15px;
      }
      label {
        left: 15px;
        opacity: 0;
      }
    }
    &.input-error {
      &:focus-within {
        label, label.tiny {
          color: $red;
        }
      }
      input, .simple-select {
        border-color: $red;

        &:not(:placeholder-shown) {
          border-color: $red;

          & + label, & + label.tiny {
            color: $red;
          }
        }
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .input-time {
      &-placeholder {
        position: relative;
        width: 95px;
      }
      &-icon {
        position: absolute;
        top: 12px;
        right: 10px;
        font-size: 1.6rem;
        color: $grey;
        z-index: 2;
      }
    }
    .vue__time-picker {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;

      .controls {
        display: none;
      }
      .select-list {
        ul {
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgba(33,33,33,0.2);
            border-radius: 5px;
          }
        }
        li {
          &.active, &:hover {
            background: var(--primary-color);
          }
        }
      }
      input {
        width: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
    // Spécifique pour le v-select qui a une valeur (js obligé)
    .has-value {
      & + label, & + label.tiny {
        top: 0;
        font-size: 1.2rem;
        color: var(--primary-color);
        font-weight: 700;
      }
    }
    input, textarea, .simple-select {
      width: 100%;
      height: 50px;
      margin: 0;
      padding: 15px 0 0 0;
      font-size: 1.4rem;
      border: none;
      border-bottom: 1px solid $grey;
      border-radius: 0;
      color: $dark;
      background-color: transparent;
      outline: none;
      // Nice hack pour l'autocomplétion
      &:-webkit-autofill {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
      &:focus {
        background-color: transparent;
      }
      &:not(:placeholder-shown) {
        background: transparent;
        border-bottom: 1px solid var(--primary-color);

        & + label, & + label.tiny {
          top: 0;
          font-size: 1.2rem;
          color: var(--primary-color);
          font-weight: 700;
        }
      }
    }
    label {
      position: absolute;
      top: 20px;
      left: 0;
      font-weight: 400;
      color: $grey;
      font-size: 1.4rem;
      z-index: -1;
      transition: all 0.15s ease-in-out;

      &.tiny {
        font-size: 1.4rem;
      }
    }
  }
  .input-error-msg {
    position: absolute;
    width: 100%;
    top: 110%;
    right: 0;
    color: $red;
    font-size: 1.2rem;
    text-align: right;
  }
}
//-- Input Select classique --//
.input-plain {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  z-index: 5;

  select {
    width: 100%;
    height: 42px;
    padding: 0 15px;
    border-radius: 10px;
    border-color: #F0F2F5;
    appearance: none;
    outline: none;
  }
  label {
    position: absolute;
    top: -8px;
    left: 15px;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-color);
    z-index: 0;
  }
}
.input-unit {
  position: absolute;
  right: 0;
  bottom: 5px;
  padding: 5px;
  border: 1px solid $grey;
  color: $grey;
}
.input-action {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer;

  &[aria-label]:hover:after {
    content: attr(aria-label);
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 5px 10px;
    background: $dark;
    color: white;
  }

  .fa-check {
    color: $green;
  }
}
.input-number-bloc {
  position: relative;

  &.input-error {
    input {
      color: $red;
    }
  }

  label {
    color: $dark;
    font-family: $font-1;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
  }
  input, .simple-select {
    display: block;
    width: 130px;
    padding: 0;
    border: none;
    font-family: $font-1;
    font-size: 2.4rem;
    font-weight: 700;
    text-align: right;
    color: var(--primary-color);
    background: #EAEAEA;
    outline: none;
  }
}
//--- Input plus classique --//
.input-number {
  input {
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    text-align: right;
  }
}
//--- Spécifique aux transactions --//
.transaction-header {
  .my-date-picker {
    .input-group {
      flex-wrap: nowrap;
    }
  }
  .style-chooser {
    min-width: initial !important;
  }
}
//--- Spécifique aux inputs "time" (permet de déclencher le sélecteur sur l'input entière) ---//
//input[type="time"]::-webkit-calendar-picker-indicator {
//  position: absolute;
//  width: auto;
//  height: auto;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  background: transparent;
//  color: transparent;
//  cursor: pointer;
//}
@media (max-width: 576px) {
  .input-bloc {
    input, .simple-select {
      font-size: 1.4rem;
    }
  }
}
//--- Spécifique à la page pro ---//
.card-socials {
  .component-input-bloc {
    .input-bloc {
      margin-bottom: 0;

      input {
        height: 30px;

        &:not(:placeholder-shown) + label {
          display: none;
        }
      }
    }
  }
}
// Spécifique au vue time picker avec "append-to-body"
.vue__time-picker-dropdown {
  ul {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(33,33,33,0.2);
      border-radius: 5px;
    }
    li:not([disabled]) {
      &.active {
        background: var(--primary-color) !important;
      }
    }
  }
}
