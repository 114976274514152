.quantity-selector {
  display: inline-block;
  font-size: 0;

  &-label {
    margin-bottom: 10px;
    color: $dark;
    font-family: $font-1;
    font-size: 1.4rem;
    font-weight: 400;
  }
  .actions, .quantity-input {
    display: inline-block;
    vertical-align: top;
  }
  .actions {
    width: 40px;
    height: 40px;
    border: 1px solid $grey;
    font-size: 1rem;
    cursor: pointer;
  }
  .quantity-input {
    width: 50px;
    height: 40px;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    font-size: 1.6rem;

    &.bordered {
      border: 1px solid $grey;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      text-align: center;

      &[type="number"] {
        -moz-appearance: textfield;
      }
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

}