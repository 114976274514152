#vue_header_pro, #vue_page_pro {
  .fa-trash {
    color: $red;
  }
  .ck-content {
    min-height: 150px;
  }
  .socials-bloc {
    a {
      text-decoration: none;
    }
  }
}
// Fix pour Treiz'local
#collapse_schedule,
#collapse_schedule-retrait {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}
//--- HEADER PRO ---//
#vue_header_pro {
  position: relative;

  &.no-header-bg {
    .header-pro-img-cover {
      max-height: 120px;
      background-color: #F0F2F5;
    }
    .header-pro {
      padding-top: 100px;
    }
  }
  .admin-panel-pro {
    position: absolute;
    top: 25px;
    right: 25px;

    .admin-panel-btn {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background-color: white;

      i {
        font-size: 1.6rem;
        color: var(--primary-color);
      }
    }
  }
  .header-pro {
    padding-top: 200px;

    &-img-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 215px;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    &-content {
      position: relative;
      background-color: white;
      z-index: 10;

      &.modal-is-open {
        z-index: 1000;
      }
      &__logo {
        width: 110px;
        height: 110px;
        border-radius: 11px;
        background-color: white;
        overflow: hidden;
        box-shadow: 2px 4px 4px 0 #00000033;

        .slim-btn-group {
          button {
            width: 28px;
            height: 28px;
          }
        }
        .slim-area {
          .slim-status {
            position: absolute;
            padding: 0 1.5em;
          }
        }
      }
      &__activity {
        max-width: calc(100% - 125px);
        margin-bottom: -5px;

        span {
          font-size: 1.2rem;
        }
      }
      &__schedule, &__address, &__tel {
        display: flex;
        min-height: 40px;

        .info-icon, .info {
          display: flex;
          height: 40px;
          align-items: center;
        }
        .info {
          justify-content: space-between;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: $dark;
        }
        .info-icon {
          width: 20px;
          margin-right: 10px;
          justify-content: center;
          font-size: 1.8rem;
          color: var(--primary-color);
        }
      }
      &__schedule {
        position: relative;
        max-width: 310px;

        #accordion,
        #accordion_retrait {
          width: 100%;
          margin: 0;
          padding: 0;
        }
        #heading_schedule,
        #heading_schedule-retrait {
          > span {
            &:after {
              content: '\f077';
              margin-top: -3px;
              margin-left: 20px;
              font-family: $fa;
              transition: all 0.3s ease-in-out;
            }
            &.collapsed:after {
              transform: rotate(180deg);
            }
            &.no-schedule:after {
              content: none;
            }
          }
        }
        .opened-state, .closed-state {
          font-weight: 700;
        }
        .opened-state {
          color: $green;
        }
        .closed-state {
          color: $red;
        }
        .horaires-pro {
          &-content {
            font-size: 1.2rem;

            .active {
              border: 1px solid var(--primary-color);
              font-weight: 700;
              color: var(--primary-color);
            }
            .jour-horaire {
              width: 90px;
              font-weight: 700;
            }
            p {
              margin-bottom: 0;
              padding: 10px;
            }
          }
        }
      }
      &__actions {
        display: flex;
        padding: 20px 0;
        justify-content: space-evenly;
        border-top: 1px solid $light;
        border-bottom: 1px solid $light;
      }
    }
    .content-logo-activity {
      display: flex;
      position: absolute;
      padding: 0 25px;
      top: -70px;
      align-items: flex-end;
      gap: 15px;
    }
    .content-pro {
      padding: 65px 25px 0 25px;

      h1 {
        font-size: 2.2rem;
        font-weight: 700;
      }
    }
    .content-actions {
      margin-top: 10px;

      .action-circle {
        display: flex;
        width: 40px;
        height: 40px;
        margin: 0 auto 5px auto;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--primary-color);
        border-radius: 20px;
        color: var(--primary-color);
        font-size: 1.8rem;
        transition: all 0.3s ease-in-out;

        &:hover, &:focus, &:active {
          color: white;
          background-color: var(--primary-color);
        }
      }
      span {
        font-weight: 700;
      }
    }
    .content-navigation {
      padding: 0 25px;

      .navigation-pro {
        flex-wrap: nowrap;
        gap: 20px;
        overflow-x: auto;
        overflow-y: hidden;

        .nav-item {
          .nav-link {
            padding: 25px 0 5px 0;
            border-bottom: 3px solid transparent;
            font-size: 1.6rem;
            font-weight: 700;
            color: $grey;
            cursor: pointer;

            &.active {
              color: var(--primary-color);
              border-color: var(--primary-color);
            }
          }
        }
      }
    }
  }
  a:hover, a:focus, a:active {
    text-decoration: none;
  }
}
//--- /HEADER PRO ---//
//--- CONTENT PRO ---//
#vue_page_pro {
  padding: 20px 0;
  background-color: #F0F2F5;

  .edit-pro-admin {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.6rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  .card {
    padding: 0 25px;
    border-radius: 0;

    .card-header {
      padding: 15px 0 10px 0;
      text-transform: initial;

      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0;
      }
    }
    .card-body {
      min-height: 30px;
      padding: 15px 0;
    }
    .edit-description {
      font-size: 1.2rem;
    }
  }
  .card-maps {
    .change-address-form {
      border-bottom: 1px solid #DDDFE4;
    }
  }
  .card-description {
    .card-header {
      p {
        font-weight: initial;
      }
    }
  }
  .card-loyalty{
    .col-loyalty{
      width: 100%;
    }
    .program-description {
      .text-title {
        font-weight: 700;
      }
    }
    .badge{
      margin-left: 5px;
      background: #ff7889;
      border-radius: 50px;
      color: var(--white-color);
    }
  }
  .card-socials {
    .socials-bloc {
      display: flex;
      flex-direction: column;

      .save-action {
        display: flex;
        justify-content: center;
      }
      .text-link {
        width: calc(100% - 50px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      a {
        display: flex;
        align-items: center;
        gap: 15px;
        text-decoration: none;
      }
    }
    .socials-single {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 10px;

      &__delete {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
      }
      .component-input-bloc {
        width: 100%;
        max-width: calc(100% - 70px);
      }
    }
  }
  .nav-select-mode {
    .nav-link {
      padding: 0 1rem;
      border-bottom: 2px solid transparent;

      &.active {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
  }
  .maps-top {
    padding-bottom: 15px;
    background-color: white;
    text-align: left;
  }
  // Custom carte de fidélité (pas d'image)
  .pro-card {
    &-default {
      width: 225px;
      height: 140px;
      background: linear-gradient(90deg, $grey 0%, var(--primary-color) 100%);
      color: white;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.3) 1.95px 1.95px 2.6px;
    }
    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    &-title, &-text {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    &-title {
      text-transform: uppercase;
      font-family: $font-1;
      font-size: 1.8rem;
      font-weight: 700;
    }
    &-text {
      font-size: 1rem;
      font-weight: 400;
    }
  }
  // Custom chèque de fidélité (pas d'image)
  .pro-check {
    &-default {
      position: relative;
      width: 225px;
      height: 100px;
      background: #EFEFEF;
      box-shadow: rgb(150, 150, 150) -3px -5px 7px inset;

      .corner {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: white;
        transform: rotate(45deg);

        &.top-right {
          top: -5px;
          right: -5px;
        }
        &.bot-left {
          bottom: -4px;
          left: -4px;
          border-top: 1px solid $dark;
        }
      }
    }
    &-block {
      width: 217px;
      height: 92px;
    }
    &-strip {
      position: absolute;
      width: 10px;
      height: 92px;
      top: 0;
      left: 0;
      background-color: $dark;
      z-index: 2;
    }
    &-couv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, $grey 0%, var(--primary-color) 100%);
      color: white;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      box-sizing: initial;
    }
    &-title, &-text {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    &-title {
      text-transform: uppercase;
      font-family: $font-1;
      font-size: 1.8rem;
      font-weight: 700;
    }
    &-text {
      font-size: 1rem;
      font-weight: 400;
    }
  }
  .service {
    &-single {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &__content {
        display: flex;
        align-items: baseline;
        gap: 5px;

        i {
          width: 20px;
        }
      }
    }
  }
}
//--- /CONTENT PRO ---//
//--- MODALS PRO ---//
#modalAdminPro {
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 70px;

    .el-input__inner{
      width: 100%;
    }
  }
  .label-day {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }
  .custom-select {
    height: 42px;
  }
  .btn-duplicate {
    padding: 0;
    width: 42px;

    i {
      font-size: 1.8rem;
      line-height: 40px;
    }
  }
  .horaires-bloc {
    [class*="col-"] {
      padding: 0;
    }
    p {
      line-height: 42px;
      margin: 0;
    }
  }
  // Pour les horaires
  .edit-horaires-actions {
    display: flex;
    width: 100%;
    height: 42px;
    align-items: center;
    gap: 20px;

    .vue-js-switch {
      margin-bottom: 0;
    }
  }
  .horaires-single {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    gap: 10px;

    &__clone {
      margin-right: 10px;

      i {
        font-size: 1.5rem;
      }
    }
    &__day {
      width: 90px;
      font-size: 1.4rem;
      line-height: 1em;
      font-weight: 700;
      text-transform: uppercase;
    }
    &__time {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .input-bloc {
      margin: 0;
    }
    .time-picker-text {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 700;
      color: $grey;

      &.danger {
        padding-left: 10px;
        color: $red;
      }
    }
  }
  // Pour les coordonnées
  .liste-destinataires-telecollecte {
    .btn-icon-circle {
      font-size: 1.2rem;
      color: white;

      &.fa-plus {
        background-color: $green;
      }
      &.fa-trash {
        background-color: $red;
      }
    }
  }
  // Pour les activités
  .title {
    width: 100%;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
    color: $grey;

    h5 {
      line-height: 32px;
    }
    p {
      margin: 0;
    }
  }
  .activite-single {
    display: inline-block;
    padding: 10px 15px;
    margin: 0 5px 5px 0;
    border: 1px solid $dark;
    vertical-align: top;
    color: $dark;

    .fa-star {
      color: #D79A10;
    }
    .fa-trash {
      margin-left: 5px;
      padding-left: 10px;
      border-left: 1px solid $light;
      color: $red;
    }
    i {
      position: relative;
    }
    span {
      padding-right: 5px;
      margin-right: 10px;
      border-right: 1px solid $light;
    }
  }
  .btn-add-activity {
    padding: 0 10px;
    border: 1px solid var(--primary-color);
    text-align: center;
    font-size: 1.4rem;
    line-height: 32px;
    background-color: white;
    color: var(--primary-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--primary-color);
      color: white;
    }
    i {
      margin-right: 5px;
    }
    p {
      margin: 0;
      text-transform: uppercase;
    }
  }
  .vs__actions {
    .vs__clear {
      fill: $red;
    }
  }
  // Référencement SEO
  .url-seo-bloc, .title-seo-bloc, .desc-seo-bloc {
    position: relative;
  }
  .url-seo-bloc {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    .component-input-bloc {
      width: 100%;
    }
  }
  .desc-seo-bloc {
    textarea {
      min-height: 100px;
    }
  }
  .aide-bloc {
    position: absolute;
    display: inline-block;
    max-height: 0;
    padding: 0;
    right: 35px;
    top: 10px;
    font-size: 1.2rem;
    background: white;
    overflow: hidden;
    opacity: 0;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    transition: opacity 0.3s ease-in-out;

    &.show {
      opacity: 1;
      max-height: initial;
      padding: 15px;
    }

    ul {
      padding-left: 15px;
      list-style-type: disc;
    }
  }
}
//--- /MODALS PRO ---//
//--- RESPONSIVE ---//
@media (min-width: 992px) {
  #vue_header_pro {
    &.no-header-bg {
      .header-pro {
        padding-top: 155px;

        &-img-cover {
          max-height: 175px;
        }
      }
    }
    .header-pro {
      padding-top: 280px;

      .content-pro {
        padding: 75px 25px 0 25px;
      }
      .content-logo-activity {
        top: -135px;
      }
      .content-actions {
        span {
          display: block;
        }
      }
      .content-navigation {
        .navigation-pro {
          border-top: 1px solid $light;
        }
      }
    }
    .header-pro-content__schedule, .header-pro-content__address, .header-pro-content__tel {
      .info, .horaires-pro-content {
        font-size: 1.4rem;
      }
    }
    .header-pro-content__schedule {
      max-width: 100%;
    }
    .header-pro-content__logo {
      width: 180px;
      height: 180px;
      border-radius: 18px;
    }
    .header-pro-content__activity {
      span {
        font-size: 1.4rem;
      }
    }
    .header-pro-content__tel {
      margin-bottom: 20px;
    }
    .header-pro-content__actions {
      padding-top: 0;
      border-top: none;
      border-bottom: none;
    }
  }
  #vue_page_pro {
    .card {
      .edit-description {
        font-size: 1.4rem;
      }
    }
  }
  .page-pro {
    background-color: #F0F2F5;
  }
}
@media (min-width: 576px) {
  #vue_header_pro {
    .header-pro-img-cover {
      max-height: 300px;
    }
    .header-pro {
      .content-actions {
        margin-top: 0;
        margin-bottom: 15px;
      }
    }
    .header-pro-content__activity {
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 992px) {
  #modalAdminPro {
    .horaires-single {
      padding-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid $light-grey;
    }
  }
}

@media (max-width: 576px) {
  .modalHorairesPro {
    .modal-header-custom {
      padding: 0 1rem;
    }
  }
  #vue_header_pro {
    .header-pro-content__activity {
      // Permet de faire un text-overflow sur 2 lignes
      span {
        display: -webkit-box;
        height: 34px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  #modalAdminPro {
    .edit-horaires-actions {
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    .horaires-single__time {
      width: 100%;
      flex-direction: column;
    }
  }
}

//--- OLD (à nettoyer/factoriser) ---//
.page-pro {
  #modalAddArticle {
    .modal-body {
      overflow-x: hidden;

      #add-article-pic {
        width: 200px;
        height: 200px;
      }
    }
  }
  #modalAddArticle2 {
    #add-article-pic {
      width: 185px;
      height: 185px;
    }
    @include respond-to('small') {
      .modal-footer {
        flex-direction: column;
        height: auto;
      }
      .modal-footer .btn {
        width: 100%;
        margin: 0;
      }
      .modal-footer .btn:nth-of-type(2) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  #modalEditPhoto {
    .editPhotoPro {
      .slim {
        min-height: 100px;
        background: none;
      }
    }
  }
  #modal-footer-modal-contact {
    display: block;
    height: auto;
    padding: 0;
  }
  #add-article-pic {
    margin-bottom: 1.4rem;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .ajout-article-pro, #modalEditArticlePro {
    right: 0;
    top: 2px;

    .btnAjoutArticle {
      padding: 0.5rem 1rem;
    }
    textarea.form-control {
      max-width: 100%;
    }
  }
  #modalEditActu {
    .modal-body {
      overflow-x: hidden;

      .slim {
        height: 100%;
      }
      .ck-editor .ck-editor__main .ck-content {
        min-height: 150px;
      }
    }
  }
  .image-article-cropper {
    width: 175px;
    height: 175px;

    &.add-image-article-cropper {
      width: 177px;
      height: 177px;
    }
    .slim-area {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .slim-status {
        height: 100%;
      }
    }
  }
  .headerArticle{
    border-bottom: 1px solid #dddfe4 !important;
  }
  .dropdownArticle{
    z-index: 9;

    #dropdownMenuLink{
      width: 32px;
      height: 32px;
      border: none;
      background: none;
      color: #777;

      i{
        font-size: 1.3rem !important;
      }
    }
  }
  .dropdown-menu {
    font-size: 1.2rem;
  }
  .feature-box p {
    font-size: 0.89em;
  }
  .product-carousel.product-default {
    figure {
      background: var(--white-color);
    }
  }
}
.opening-table-row {
  .active {
    background: var(--primary-color);
    color: var(--white-color);
    font-weight: bold;
  }
  p {
    margin: 0;
    padding: 0.75rem 0;
  }
}
.v-select-regles-fid {
  width: 100% !important;

  .vs__dropdown-toggle {
    height: 42px;
  }
}
