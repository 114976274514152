/* Change Bootstrap animation speed */
.fa-spin {
    --fa-animation-duration: 1s;
}
.select-img {
    margin-right: 8px;
    width: 30px !important;
    height: 30px !important;
}

.owl-carousel {
    padding: 1rem 0;
}
.owl-dots {
    padding-top: 1rem;
}
.history-body {
    padding: 2rem 0 0 0 !important;
}

/* Datatable vueGoodTable */
.footer__row-count__label,
.footer__row-count > form {
    margin-bottom: 0 !important;
    margin-top: 3px;
}
.espace-title {
    margin: 0;
    color: #000;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.2;
}
