#vue_galerie_images {
  // Override button
  .btn {
    padding: 7px 15px;
    border-radius: 10px;
    text-transform: initial;
  }
  .btn-icon-circle.suppress {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $red;
    cursor: pointer;
    z-index: 5;

    i {
      color: white;
    }
  }
  // Galerie
  .galerie-container {
    position: relative;

    .loading-screen {
      position: absolute;
      width: 100%;
      padding: 15px 0;
      top: 0;
      left: 0;
      font-weight: bold;
      background-color: rgba(256,256,256,0.8);
      z-index: 10;
    }
  }
  .galerie-column {
    opacity: 1;
    transition: all 0.3s ease-in-out;

    &.waiting {
      opacity: 0.5;
    }
  }
  // Thumbnails
  .galerie-img-thumbnail {
    position: relative;
    cursor: pointer;

    &:hover {
      img {
        opacity: 0.75;
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
  // Image viewer
  .image-viewer {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 50px 15px;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.9);
    opacity: 0;
    z-index: 1050;
    transition: opacity 0.3s ease-in-out;

    &.show {
      opacity: 1;

      .image-viewer-content {
        img {
          transform: scale(1);
        }
      }
    }

    &-content {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      img {
        max-height: 100%;
        transform: scale(0);
        transition: transform 0.3s ease-in-out;
      }
    }
    i {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 2.5rem;
      color: white;
      cursor: pointer;
    }
  }
  // Erreur
  .alert-danger {
    text-align: center;
  }
  // Admin
  .admin-panel {
    margin-bottom: 15px;
    text-align: right;

    #cropper-galerie {
      height: 120px;
      cursor: pointer;
    }
    .upload-bloc {
      margin-top: 15px;
      border: 2px dashed var(--primary-color);

      .slim-status {
        position: absolute;
        padding: 0 1.5em;
      }
      .slim-label {
        font-weight: 700;
        color: var(--primary-color);
      }
    }
  }
}