.marketplace-boutiques {
  .sidebar-wrapper.noTop {
    top: 0 !important;
  }
  .banner {
    text-align: center;

    h4, h1 {
      color: var(--white-color);
    }
    h4 {
      font-weight: lighter;
      font-size: 17px;
    }
    h1 {
      font-size: 30px;
    }
  }
  .products-body {
    .infosBoutique {
      border-bottom: 1px solid var(--primary-color);

      .btn.btn-primary {
        margin-bottom: 10px;
      }
    }
    h3 {
      color: var(--primary-color);
      font-weight: normal;
      padding-bottom: 10px;
      margin-bottom: 30px;

      a:hover {
        text-decoration: none;
      }
    }
    .productFullWidth.product-default .product-details {
      justify-content: start;
    }
    .product-default.product-with-timetable {
      figure {
        .product-reduc {
          background-color: var(--white-color);
          color: var(--black-color);
          font-weight: normal;
        }
      }
      .product-details {
        h2.product-title {
          a{
            font-size: 20px;
            font-weight: bolder;
            color: var(--primary-color);
          }
        }
        .price-box {
          .product-price {
            color: var(--black-color);
            font-size: 10px;
            font-weight: normal;

            strong {
              font-weight: bolder;
            }
            span {
              color: var(--primary-color);
              font-weight: bolder;
              margin-left: 5px;
              margin-right: 5px;

              &:after {
                content: '•';
                margin-left: 5px;
              }
            }
          }
        }
        .product-action{
          .btn-see-product{
            background-color: var(--white-color);
            border: 1px solid var(--white-color);
            font-size: 11px;
            font-weight: bold;
            color: var(--black-color);
            padding: 10px 6px;
          }
        }
      }
    }
    .sidebar-shop {
      .widget {
        h3.widget-title {
          border-bottom: none;
          margin-bottom: 0;

          a {
            text-transform: uppercase;
            font-weight: normal;
            color: var(--black-color);
          }
        }
        .widget-body {
          .noUi-connect {
            background: var(--primary-color);
          }
          .noUi-handle {
            background: var(--primary-color);
          }
        }
      }
    }
  }
}

@include respond-to('small') {
  .marketplace-boutiques .layout-btn.btn-list{
    display: none;
  }
}
@media (max-width: 575px) {
  .marketplace-boutiques .product-list.productFullWidth figure.col-4 {
    flex: auto;
  }
}