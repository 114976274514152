.content-pdv-titre-kdo{
  .card{
    .card-header{
      h2{
        color: #000;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1em;
      }
    }
    .card-body{

      .pdv{
        border-right: 1px solid #dddfe4;
        border-bottom: 1px solid #dddfe4;
        .title{
         img{
           max-width: 100px;
         }
        }
        a{
          text-decoration: none;
        }
        .kdo{
          img{
            max-height: 30px;
          }
        }
        .activite{
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
          font-weight: bold;
        }
        @media (max-width: 992px) {
          &:nth-child(odd){
            border-right: none !important;
          }
        }
        &:nth-child(3n){
          border-right: none;
        }
        .linkToPagePro{
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      .pdv.no-border{
        border-bottom: none;
      }
    }
  }
}

@media screen{
  .content-pdv-titre-kdo .card-body h3{
    background: none !important;
  }
}