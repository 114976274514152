/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
    cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
    color: var(--primary-color);
}

/* underline color for "Browse" button */
.filepond--label-action {
    text-decoration-color: var(--primary-color);
    color: var(--primary-color);
}

/* the background color of the filepond drop area */
.filepond--panel-root {
    background-color: transparent;
    border-radius: 4px;
}

/* the border radius of the file item */
.filepond--item-panel {
    border-radius: 4px;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
    background-color: #f6f9fc;
}

/* the background color of the drop circle */
.filepond--drip-blob {
    background-color: var(--primary-color);
}

/* the background color of the black action buttons */
.filepond--file-action-button {
    background-color: var(--primary-color);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
    color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:after {
    border-color: transparent;
}

.filepond--image-preview-overlay {
    display: none !important;
}

@media (max-width: 768px) {
    .filepond--image-preview-wrapper,
    .filepond--image-bitmap {
        display: none !important;
    }
}

/* the text color of the file status and info labels */
.filepond--file {
    color: #6675a4;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: green;
}

/* bordered drop area */
.filepond--panel-root {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.filepond--root {
    background-color: #f6f9fc;
    border: 2px dashed #dee0eb;
    cursor: pointer;
    border-radius: 4px;
    min-height: 68px;
    font-size: 1.3rem;
}

.filepond--item > .filepond--panel .filepond--panel-bottom {
    box-shadow: none;
}
