.btn-primary{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  &:hover, &:focus, &:active{
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--white-color) !important;
  }
}


::-moz-selection {
  background-color: var(--primary-color);
  color: var(--white-color); }

::selection {
  background-color: var(--primary-color);
  color: var(--white-color); }

.text-primary {
  color: var(--primary-color) !important; }

a {
  transition: all .3s;
  color: var(--primary-color);
  text-decoration: none; }

a:hover,
a:focus {
  color: var(--primary-color);
  text-decoration: underline; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item .nav-link.active{
  border-bottom-color: var(--primary-color);
  color: var(--primary-color);
}
.nav-tabs .nav-item .nav-link:hover{
  color: var(--primary-color);
}

.owl-theme .owl-dots .owl-dot span:before {
  background-color: var(--primary-color);}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  border-color: var(--primary-color); }
.owl-theme.owl-theme-light .owl-dots .owl-dot.active span, .owl-theme.owl-theme-light .owl-dots .owl-dot:hover span {
  border-color: var(--primary-color); }
.owl-theme.owl-theme-light .owl-dots .owl-dot.active span:before {
  border-color: var(--primary-color); }
.btn-primary {
  border-color: var(--primary-color);
  background-color: var(--primary-color); }
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  box-shadow: none; }
.btn-primary.disabled, .btn-primary:disabled {
  border-color: var(--primary-color);
  background-color: var(--primary-color); }
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  border-color: var(--primary-color);
  background-color: var(--primary-color);}
.btn-outline:hover, .btn-outline.focus {
  border-color: var(--primary-color);
  color: var(--primary-color); }
.btn-outline.disabled, .btn-outline:disabled {
  color: #ccc; }
.btn-outline:not(:disabled):not(.disabled):active, .btn-outline:not(:disabled):not(.disabled).active, .show > .btn-outline.dropdown-toggle {
  border-color: var(--primary-color);
  color: var(--primary-color); }
.btn-outline-secondary:hover, .btn-outline-secondary.focus {
  border-color: var(--primary-color);
  background-color: var(--primary-color); }

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  border-color: var(--primary-color);
  background-color: var(--primary-color); }
.btn-link {
  color: var(--primary-color); }
.btn-link:hover, .btn-link:focus {
  color: var(--primary-color); }

.btn-social-login {
  background-color: var(--primary-color);}
.porto-loading-icon {
  border-top-color: var(--primary-color);}
.card-header .card-edit {
  color: var(--primary-color);}
.feature-box i {
  color: var(--primary-color);}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--primary-color); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: var(--primary-color); }

.page-item.active .page-link {
  border-color: var(--primary-color); }
.page-link:hover, .page-link:focus {
  border-color: var(--primary-color);}
.layout-btn.active {
  color: var(--primary-color); }
.login-popup .forget-password {
  color: var(--primary-color); }
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span, .product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  border-color: var(--primary-color); }
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span:after, .product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span:after {
  background-color: var(--primary-color); }
.product-default a:hover {
  color: var(--primary-color); }
.product-default:hover .btn-add-cart {
  background-color: var(--primary-color);
  border-color: var(--primary-color); }
.product-select.type-image.checked, .product-select.type-image.hover {
  border: 1px solid var(--primary-color); }
.product-single-qty .btn.btn-down-icon:hover:before, .product-single-qty .btn.btn-down-icon:hover:after,
.product-single-qty .btn.btn-up-icon:hover:before,
.product-single-qty .btn.btn-up-icon:hover:after {
  background-color: var(--primary-color); }
.config-swatch-list li:hover span:last-child {
  color: var(--primary-color); }
.add-cart-box {
  border-top: 4px solid var(--primary-color); }
.add-cart-box h4 {
  color: var(--primary-color); }
.inner-quickview figure .btn-quickview {
  background-color: var(--primary-color);}
.inner-icon figure .btn-icon:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color); }
.left-details .btn-icon-wish:hover,
.left-details .btn-quickview:hover,
.left-details .btn-add-cart:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color); }
.hidden-description .btn-quickview {
  background-color: var(--primary-color); }
.hidden-description .btn-add-cart:hover {
  background-color: var(--primary-color);}
.overlay .product-details a:hover {
  color: var(--primary-color); }
.overlay-dark .product-details a:hover {
  color: var(--primary-color); }
.quantity-input .btn-add-cart:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);}
.product-list .btn-add-cart {
  background-color: var(--primary-color);
  border-color: var(--primary-color); }
.social-icon {
  background-color: var(--primary-color);}
.nav-tabs .nav-item .nav-link:hover {
  color: var(--primary-color); }
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: var(--primary-color);
  color: var(--primary-color); }
::-moz-selection {
  background-color: var(--primary-color); }

::selection {
  background-color: var(--primary-color); }
a {
  color: var(--primary-color); }

a:hover,
a:focus {
  color: var(--primary-color); }
.bg-primary {
  background-color: var(--primary-color) !important; }
.text-primary {
  color: var(--primary-color) !important; }
.category-slide:before {
  border-top-color: var(--primary-color);}
.noUi-handle {
  background: var(--primary-color); }
.cart-dropdown .btn-remove:hover, .cart-dropdown .btn-remove:focus {
  color: var(--primary-color); }
.menu > li:hover > a, .menu > li.show > a, .menu > li.active > a {
  color: var(--primary-color);}
.menu .megamenu {
  border-top: 3px solid var(--primary-color); }
.menu ul {
  border-top: 3px solid var(--primary-color); }
.main-nav .menu > li:hover > a, .main-nav .menu > li.show > a, .main-nav .menu > li.active > a {
  color: var(--primary-color); }
.cat-list li a:hover, .cat-list li a:focus {
  color: var(--primary-color); }
.config-size-list a:hover, .config-size-list a:focus, .config-size-list a.active {
  border-color: var(--primary-color);
  background-color: var(--primary-color); }
.filter-toggle.opened a {
  background-color: var(--primary-color); }
.product-single-details .price-box {
  color: var(--primary-color); }
.transparent-dots .owl-dot.active, .transparent-dots .owl-dot:hover {
  border-color: var(--primary-color); }
.product-desc .view-more {
  color: var(--primary-color);}
.widget-info i {
  color: var(--primary-color); }
.post-body .post-date .day {
  color: var(--primary-color); }
.post-body .post-date .month {
  background-color: var(--primary-color); }
.widget-search .search-submit {
  color: var(--primary-color);}
.tagcloud a:hover, .tagcloud a:focus {
  background-color: var(--primary-color); }
.contact-info i {
  background-color: var(--primary-color); }
.main-nav .menu > li:hover, .main-nav .menu > li.active {
  border-top-color: var(--primary-color); }
.home-product-tabs .quantity-input .btn-add-cart:hover {
  background: var(--primary-color); }
.history-header h5 a:not(.collapsed) {
  color: var(--primary-color);
  border-color: var(--primary-color); }
.features-section i {
  border: 2px solid var(--primary-color); }