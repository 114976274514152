.fid-card {
  &-bloc {
    display: flex;
    position: relative;
    width: calc(50% - 10px);
    min-height: 150px;
    padding: 15px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;

    &.add-card {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-style: dashed;

      i {
        font-size: 3.2rem;
        color: var(--primary-color);
      }
      p {
        font-size: 1.8rem;
        font-weight: 700;
        margin: 0;
      }
    }
  }
  &-img {
    width: 25%;

    img {
      width: auto;
      max-height: 115px;
    }
  }
  &-content {
    display: flex;
    width: 75%;
    flex-direction: column;
    justify-content: space-between;
  }
  &__label {
    padding: 15px 0 0 15px;
    color: var(--primary-color);
  }
  &__solde {
    display: flex;
    align-items: center;
    width: 100%;
    color: $dark;
    gap: 5px;

    i {
      width: 25px;
      height: 25px;
      border-radius: 15px;
      line-height: 25px;
      text-align: center;
      background: var(--primary-color);
      color: white;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;

    .btn-circle-icon {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      line-height: 1em;

      &.primary {
        background: var(--primary-color);
      }
      &.dark {
        background: $dark;
      }
      &.red {
        background: $red;
      }
      &.green {
        background: $green;
      }
      i {
        color: white;
      }
      svg {
        max-width: 18px;
        max-height: 18px;
        fill: white;
      }
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
  }
  &-status {
    position: absolute;
    padding: 8px 15px;
    top: -2px;
    right: -2px;
    border-radius: 0 20px;
    font-family: $font-1;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1em;
    color: white;
    text-transform: uppercase;
    z-index: 10;

    &.active {
      background: $green;
    }
    &.inactive {
      background: $red;
    }
  }
}
.wallet-google, .wallet-apple {
  height: 40px;
  padding: 0;
  background: transparent;

  img {
    height: 100%;
  }
}
.qrcode-bloc {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px;
  top: 0;
  left: 0;
  align-items: center;
  border-radius: 20px;
  background: white;
  z-index: 5;

  img {
    width: auto;
    height: 100%;
  }
  i {
    position: absolute;
    top: 30px;
    right: 15px;
    font-size: 2.4rem;
    cursor: pointer;
  }
}
.cards-carousel {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 1200px) {
  .fid-card__btn {
    gap: 5px;
  }
}
//-- RESPONSIVE --//
@media (max-width: 768px) {
  .fid-card {
    &-bloc {
      flex-direction: column;
    }
    &-img {
      width: 100%;

      img {
        margin: 0 auto;
      }
    }
    &-content {
      width: 100%;
    }
    &-actions {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
    &__label {
      padding: 10px 0;
      text-align: center;
    }
  }
  .qrcode-bloc {
    img {
      height: 80%;
    }
  }
}
@media (max-width: 576px) {
  .fid-card {
    &-bloc {
      width: 100%;

      &.add-card {
        min-height: initial;
        margin-top: 40px;
        flex-direction: row;
        gap: 10px;
        border-style: solid;
        border-radius: 10px;
        background: var(--primary-color);

        i, p {
          font-size: 1.6rem;
          line-height: 1em;
          color: white;
        }
      }
    }
  }
  .cards-carousel {
    display: initial;
  }
}

//-- OLD --//
.cartesfidelites{
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background: none;
  }
  .product-default{
    figure{
      height: 120px;
    }
    .label-group{
      left: unset;
      right: 0.8rem;
    }
    .product-title.title-carte-fidelite{
      color: var(--primary-color);
      font-weight: 600;
    }
    .program-description p{
      margin-bottom: 0;
    }
  }
  .d-flex.product-default.bg-white.align-items-center.position-relative{
    padding-left: 10px;
    padding-right: 10px;
  }
}