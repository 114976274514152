#vue_registerPdv{
  .form-control{
    height: 4.6rem;
    padding: 1.25rem 1.5rem;
    font-size: 1.3rem;
  }
  .resultSiret{
    border-radius: 4px;
    background-color: var(--white-color);
    border: 1px solid #e0e6f1;
    box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
    word-wrap: break-word;
    margin-bottom: 20px;
    padding: 10px;
    h3{
      font-size: 1.8rem;
    }
    p{
      margin-bottom: 0;
    }
  }
  //-- Spécifique à la connexion --//
  #vue_login {
    .content {
      padding: 0;
    }
  }
}

.secur-message {
  background-color: #e8f9f1;
  margin: 24px 0 0;
  padding: 12px 16px;
  span {
    color: #00c373;
    font-size: 12px;
    font-weight: 600;
  }
  img {
    margin: 0 0 5px 4px;
  }
}
