//-- Styles Souscription Fidélité --//
#vue_fidelite {
  // Override boutons //
  .btn {
    text-transform: none;
  }
  a.btn {
    display: initial;
  }
  // Override login //
  #vue_login {
    margin-top: 30px;

    .content {
      background: white;
    }
  }
  // Override inputs //
  .input-bloc {
    &.input-plain {
      input {
        height: 50px;
      }
    }
  }
  .input-plain {
    select {
      height: 50px;
    }
  }
  //-- Header --//
  .loyalty-header {
    height: 100px;
    padding: 0 25px;

    .go-home, .go-back {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      line-height: 4rem;
      text-align: center;
    }
    .go-home {
      background-color: var(--primary-color);
      color: white;
    }
    .go-back {
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
    }

    .img-logo {
      img {
        max-height: 100px;
      }
    }
    .header-icon {
      margin-right: 0;
    }
    .header-user {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      overflow: hidden;
    }
    a:hover, a:focus {
      text-decoration: none;
    }
  }
  //-- Body --//
  .loyalty-body {
    margin-top: 40px;

    h1, p {
      color: $dark;
      text-align: center;
    }
    h1 {
      margin: 15px 0;
      font-family: $font-1;
      font-size: 2rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .loyalty-home {
    .custom {
      &-frame, &-bloc, &-number, &-rule {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-number, &-text, &-rule {
        font-family: $font-1;
        font-weight: 700;
      }
      &-frame {
        width: 100%;
        margin: 40px 0;
        gap: 20px;
        flex-wrap: wrap;
      }
      &-bloc {
        width: 100%;
        max-width: 335px;
        padding: 20px;
        border: 3px solid var(--primary-color);
        border-radius: 15px;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        background: var(--primary-color-darker);
      }
      &-number {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        border-radius: 30px;
        font-size: 3.2rem;
        line-height: 1em;
        color: white;
        background: var(--primary-color);
      }
      &-text {
        margin-top: 20px;
        text-align: center;
        color: white;
      }
      &-rule {
        padding: 10px 20px;
        color: white;
        background: var(--primary-color);
      }
    }
    .actions {
      display: flex;
      width: 100%;
      max-width: 1045px;
      margin: 0 auto;
      justify-content: space-evenly;

      .darker {
        background-color: var(--primary-color-darker);
      }
    }
  }
  .loyalty-step {
    padding-top: 30px;

    &__type, &__infos, &__confirmation {
      position: relative;
      transition: opacity 0.3s ease-in-out;

      &.inactive {
        opacity: 0.5;
      }
      &.valid {
        .circle-icon {
          background: $green;
        }
      }
      &-content {
        padding: 20px;

        .loader {
          width: 100%;
          font-size: 3rem;
          text-align: center;
        }
      }
      p {
        margin-top: 15px;
      }
    }
    &__type {
      .type-single {
        display: flex;
        padding: 2px 15px;
        border: 3px solid $light;
        border-radius: 10px;
        align-items: center;
        background: $light;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          border-color: var(--primary-color);
        }
        img {
          max-height: 40px;
          margin-right: 10px;
        }
      }
    }
    &__confirmation {
      p:last-child {
        margin-top: 30px;
      }
    }
    &__infos-content {
      text-align: right;
    }
    &__infos-actions {
      display: flex;
      justify-content: flex-end;
    }
    .bg-step {
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 30px);
      top: 30px;
      left: 10px;
      background: var(--primary-color);
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s ease-in-out;

      &.active {
        opacity: 0.2;
      }
    }
    .circle-icon {
      position: relative;
      display: flex;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      font-size: 2.8rem;
      line-height: 1em;
      background: var(--primary-color);
      color: white;
      transition: all 0.3s ease-in-out;

      span {
        font-family: $font-1;
        font-size: 3.2rem;
        font-weight: 700;
      }
    }
  }
  .loyalty-create {
    &__confirm {
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        margin-bottom: 15px;
        font-size: 5rem;
        color: var(--primary-color);
      }
      p:last-child {
        margin-top: 30px;
      }
    }
    .loyalty-actions {
      display: flex;
      margin-top: 40px;
      justify-content: center;

      button:first-child {
        margin-right: 30px;
      }
    }
  }
}
#vue_bonachat {
  .nombre, .valeur-total {
    text-transform: none;
    font-weight: 400;
  }
  .valeur-total, .primary-color {
    color: var(--primary-color);
  }
  .card-header {
    display: flex;
    justify-content: space-between;

    &::after {
      display: none !important;
    }
  }
  .bonachat-table {
    padding: 1.2rem 1.5rem;
    .col-montant {
      text-align: right;
    }
    .col {
      &-datedebut, &-datefin, &-montant {
        font-size: 1.3rem;
        text-transform: uppercase;
        width: calc(100% / 3);
        align-items: center;
      }
    }
    .col {
      &-datedebut {
        padding-right: calc(100% / 100);
      }
    }
    &__header {
      display: flex;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid $light-grey;
      align-items: center;

      .col {
        &-datedebut, &-datefin, &-montant {
          color: $grey;
        }
      }
    }
    &__body > div {
      display: flex;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid $light-grey;
      align-items: center;
    }
  }
}
// Fidélité dans l'espace perso
.fidelity-bloc-single {
  display: flex;
  width: 100%;
  padding: 20px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid $light-grey;

  &__image {
    display: flex;
    width: 80px;
    height: 80px;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }
  &__content {
    display: flex;
    width: calc(100% - 275px);
    padding: 0 10px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    .content-text {
      .name-solde-bloc {
        min-width: 190px;
      }
      .commerce-name {
        margin: 0;
        font-weight: 800;
        text-transform: capitalize;
      }
      .text-desc {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }
    .content-solde {
      min-width: 190px;

      .text-solde {
        display: flex;
        flex-direction: column;

        .solde-label, .solde-amount {
          font-weight: 700;
        }
        .solde-label {
          font-size: 1.2rem;
        }
        .solde-amount {
          font-size: 1.8rem;
          color: var(--primary-color);
        }
      }
      .solde {
        margin: 0;
        color: var(--primary-color);
      }
      .date-peremption-fid {
        display: inline-block;
        margin-top: 10px;

        div {
          font-size: 1.2rem;
          color: $grey;
        }
      }
    }
  }
  &__actions {
    display: flex;
    height: 80px;
    align-items: center;
  }
  // Not used currently
  //&__chart {
  //  .donut-size {
  //    font-size: 12em;
  //  }
  //  .half-circle {
  //    border: 8px solid var(--primary-color);
  //    transition: all 1s cubic-bezier(0.92, 0.18, 0.46, 1.28);
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    border-radius: 50%;
  //    clip: rect(0em, 0.5em, 1em, 0em);
  //    background-color: white;
  //  }
  //  .pie-wrapper {
  //    font-size: 80px;
  //    position: relative;
  //    width: 1em;
  //    height: 1em;
  //    margin: 0 auto;
  //    .label {
  //      font-weight: bold;
  //      position: absolute;
  //      top: 0.4em;
  //      right: 0.4em;
  //      bottom: 0.4em;
  //      left: 0.4em;
  //      display: block;
  //      background: none;
  //      border-radius: 50%;
  //      color: #7F8C8D;
  //      font-size: 0.25em;
  //      line-height: 2.6em;
  //      text-align: center;
  //      cursor: default;
  //      z-index: 2;
  //    }
  //    .pie {
  //      position: absolute;
  //      top: 0;
  //      left: 0;
  //      width: 100%;
  //      height: 100%;
  //      clip: rect(0, 1em, 1em, 0.5em);
  //    }
  //    .shadow {
  //      border: 8px solid #e0e6f1;
  //      height: 100%;
  //      width: 100%;
  //      border-radius: 50%;
  //    }
  //  }
  //}
}
//-- Responsive Tablette (Haute Résolution) --//
@media (max-width: 992px) {
  #vue_fidelite {
    .aside-img-bloc {
      display: none;
    }
    .loyalty-step {
      justify-content: center;

      &__type, &__infos, &__confirmation {
        margin-bottom: 20px;
      }
    }
  }
}
//-- Responsive Mobile --//
@media (max-width: 576px) {
  #vue_fidelite {
    .loyalty-header {
      height: 75px;
      padding: 0 10px;
      border-bottom: 1px solid $light;

      .img-logo {
        img {
          max-height: 75px;
        }
      }
    }
    .header-user-name {
      display: none;
    }
    .header-icon {
      margin-right: 0;
    }
    .loyalty-home {
      padding: 10px 0 50px 0;

      .actions {
        flex-wrap: wrap;

        button {
          margin-bottom: 15px;
        }
      }
    }
    .loyalty-step {
      &__type, &__infos, &__confirmation {
        margin-bottom: 0;

        &.valid {
          display: flex;
          margin: -1px 0 0 0;
          padding: 15px 20px;
          border-top: 1px solid var(--primary-color);
          border-bottom: 1px solid var(--primary-color);
          align-items: center;

          .circle-icon {
            width: 40px;
            height: 40px;
            margin: 0;
            font-size: 1.8rem;
          }
          p {
            display: none;
          }
        }
        &.mobile-hide {
          display: none;
        }
      }
      &__infos, &__confirmation {
        margin-top: 20px;
      }
      &__type {
        &.valid {
          .loyalty-step__type-content {
            width: calc(100% - 40px);
            padding: 0 0 0 20px;
          }
          .type-single:not(.selected) {
            display: none;
          }
        }
        .type-single {
          img {
            max-height: 30px;
          }
        }
      }
      &__infos {
        &.valid {
          .loyalty-step__infos-content {
            width: calc(100% - 40px);
            padding: 0 0 0 20px;

            .input-bloc {
              margin-bottom: 0;

              input {
                height: 40px;
              }
            }
            div:not(:first-child) {
              display: none;
            }
          }
        }
      }
      &__type-content, &__infos-content, &__confirmation-content {
        padding: 20px 10px;
      }
      .bg-step {
        display: none;
      }
    }
    .loyalty-create {
      .loyalty-actions {
        flex-direction: column-reverse;
        align-items: center;

        button:first-child {
          margin-right: 0;
          margin-top: 20px;
        }
      }
    }
  }
  .fidelity-bloc-single {
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;

    &__image {
      width: 50px;
      height: 50px;
    }
    &__content {
      width: calc(100% - 60px);
      padding: 0 0 0 10px;
      flex-direction: column;
      gap: 20px;
    }
  }
}
