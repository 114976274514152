.add-actu, .edit-actu {
  &-main-bloc {
    width: 100%;
    padding: 0 20px;
    background-color: white;
  }
  &-header {
    position: relative;
    display: flex;
    padding: 10px 0;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid $light;

    &__author {
      display: flex;
      width: 75%;
      height: 56px;
      align-items: center;

      .author-name {
        font-weight: 700;
      }
      img {
        width: auto;
        height: 100%;
      }
    }
    .current-author {
      display: flex;
      align-items: center;
      gap: 10px;

      &.has-after {
        cursor: pointer;

        &:after {
          content: '\f078';
          font-family: $fa;
          transition: all 0.3s ease-in-out;
        }
        &.enable {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
      .img-container {
        width: 60px;
        height: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      p {
        margin-bottom: 0;
        font-size: 1.1rem;
        color: $grey;
      }
      .author-name {
        display: block;
        width: auto;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .author-select {
      position: absolute;
      width: 400px;
      max-height: 0;
      padding: 0 10px;
      top: 100%;
      left: 0;
      background-color: white;
      overflow-y: hidden;
      box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.3);
      z-index: 10000;
      transition: all 0.3s ease-in-out;

      &.show {
        max-height: 350px;
        padding: 10px;
        overflow-y: auto;
      }

      .select-section {
        cursor: pointer;

        &__head {
          padding: 5px 10px;
          font-weight: 700;
          color: var(--primary-color);
          border-bottom: 1px solid var(--primary-color);
        }
        &__body {
          margin-top: 10px;

          .item-single {
            display: flex;
            height: 40px;
            align-items: center;
            gap: 10px;
          }
          > div {
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
  &-body {
    &__content {
      .trumbowyg-box {
        max-height: 300px;
        border: none;
      }
      .trumbowyg-editor {
        padding: 20px 0;
      }
      input[type="text"], textarea {
        border: none;

        &:focus-visible {
          outline: none;
        }
      }
      input[type="text"] {
        margin: 20px 0;
        padding: 0;
        font-size: 2rem;
        font-weight: 700;
        color: var(--primary-color);
      }
      textarea {
        min-height: 200px;
        padding: 0;
      }
    }
    &.as-col {
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;

      .add-actu-body__image, .edit-actu-body__image {
        width: 30%;
      }
      .add-actu-body__content, .edit-actu-body__content {
        width: 70%;
      }
    }
    .drop-area {
      height: 100%;
    }
  }
  &-footer {
    position: relative;
    display: flex;
    padding: 10px 0;
    border-top: 1px solid $light;

    &__options, &__actions {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &__options {
      width: 25%;
      font-size: 2.4rem;

      i {
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &.active {
          color: var(--primary-color);
        }
      }
    }
    &__actions {
      width: 75%;
      justify-content: flex-end;

      .date-publi {
        font-weight: 700;

        b {
          color: var(--primary-color);
        }
      }
      .actu-date-infos {
        position: absolute;
        display: flex;
        width: 200px;
        height: 0;
        padding: 0 20px;
        right: 0;
        bottom: 52px;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        background: white;
        box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.3);
        overflow: hidden;
        transition: height 0.2s ease-in-out;

        > div {
          width: 100%;

          &:last-child {
            .input-bloc {
              margin: 0;
            }
          }
        }
        &.show {
          height: 150px;
        }
        .component-input-bloc {
          width: 100%;
        }
      }
      .facebook-infos {
        position: absolute;
        display: flex;
        width: auto;
        height: 0;
        padding: 0 20px;
        right: 0;
        bottom: 52px;
        justify-content: center;
        align-items: center;
        background: white;
        box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.3);
        overflow: hidden;
        transition: height 0.2s ease-in-out;

        &.show {
          height: 40px;
        }
        div {
          white-space: nowrap;
        }
      }
    }
  }
  &-btn-mobile {
    display: none;
  }
}
.edit-actu-main-bloc {
  padding: 0;
}
.actu-pro {
  margin-bottom: 30px;
  padding: 0 15px;
  background: white;

  &-header {
    display: flex;
    padding: 10px 0;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid $light-grey;

    &__author {
      font-size: 1.8rem;
      font-weight: 700;
      color: var(--primary-color);
    }
    &__date {
      font-size: 1.3rem;
      font-weight: 400;
      color: $dark-grey;
    }
    &__actions {
      display: flex;
      min-width: 70px;
      margin-left: auto;
      justify-content: flex-end;

      .dropdown-menu {
        padding: 5px;

        .dropdown-item {
          padding: 5px;
        }
      }
      > button {
        width: 32px;
        height: 32px;
        border: none;
        background: none;

        &.pinned {
          i {
            color: var(--primary-color);
          }
        }
        i {
          font-size: 2rem;
          color: $dark-grey;
        }
      }
    }
    img {
      max-width: 60px;
      max-height: 50px;
      width: auto;
      height: auto;
    }
  }
  &-body {
    padding: 15px 0;
    word-break: break-word;

    &:after {
      // Ça c'est important !
      content: '';
      display: block;
      clear: both;
    }

    &.default {
      display: flex;
      gap: 20px;
    }
    &.separated {
      img {
        float: left;
        margin: 0 20px 20px 0;
      }
      #crop-edit-actu {
        img {
          float: none;
        }
      }
    }
    &__image {
      max-width: calc(50% - 20px);
      max-height: 100%;
    }
    &__content {
      h4 {
        color: var(--primary-color);
      }
      a {
        color: var(--primary-color);
      }
    }
  }
  .dropdown-item {
    font-size: 1.4rem;
  }
}
//-- Responsive --//
@media (max-width: 576px) {
  .add-actu, .edit-actu {
    &-main-bloc {
      position: fixed;
      display: flex;
      width: 100%;
      height: 100dvh;
      padding: 0 20px;
      top: 0;
      left: 0;
      flex-direction: column;
      opacity: 0;
      transform: scale(0);
      z-index: 999;
      transition: all 0.3s ease-in-out;

      &.show {
        opacity: 1;
        transform: scale(1);
      }
    }
    &-header {
      height: 65px;
      justify-content: space-between;

      .current-author {
        .author-name {
          width: 120px;
        }
      }
      .author-select {
        width: calc(100% - 25px);
        left: 25px;
      }
      .fa-xmark {
        font-size: 2rem;
      }
    }
    &-body {
      height: calc(100% - 130px);
      overflow-y: auto;

      &__content {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 20px;

        .trumbowyg-box {
          height: 100%;
          max-height: 100%;
        }
        input[type=text] {
          margin: 20px 0 0 0;
        }
        textarea {
          height: 100%;
        }
      }
      &.as-col {
        flex-direction: column;

        .add-actu-body__content, .add-actu-body__image, .edit-actu-body__content, .edit-actu-body__image {
          width: 100%;
        }
        .add-actu-body__content, .edit-actu-body__content {
          input[type=text] {
            margin: 0;
          }
        }
      }
    }
    &-footer {
      height: 65px;
    }
    &-footer__actions {
      .hide-mobile {
        display: none;
      }
      .actu-date-bloc {
        button {
          display: flex;
          width: 42px;
          height: 42px;
          padding: 0;
          justify-content: center;
          align-items: center;
          font-size: 2rem;

          i {
            margin-right: 0 !important;
          }
          span {
            display: none;
          }
        }
      }
    }
    &-btn-mobile {
      display: flex;
      width: auto;
      height: 50px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: var(--primary-color);
      color: white;
      font-size: 1.6rem;
    }
  }
  .actu-pro {
    &-header {
      gap: 10px;
    }
    &-body {
      &.default {
        flex-direction: column;
      }
      &__image {
        max-width: 100%;
      }
    }
  }
}
