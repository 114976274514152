@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800%7CPoppins:400600,700,800%7CRoboto:400,700,800");
//@import "css/bootstrap.min.css";
@import "css/all.min.css";
//@import "css/style.css";
//@import "css/custom.css";
// Import des breakpoints pour la gestion des ecrans
@import "_utils/breakpoints";
// Variables
@import "variables";
// Fonctions utiles
@import "_utils/functions";
// Couleur de base
@import "_utils/color";
// Classes de base
@import "_utils/_classCommunes";
// Styles des structures communes
@import "_utils/_layouts";

//Ajout des modules
@import "_modules/search_header";
@import "_modules/header";
@import "_modules/actus";
@import "_modules/slider";
@import "_modules/footer";
@import "_modules/_marquesContainer";
@import "_modules/_blocPanier";
@import "_modules/_commerces";
@import "_modules/phone_input";
@import "_modules/_vue-select";
@import "_modules/slim";
@import "_modules/uploader";
@import "_modules/modals";
@import "_modules/quantitySelector";
@import "_modules/_cb";
@import "_modules/_galerie";
@import "_modules/_tabs";
@import "_modules/_banAutocomplete";

@import "inputs";
@import "buttons";
@import "boutiques";
@import "pros";
@import "offrecadeaux";
@import "achatcourriers";
@import "mentions";
@import "user";
@import "product";
@import "registerPdv";
@import "cards";
@import "connexion";
@import "header";
@import "accueil";
@import "commercesTitreKdo";
@import "affiliation";
@import "infoFidMerchantPage";
@import "cashless";
@import "panier";
@import "paiement";
@import "events";
@import "fidelite";

@import "css/custom.css";
