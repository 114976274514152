body {
  font-family: $font-2;
  color: $dark;
}
//-- Commun pour Affiliation et Fidélité --//
#vue_onboarding, #vue_fidelite {
  height: 100vh;
  min-height: vh(100);
  margin: 0 auto;

  .aside-img-bloc {
    position: relative;
    padding: 0;
  }
  //-- Aside avec image --//
  .bg-color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.8;
  }
  .bg-img {
    width: auto;
    height: 100%;
    background-size: cover;
  }
}
@media(max-width: 997px) {
  html {
    font-size: 62.5%;
  }
  #vue_onboarding, #vue_fidelite {
    .bg-color {
      opacity: 0.9;
    }
  }
}
@media(max-width: 576px) {
  .page-pro {
    .container {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
}
