//-- Override styles VuePhoneNumberInput --//
.vue-phone-number-input.ciss-phone-input, .component-input-bloc .vue-phone-number-input {
  width: 100%;
  max-width: initial;
  margin-bottom: 1rem;
  
  &.required {
    color: $dark;
  }
  &.input-error {
    .field.vue-input-ui, .field.vue-input-ui.has-value {
      .field-label {
        color: $red;
      }
      input#VuePhoneNumberInput_country_selector, input#VuePhoneNumberInput_phone_number {
        border-color: $red;
      }
    } 
  }
  .select-country-container {
    .country-selector.has-value {
      .field-country-flag {
        top: 24px;
      }
      .field-label {
        top: 0;
        left: 0;
        font-family: $font-2;
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--primary-color);
      }
    }
    .country-list-item {
      &.selected {
        background-color: var(--primary-color)
      }
    }
  }
  .field {
    &.vue-input-ui {
      &.has-value {
        .field-label {
          top: 0;
          left: 10px;
          font-family: $font-2;
          font-size: 1.2rem;
          font-weight: 700;
          color: var(--primary-color);
        }
      }
      // Pour l'autocomplétion
      input:-webkit-autofill {
        box-shadow: 0 0 0 30px transparent inset;
        -webkit-box-shadow: 0 0 0 30px transparent inset;

        &:active, &:focus, &:hover {
          box-shadow: 0 0 0 30px transparent inset;
          -webkit-box-shadow: 0 0 0 30px transparent inset;
        }
      }
    }
    &.has-value {
      input#VuePhoneNumberInput_country_selector, input#VuePhoneNumberInput_phone_number {
        border-color: var(--primary-color);
      }
    }
    #VuePhoneNumberInput_phone_number::placeholder {
      font-size: 14px;
      color: $grey;
    }
    input#VuePhoneNumberInput_country_selector, input#VuePhoneNumberInput_phone_number {
      height: 50px;
      border: none;
      border-bottom: 1px solid $grey;
      border-radius: 0;
    }
  }
}
//-- Input plain styles --//
.input-plain .vue-phone-number-input {
  .select-country-container {
    .country-selector.has-value {
      .field-label {
        top: -8px;
        left: 15px;
        z-index: 0;
      }
      .field-country-flag {
        top: 17px;
        left: 15px;
      }
      .field-input {
        padding-top: 5px;
      }
    }
  }
  .field {
    &.vue-input-ui {
      &.has-value {
        .field-label {
          top: -8px;
        }
      }
    }
    &.has-value {
      input#VuePhoneNumberInput_country_selector, input#VuePhoneNumberInput_phone_number {
        border-color: $light;
      }
    }
    input#VuePhoneNumberInput_country_selector, input#VuePhoneNumberInput_phone_number {
      height: 42px;
      border-color: $light;
      background-color: $light !important;
    }
    input#VuePhoneNumberInput_country_selector {
      border-radius: 10px 0 0 10px;
    }
    input#VuePhoneNumberInput_phone_number {
      padding-top: 5px;
      border-radius: 0 10px 10px 0;
    }
  }
}
//-- En attendant la full transition --//
.component-input-bloc {
  .input-error {
    .vue-phone-number-input {
      .field.vue-input-ui, .field.vue-input-ui.has-value {
        .field-label {
          color: $red;
        }
        input#VuePhoneNumberInput_country_selector, input#VuePhoneNumberInput_phone_number {
          border-color: $red;
        }
      }
    }
  }
}
