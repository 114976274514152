#vue_cashless {
  width: 100vw;
  min-height: 100vh;
  /* Override styles pour la connexion */
  #vue_login {
    height: 100vh;

    .content {
      height: 100%;
      padding-top: 80px;
    }
  }
  /* Override styles pour les boutons */
  .btn {
    background-color: $col-cash;
    border: 1px solid $col-cash;
    text-transform: unset;

    &-secondary {
      background-color: transparent;
      color: $col-cash;
    }
    &.dark {
      background-color: $dark;
      border-color: $dark;

      &:hover {
        background-color: $col-cash;
        border-color: $col-cash;
      }
    }
    &.disabled {
      background-color: $grey;
      border-color: $grey;
    }
    &.outline {
      &:hover {
        background-color: $col-cash;
      }
      &.disabled {
        border-color: $grey;
      }
    }
    &.outline-dark {
      border-color: $dark;

      &:hover {
        background-color: $dark;
      }
      &.disabled {
        border-color: $grey;
      }
    }
    &.as-link {
      background-color: transparent;
      border: none;
    }
    &.as-link-outline {
      background-color: transparent;
      color: $dark;
      border-color: $dark;
    }
    &.tiny {
      border-radius: 10px;
      text-transform: unset;
    }
  }
  .alerte-generale-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(33,33,33,0.9);
    z-index: 200;

    .alerte-generale {
      display: flex;
      padding: 20px;
      justify-content: center;
      align-items: center;
      background-color: $red;
      color: white;

      .btn-error {
        margin-left: 15px;
        padding: 10px 15px;
        background: transparent;
        border: 2px solid white;
      }
    }
  }
  .alert-reseau-container {
    display: flex;
    position: fixed;
    width: 100%;
    top: 10%;
    justify-content: center;
    z-index: 200;

    .alert-reseau {
      width: 100%;
      padding: 15px;
      text-align: center;
    }
  }
  .header-icon {
    margin-right: 30px;
    cursor: pointer;
  }
  .header-user {
    img {
      width: 50px;
      height: auto;
      border-radius: 50px;
      border: 2px solid white;
    }
  }
  .header-user-name {
    p {
      color: white;
    }
  }
  .menu-mobile {
    display: none;
  }
  .cashless {
    &-section {
      .cashless-loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 150px;
        span {
          display: block;
          text-align: center;
          font-family: $font-2;
          font-size: 1.8rem;
          font-weight: 700;
        }
      }
    }
    &-top-content {
      width: 100%;
      height: 15vh;
      background: $col-cash;

      h1 {
        margin: 0 0 0 20px;
        font-family: $font-1;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 15vh;
        color: white;
        text-transform: uppercase;
      }
      .img-logo {
        display: flex;
        height: 15vh;
        flex-direction: column;
        justify-content: center;

        img {
          max-height: 10vh;
        }
      }
      .user-desktop-bloc {
        display: flex;
      }
    }
    &-nav {
      .user-mobile-bloc {
        display: none;
      }
      ol {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $light;

        li {
          display: inline-block;
          margin: 0 20px;
          padding: 20px 0;
          border-bottom: 3px solid white;
          font-family: $font-2;
          font-weight: 400;
          font-size: 1.6rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &.active {
            border-color: $col-cash;
            color: $col-cash;
            font-weight: 700;
          }
        }
      }
    }
    &-card {
      position: relative;
      display: flex;
      margin-bottom: 20px;
      padding: 20px;
      flex-direction: column;
      justify-content: space-between;
      background-color: $col-cash;
      border-radius: 15px;

      &.card-outline {
        min-height: 230px;
        border: 3px solid $col-cash;
        background-color: white;

        &.new {
          border-style: dashed;
          justify-content: center;
          align-items: center;
          color: $col-cash;
          cursor: pointer;

          i {
            margin-bottom: 15px;
            font-size: 3rem;
          }
          span {
            font-size: 1.8rem;
            font-weight: 700;
          }
        }
        .cashless-card-info, .cashless-card-solde {
          p, span {
            color: $col-cash;
          }
        }
        .cashless-card-credit {
          border-radius: 10px;
          background-color: white;
          border-color: $green;

          &:hover {
            background-color: $green;
            .credit-txt, i {
              color: white;
            }
          }
          .credit-txt, i {
            color: $green;
          }
        }
        .cashless-card-menu {
          color: $col-cash;

          &-dropdown.show {
            border: 1px solid $light;
            box-shadow: -5px 10px 15px -5px rgba(0,0,0,0.4);
          }
          i {
            cursor: pointer;
          }
        }
      }
      &.card-inactive {
        min-height: 230px;
        border: 3px solid $red;
        background-color: white;

        &.new {
          border-style: dashed;
          justify-content: center;
          align-items: center;
          color: $red;
          cursor: pointer;

          i {
            margin-bottom: 15px;
            font-size: 3rem;
          }
          span {
            font-size: 1.8rem;
            font-weight: 700;
          }
        }
        .cashless-card-info, .cashless-card-solde {
          p, span {
            color: $red;
          }
        }
        .cashless-card-credit {
          border-radius: 10px;
          background-color: white;
          border-color: $green;

          &:hover {
            background-color: $green;
            .credit-txt, i {
              color: white;
            }
          }
          .credit-txt, i {
            color: $green;
          }
        }
        .cashless-card-menu {
          color: $col-cash;

          &-dropdown.show {
            border: 1px solid $light;
            box-shadow: -5px 10px 15px -5px rgba(0,0,0,0.4);
          }
          i {
            cursor: pointer;
          }
        }
      }
       Mise en oppposition
      &.card-disabled {
        border-color: $red;
        .cashless-card {
          &-info, &-img {
            opacity: 0.5;
          }
          &-bloc {
            justify-content: right;
          }
        }
      }
      &-img {
        img {
          width: auto;
          max-width: 200px;
          height: auto;
          max-height: 100px;
          margin: 0 auto;
        }
      }
      &-bloc {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      &-info {
        p, span {
          color: white;
          text-align: center;
        }
      }
      &-tooltip {
        i {
          color: white;
        }
      }
      &-statut {
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 0;
        padding: 10px;
        background-color: $red;
        color: white;
        p {
          margin: 0;
          font-weight: 700;
        }
      }
      &-credit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 15px;
        border: 1px solid white;
        background-color: $col-cash;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: white;

          i, .credit-txt {
            color: $col-cash;
          }
        }

        i, .credit-txt {
          display: inline-block;
          vertical-align: top;
          font-size: 1.4rem;
          transition: color 0.3s ease-in-out;
        }
        i {
          margin-right: 5px;
          color: white;
          line-height: 2rem;
        }
        .credit-txt {
          margin-bottom: 0;
          font-weight: 700;
        }
      }
      &-menu {
        position: absolute;
        top: 20px;
        right: 0;
        color: white;

        &-dropdown {
          position: absolute;
          top: 35px;
          right: 0;
          height: 0;
          opacity: 0;
          cursor: pointer;
          overflow: hidden;
          transition: all 0.3s ease-in-out;

          &.show {
            height: auto;
            opacity: 1;
          }

          ol {
            margin-bottom: 0;
            padding: 5px 20px;
            background-color: white;

            li {
              padding: 0 10px 0 0;
              border-bottom: 1px solid $light;
              font-size: 1.4rem;
              line-height: 3.4rem;

              &:last-child {
                border: none;
              }
            }
          }
        }

        #dropdownActions {
          position: relative;

          i {
            padding: 0 20px;
            font-size: 3rem;
          }
        }
      }
    }

    &-rechargement {
      .value-bloc, .amount-bloc {
        display: flex;
        width: calc(50% - 20px);
        padding: 0 15px;
      }
      .value-bloc {
        flex-direction: column;
        align-items: center;

        label {
          color: $dark;
          font-family: $font-1;
          font-size: 1.4rem;
          font-weight: 400;
        }
        div:last-child {
          margin-right: 0;
        }
      }
      .amount-bloc {
        justify-content: center;
      }
      .circle-ou {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        background-color: $dark;
        color: white;
        font-family: $font-1;
        font-size: 1.4rem;
        line-height: 4rem;
        font-weight: 700;
      }
    }

    &-paiement {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $light;

      &.no-border {
        border: none;
        margin: 0;
        padding: 0;
      }
    }

    &-remb {
      .cashless-radio-list {
        .checkbox-info {
          span:last-child {
            display: block;
          }
        }
      }
    }

    &-checkout-card-single {
      position: relative;
      width: 100%;
      padding: 15px;
      border: 2px solid $light;
      border-radius: 15px;

      &:not(.new-card){
        width: calc(100% - 45px);
      }
      &.selected {
        border: 2px solid $col-cash;
      }
      svg {
        height: 25px;
      }
      input[type="radio"] {
        position: absolute;
        top: 21px;
        left: 10px;
        accent-color: $col-cash;
      }
      label:not(.hipay-field-label) {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 10px;
        cursor: pointer;
      }
      .txt-label {
        display: inline-block;
        margin-right: 10px;
        font-size: 1.4rem;
        line-height: 25px;
        text-transform: uppercase;
        vertical-align: top;

        &.new-card {
          margin-bottom: 0;
        }
      }
      .card-logo {
        display: inline-block;
        margin-right: 5px;
        vertical-align: top;
      }
      .card-status {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 3px 7px;

        &.active {
          background-color: #009900;
          color: white;
        }
        &.expired {
          background-color: #990000;
          color: white;
        }
      }
      .card-number, .card-expire {
        font-size: 1.2rem;
        line-height: 2.5rem;
        font-weight: 400;
      }
      .card-expire {
        margin-left: 15px;
      }
      .cvc-input {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: rgba(33,33,33,0.8);

        input {
          width: 105px;
          height: 40px;
          text-align: center;
        }
      }
      .cvc-ok {
        margin-left: 15px;
        color: $green;
      }
      .cvc-loader {
        margin-left: 15px;
        color: white;
      }
    }
    &-radio-list {
      position: relative;
      margin-bottom: 20px;
      padding: 15px;
      border: 2px solid $light;
      border-radius: 15px;

      &.selected {
        border-color: $col-cash;
      }
      input[type="radio"] {
        position: absolute;
        top: 23px;
        left: 10px;
        accent-color: $col-cash;
      }
      .checkbox-info {
        display: block;
        padding-left: 15px;

        span {
          font-size: 1.2rem;
          line-height: 3rem;
          font-weight: 400;

          &:first-child {
            margin-right: 20px;
          }
          &.norib-txt {
            font-size: 1.4rem;
            text-transform: uppercase;

            b {
              color: black;
            }
          }
          b {
            color: $col-cash;
          }
        }
      }
      .btn.select-rib, .btn.select-support {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
      label {
        display: block;
        width: 100%;
        margin: 0;
        cursor: pointer;
      }
    }
    &-history {
      margin-top: 20px;
      margin-bottom: 40px;
      &-select {
        margin-bottom: 40px;
      }
      &-table {
        thead {
          background-color: $col-cash;
          color: white;
          th {
            padding: 15px 10px;
          }
        }
        td {
          vertical-align: middle;
          i {
            font-size: 1.8rem;
            transition: 0.3s all ease-in-out;
            &.open-details {
              transform: rotate(90deg);
            }
          }
        }
        .cashless-history-details {
          background-color: #EFEFEF;
          thead {
            background-color: $grey;
          }
        }
      }
    }

    &-compte {
      &-email, &-tel {
        margin-bottom: 30px;
      }
      .info-bloc {
        margin-top: 30px;

        &-user {
          display: flex;
          width: 400px;
          margin-right: 30px;
          padding: 15px;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 15px;
          background-color: $col-cash;

          span, h4, p {
            color: white;
          }
          .modal-header {
            h4 {
              color: black;
            }
          }
        }
        &-details {
          width: calc(100% - 430px);
          padding-top: 15px;
        }
        .info-header, .email-header, .tel-header, .adresse-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          padding-bottom: 10px;
          border-bottom: 1px solid $light;

          span {
            font-family: $font-1;
            font-size: 1.4rem;
            line-height: 30px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
        .info-body {
          img {
            display: inline-block;
            width: auto;
            height: 80px;
            border-radius: 40px;
            border: 3px solid white;
          }
        }
        .btn, .btn.tiny {
          padding: 7px 12px;
          font-size: 1.2rem;
          text-transform: unset;
        }
        .change-mdp {
          .btn {
            border-color: white;
          }
        }
        .delete-account {
          .btn {
            background-color: $red;
          }
        }
      }
      #delete_account {
        .btn {
          padding: 7px 12px;
          font-size: 1.2rem;
          text-transform: unset;

          &.outline {
            font-weight: 700;
          }
          &.red {
            width: 100%;
            background-color: $red;
            border-color: $red;
          }
        }
      }
    }
    .solde-bloc, .badge-bloc {
      p, label {
        margin: 0;
        font-family: $font-1;
        font-size: 1.4rem;
        color: $dark;
      }
      .solde-txt {
        font-family: $font-1;
        font-size: 2.4rem;
        font-weight: 700;
        color: $col-cash;
        &.green {
          color: $green;
        }
        &.grey {
          color: $grey;
        }
      }
    }
    .title-bloc {
      position: relative;
      border-bottom: 1px solid $light;
      margin: 30px 0;

      h3 {
        margin: 0;
        font-family: $font-1;
        font-weight: 700;
        line-height: 80px;
        text-transform: uppercase;
      }

      button {
        position: absolute;
        top: 18px;
        right: 0;
      }
    }
  }
  .cashless-title {
    font-family: $font-1;
    font-size: 1.4rem;
    font-weight: 400;
  }
  .single-support {
    display: inline-flex;
    height: 50px;
    padding: 10px 15px;
    align-items: center;
    border: 2px solid $col-cash;
    border-radius: 15px;

    img {
      height: 100%;
      margin-right: 15px;
    }
  }
  .suppress-credit-btn {
    display: inline-flex;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: $red;
    color: white;
    vertical-align: top;
    border: none;
  }
  .cgu-bloc {
    margin-top: 40px;

    p {
      margin: 0;
    }
  }
  .result-bloc {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.show {
      opacity: 1;
    }
    i {
      margin-bottom: 15px;
      font-size: 5rem;

      &.fa-circle-check {
        color: $green;
      }
      &.fa-circle-xmark {
        color: $red;
      }
    }
    p {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  .data-protection {
    text-align: center;

    a {
      font-size: 1.2rem;
      color: white;
      text-decoration: underline;
    }
  }
  .logout-mobile {
    display: none;
  }
  .disclaimer-bloc {
    padding: 15px 0;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;

    label {
      font-size: 1.4rem;
      font-weight: 400;
      margin: 0;
      cursor: pointer;
    }
  }
  .no-event-bloc {
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: center;

    .no-event-bg {
      display: flex;
      padding: 20px 30px;
      align-items: center;
      border: 8px solid #FFC336;
      border-radius: 20px;
      background-color: #FFE436;
    }
    i {
      margin-right: 30px;
      font-size: 7rem;
    }
    p.title {
      margin-bottom: 15px;
      font-family: $font-1;
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      width: 100%;
      margin: 0;
      line-height: 2.4rem;
      text-align: center;
    }
  }
  // -- RESPONSIVE --//
  @media (max-width: 992px) {
    // #vue_cashless
    min-height: 80vh;

    #compte {
      margin-bottom: 50px;
    }
    .container, .row {
      height: 100%;
    }
    .main-container {
      margin-top: 12vh;
    }
    .header-icon {
      margin: 0 10px;
    }
    .header-user {
      &-name {
        p {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
      img {
        width: 35px;
        border-radius: 35px;
        border: 0;
      }
    }
    .logout-mobile {
      display: block;
      font-weight: 700;
      color: $red;
    }
    .user-popup {
      top: -150%;
      left: 0;
      .user-name {
        display: block;
      }
    }
    .menu-mobile, .header-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .menu-mobile {
      span {
        color: white;
        text-align: center;
      }
      i {
        font-size: 3.5rem;
      }
    }
    .header-user-name {
      p {
        color: var(--primary-color);
      }
    }
    .cashless-top-content {
      position: fixed;
      top: 0;
      left: 0;
      height: 10vh;
      z-index: 10;
      h1 {
        margin: 0;
        font-size: 2rem;
        line-height: 3rem;
      }
      .user-desktop-bloc {
        display: none;
      }
      .header-icon {
        margin: 0;
      }
      .img-logo {
        height: 10vh;
        img {
          max-height: 8vh;
        }
      }
    }
    .cashless-nav {
      position: fixed;
      top: 10vh;
      left: -300px;
      width: 300px;
      height: 90vh;
      background-color: white;
      border-right: 10px solid $col-cash;
      z-index: 10;
      transition: all 0.3s ease-in-out;

      &.show {
        left: 0;
      }
      .user-mobile-bloc {
        display: block;
        width: 100%;
        padding: 10px 0;
        border-top: 1px solid var(--primary-color);
        border-bottom: 1px solid var(--primary-color);

        & > div {
          flex-direction: row-reverse;
          justify-content: left;
        }
      }
      ol {
        border-bottom: 0;

        li {
          margin: 0 80px 10px 20px;
          padding: 15px 0 5px 0;
        }
      }
    }
    .cashless {
      .title-bloc {
        margin: 20px 0;
        h3 {
          font-size: 2rem;
          line-height: 60px;
        }
        button {
          top: 10px;
        }
      }
      .solde-bloc, .badge-bloc {
        margin-bottom: 15px;
      }
      .cashless-card-solde {
        .solde-bloc {
          margin-bottom: 0;
        }
      }
      .cashless-rib, .cashless-support {
        .btn.select-rib, .btn.select-support {
          display: block;
          position: initial;
          margin-top: 10px;
          margin-left: auto;
        }
      }
    }
    .cashless-no-support {
      .fas {
        margin: 0 10px 0 0;
      }
      p {
        margin: 0 0 15px 0;
      }
    }
    .cashless-checkout-card-single {
      #payweb-form {
        button {
          margin-left: -30px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .cashless-compte {
      .info-bloc {
        flex-direction: column;

        &-user, &-details {
          width: 100%;
        }
        &-user {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
    .no-event-bloc {
      .no-event-bg {
        padding: 30px 20px;
        flex-direction: column;
      }
      i {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 425px) {
    .cashless-top-content {
      h1 {
        font-size: 1.8rem;
      }
    }
    .cashless-rechargement {
      .choose-amount-bloc {
        flex-direction: column;

        .value-bloc, .amount-bloc {
          width: 100%;
        }
        .amount-bloc {
          flex-direction: column;
          align-items: center;
        }
        .circle-ou {
          margin: 15px 0;
        }
      }
    }
    .cashless-checkout-card-single {
      .credit-info {
        display: block;
        margin-top: 10px;
      }
      .suppress-credit-btn {
        position: absolute;
        top: 15px;
        right: 100px;
      }
      .cvc-ok {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
}

#cgu-cashless {
  padding: 30px 0;
  background: #F4F4F4;
  .container {
    padding: 30px;
    background: white;
  }
  h1, h2 {
    font-family: $font-1;
    font-weight: 700;
  }
  h1 {
    font-size: 2.4rem;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 1.8rem;
  }
  p {
    font-family: $font-2;
    line-height: 2.4rem;
  }
}