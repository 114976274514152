//-- Styles Affiliation --//
#vue_onboarding {
  // Override un style du login
  #vue_login .content {
    padding: 0;
  }
  //-- Fond obscure modale --//
  #bg-dark {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #333333;
    opacity: 0.8;
    z-index: 99;
  }
  #bg-dark.show {
    display: block;
  }
  //-- Contenu --//
  .modal-bloc {
    position: absolute;
    width: 50%;
    height: 100%;
    padding: 20px 30px;
    top: 0;
    right: -100%;
    background: white;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 999;
    transition: right 0.3s ease-in-out;

    &.show {
      right: 0;
    }
    .close {
      color: #333333;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #990000;
      }
      &::before {
        content: "\f00d";
        font-family: "FontAwesome", sans-serif;
      }
    }
    h2 {
      margin-top: 30px;
      color: #333333;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 3rem;
    }
    p {
      font-weight: 700;
      font-size: 1.6rem;
    }
    .separateur {
      width: 100px;
      height: 4px;
      background: #333333;
      margin: 30px 0;
    }
  }
  .top-content-bloc {
    height: 80px;
    margin: 0;
    padding-top: 10px;
    box-sizing: initial;

    img {
      height: 100%;
      width: auto;
    }
    .img-logo {
      background : white;
    }
    .user-infos {
      text-transform: uppercase;
      color: var(--primary-color);
    }
  }
  .title-affiliation-bloc {
    padding: 2rem 0;

    .title {
      margin-bottom: 0;
    }
  }
  .content-bloc {
    width: 90%;
    margin: 0 auto;
    scroll-behavior: smooth;
  }
  .go-home {
    &:hover, .go-home:focus {
      text-decoration: none;
    }
    &::before {
      content: '\f177';
      margin-right: 10px;
      font-family: "FontAwesome", sans-serif;
    }
  }
  .header-icon {
    height: 100%;
    min-width: 25px;
    margin: 0;

    .menu-header-user {
      height: 100%;

      .header-user {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .header-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        max-width: initial;
      }
    }
  }
  .monCompteIcon, .monCompteLink {
    display: none;
  }
  .container-btn-bloc {
    display: flex;
    justify-content: center;

    .btn-bloc {
      width: 480px;
      margin: 0 auto;
    }
  }
  .list-group-item {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #333333;
    border-radius: 0;

    &.active {
      background-color: white;
      border: 2px solid var(--primary-color);

      h3::after {
        font-size: 3.4rem;
      }
    }
    h3 {
      position: relative;
      padding-bottom: 15px;
      font-size: 1.8rem;
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);

      &::after {
        content: '\f058';
        position: absolute;
        width: 30px;
        height: 30px;
        top: -3px;
        right: 0;
        font-family: 'FontAwesome', sans-serif;
        font-size: 0;
        line-height: 30px;
        text-align: center;
        color: var(--primary-color);
        border-radius: 30px;
        border: 1px solid var(--primary-color);
        transition: 0.1s all ease-in-out;
      }
    }
    div {
      color: #333333;
      line-height: 2.4rem;
    }
    .correction {
      position: absolute;
      padding: 10px 15px;
      right: 0;
      bottom: 0;
      color: white;
      background: var(--primary-color);

      &:hover {
        text-decoration: none;
      }
      &::before {
        content: '\f040';
        margin-right: 5px;
        font-family: 'FontAwesome', sans-serif;
      }
    }
  }
  //-- Override de styles pour les boutons (Affiliation) --//
  .btn-action-bloc {
    position: absolute;
    width: 90%;
    bottom: 0;
    left: 5%;

    button {
      padding: 15px 10px;
    }
    .prev::before {
      content: '\f177';
      margin-right: 5px;
      font-family: 'FontAwesome', sans-serif;
    }
    .next::after {
      content: '\f178';
      margin-left: 5px;
      font-family: 'FontAwesome', sans-serif;
    }
    .skip::after {
      content: '\f04e';
      margin-left: 5px;
      font-family: 'FontAwesome', sans-serif;
    }
  }
  //-- Spécifique aux forms de Login/Register --//
  .register-form-bloc, .login-form-bloc, .resend-validation-form {
    p {
      color: map_get($vs-colors, 'dark');
      font-size: 1.6rem;

      &.error-message {
        color: #990000;
        text-transform: none;
      }
    }
  }
  //-- Override de styles pour certains champs de l'affiliation (questions) --//
  .questions-bloc {
    > div {
      display: inline-block;
      width: 50%;
      margin-right: 20px;
      vertical-align: top;
      // Ajustement responsive bureau
      @media (min-width: 1200px) and (max-height: 800px) {
        margin-right: 0;

        &:nth-child(odd) {
          padding-right: 10px;
        }
        &:nth-child(even) {
          padding-left: 10px;
        }
      }
    }
  }
  //-- Override des styles des boutons pour Affiliation --//
  .btn {
    width: 100%;
    padding: 15px 0;
    border-radius: 10px;
    text-align: center;
    color: white;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    transition: all 0.3s ease-in-out;

    &.dark {
      background-color: map_get($vs-colors, 'dark');
      border-color: map_get($vs-colors, 'dark');

      &:hover {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
    &.outline {
      color: var(--primary-color);
      background: none;
      font-weight: 400;

      &:hover {
        color: white;
        background: var(--primary-color);
      }
    }
  }
  .search-bloc {
    position: relative;

    .btn {
      position: absolute;
      width: 90px;
      height: 50px;
      padding: 0;
      top: 0;
      right: 10px;
      font-size: 2.4rem;
      color: white;
      border: 1px solid #333333;
      border-radius: 10px;
      text-align: center;
    }
    input {
      height: 50px;
      font-size: 16px;
      border: 1px solid #333333;
      border-radius: 10px;
    }
    span {
      position: absolute;
      top: 0;
      right: 115px;
      line-height: 50px;
      color: #990000;
    }
  }
  .select-ent {
    .search-bloc {
      .btn {
        right: 0;
      }
    }
  }
  .search-result {
    .btn {
      width: auto;
      padding: 5px 10px;
      border-radius: 0;
      text-transform: initial;
    }
  }
  //-- Bloc Récapitulatif --//
  .recap-bloc-content {
    padding: 20px;
    border: 1px solid var(--primary-color);
    border-radius: 20px;

    h4 {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--primary-color);
      font-size: 1.6rem;
      color: var(--primary-color);
      text-align: center;
      text-transform: uppercase;
    }
    p {
      position: relative;
      margin: 20px 0;
      padding-left: 30px;

      &:last-child {
        margin: 0;
      }
      &:before {
        position: absolute;
        left: 0;
        font-family: 'FontAwesome', sans-serif;
        font-size: 2rem;
        line-height: 2.4rem;
        color: var(--primary-color);
      }
    }
    #pos:before {
      content: '\f041';
    }
    #mail:before {
      content: '\f1fa';
    }
    #tel:before {
      content: '\f095';
    }
  }
  .success-bloc {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    i {
      font-size: 7rem;
      color: #007700;
    }
    .title {
      text-transform: initial;
      font-weight: 800;
    }
    p {
      font-size: 1.8rem;
    }
  }
  //-- Affiliation Bis --//
  .fullscreen {
    width: 640px;
    margin: 3rem auto 0 auto;
  }
  .cv-bloc {
    h4 {
      margin-top: 3rem;
      font-weight: 800;
    }
    .btn-bloc {
      text-align: right;
    }
    .btn {
      width: 50%;
      text-align: center;
    }
  }
  .upload {
    &-bloc {
      display: inline-block;
      width: 50%;
      margin-right: 20px;
    }
    &-btn {
      display: inline-block;
      width: calc(50% - 25px);
      margin-top: 0;
      vertical-align: bottom;
    }
    &-label {
      height: 120px;
      flex-direction: column;
    }
    &-doc-bloc-content {
      position: relative;
      width: 60%;
      margin-left: auto;

      .delete-bloc {
        right: 20px;
        line-height: 120px;

        .btn-delete {
          font-size: 2rem;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &-zone {
      width: 100%;
      height: 120px;
      padding: 20px;
      text-align: center;
      border: 2px dashed #D1D1D1;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        border-color: var(--primary-color);

        .file-uploads {
          color: var(--primary-color);
        }
        label {
          cursor: pointer;
        }
      }
      .fa-spinner {
        font-size: 4.5rem;
        line-height: 80px;
      }
      .file-uploads {
        color: #D1D1D1;
        width: 100%;
        font-weight: 700;
        transition: all 0.3s ease-in-out;

        i {
          width: 100%;
          margin-bottom: 10px;
          font-size: 4.5rem;
        }
      }
      img {
        max-height: 80px;
      }
    }
  }
  .scroll-content {
    width: 100%;
    justify-content: center;

    i {
      font-size: 4rem;
      color: white;
      transform: rotate(0deg);
      transition: transform 0.3s ease-in-out;

      &.go-up {
        transform: rotate(180deg);
      }
    }
  }
}
//-- Responsive Tablette (Haute Résolution) et moins --//
@media (max-width: 992px) {
  #vue_onboarding {
    position: relative;
    height: auto;

    #bg-dark {
      display: none;
      background: none;
      z-index: -1;
    }
    .aside-img-bloc {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .main-content-bloc {
      position: initial;
    }
    .content-bloc {
      width: 100%;
      padding: 0 10px 50px 10px;
      z-index: 1;

      .top-content-bloc {
        width: 100%;
        height: 60px;
        padding: 10px 20px;
        margin-left: -20px;
        background-color: white;

        a, p {
          color: var(--primary-color);
        }
        .col-4 {
          padding: 0;
        }
      }
      .user-popup {
        a {
          color: $red;
        }
      }
      h2, h3 {
        color: white;
      }
      h2 {
        font-size: 2rem;
      }
      h3 {
        font-size: 1.6rem;
      }
      a {
        color: white;
      }
      p {
        color: white;

        &.error-message {
          color: $red;
        }
      }
    }
    .header-icon {
      .menu-header-user {
        .header-avatar {
          width: 30px;
          height: 30px;
        }
      }
    }
    .title-affiliation-bloc {
      border-bottom: 1px solid white;
    }
    .title-affiliation-bloc {
      margin: 0 0 2rem 0;
    }
    .stepper-bloc {
      margin: 0 0 2rem 0;
      border-color: white;
      border-top: none;
    }
    .progress-bar-affiliation {
      margin: 0;

      li {
        width: 33%;
        z-index: 2;

        &.active {
          color: white;

          .circle-step {
            background: white;
          }
        }
        &.valide {
          &::after {
            background: white;
          }
          .circle-step::after {
            color: var(--primary-color);
          }
        }
      }
    }
    .btn {
      &.outline {
        color: white;
        border-color: white;
      }
    }
    .modal-bloc {
      position: fixed;
      width: calc(100% - 15px);

      &.show {
        right: 0;
      }
      h2 {
        font-size: 2.6rem;
        color: #333333;
      }
      h3 {
        color: var(--primary-color);
      }
      a {
        color: var(--primary-color);

        &.correction {
          color: white;
        }
      }
      p {
        color: #333333;
      }
      .btn {
        &.outline {
          color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }
      .v-select {
        .vs__selected {
          color: $dark;
        }
      }
    }
    .selected-ent {
      .input-bloc {
        i {
          color: $light;
        }
      }
    }
    .custom-input {
      .input-bloc {
        &.input-error {
          .vue-phone-number-input {
            .field {
              &.has-value {
                input#VuePhoneNumberInput_phone_number, input#VuePhoneNumberInput_country_selector {
                  border-color: white;
                }
              }
              input#VuePhoneNumberInput_phone_number, input#VuePhoneNumberInput_country_selector {
                border-color: white;
              }
            }
          }
          input {
            border-color: white;
          }
          label {
            color: white;
          }
        }
        .vue-phone-number-input {
          .field {
            &.has-value {
              input#VuePhoneNumberInput_phone_number, input#VuePhoneNumberInput_country_selector {
                border-color: white;
              }
            }
            input#VuePhoneNumberInput_phone_number, input#VuePhoneNumberInput_country_selector {
              border-color: white;

              &::placeholder {
                color: $light;
              }
            }
            .country-selector-arrow {
              color: $light;
            }
          }
        }
        label {
          color: $light;
        }
        input {
          color: white;
          border-color: white;

          &[type="date"] {
            padding: 20px 0 0 0;
            text-align: left;
          }
        }
      }
      .input-error-msg span{
        color: white;
        background: $red;
        padding: 5px 10px;
      }
    }
    .v-select {
      .vs__selected {
        color: white;
        border: none;
      }
    }
    .input2 {
      width: 10%;
    }
    .recap-bloc-content {
      padding: 10px 20px;
      border: none;
      border-radius: 0;
      background-color: white;

      h4 {
        padding-bottom: 10px;
      }
      p {
        color: var(--primary-color);
      }
    }
    .submit-bloc {
      label {
        color: white;
      }
      a {
        text-decoration: underline;
      }
    }
    .success-bloc {
      width: 100%;
      i {
        color: white;
      }
      p {
        a {
          text-decoration: underline;
        }
      }
      h4 {
        color: white;
        font-size: 1.6rem;
      }
    }
    .fullscreen {
      width: 100%;
    }
    .cv-bloc {
      h4, h5 {
        color: white;
      }
      input {
        color-scheme: dark;
      }
      .btn {
        width: 100%;
      }
    }
    .documents-bloc {
      h5, label {
        color: white;
      }
    }
    .upload-zone:hover {
      border-color: white;

      .file-uploads {
        color: white;
      }
    }
    //-- Spécifique à la connexion --//
    #vue_login {
      border: 1px solid white;

      .content {
        background: transparent;
      }
      .bloc-connect {
        p {
          color: $dark;
        }
        a {
          color: $dark;
          text-decoration: underline;
        }
        .error-message {
          color: $red;
        }
      }
    }
  }
}
//-- Responsive Mobile --//
@media (max-width: 576px) {
  #vue_onboarding {
    .header-user-name {
      display: none;
    }
    .content-bloc {
      height: 100%;

      .affiliation-content {
        max-height: maxh(100, 300);
        padding-bottom: 50px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
    .progress-bar-affiliation {
      li {
        height: 50px;

        &.active {
          .circle-step {
            width: 25px;
            height: 25px;
          }
        }
        &::after {
          height: 4px;
          top: 11px;
        }
        .circle-step {
          width: 15px;
          height: 15px;

          &::after {
            width: 25px;
            font-size: 1.4rem;
            line-height: 25px;
          }
        }
        span {
          font-size: 1.2rem;
        }
      }
    }
    .container-btn-bloc {
      display: block;

      .btn-bloc {
        width: 100%;
      }
    }
    .modal-bloc {
      padding: 10px 15px;
      overflow-y: auto;

      .separateur {
        margin: 15px 0;
      }
      .resend-validation-form {
        .row {
          flex-direction: column-reverse;
        }
        button {
          margin-bottom: 15px;
        }
      }
      #edit-ent {
        .row {
          flex-direction: column;
        }
      }
      .btn.forget-pass {
        border-color: white;
      }
    }
    .list-group-item.active {
      h3::after {
        font-size: 3.8rem;
      }
    }
    .selected-ent {
      .input-bloc {
        width: calc(100% - 40px);
        margin-left: 40px;
      }
    }
    .questions-bloc {
      > div {
        width: 100%;
        margin-right: 0;
      }
    }
    // Comportement différent pour le mobile (cf SiretResearch.vue) //
    .search-bloc {
      position: absolute;
      width: calc(100% - 20px);
      right: 10px;
      z-index: 10;
      transition: right 0.3s ease-in-out;

      &.in-modal {
        width: 100%;
        right: 0;

        .btn {
          right: 0;
        }
      }
      &.go-out {
        right: calc(100% - 55px);
      }
      .btn {
        width: 50px;
      }
      span {
        right: 75px;
      }
    }
    .search-result {
      margin-top: 50px;
      padding-left: 10px;

      p {
        margin-top: 2rem;
      }
    }
    .recap-bloc-content p {
      margin: 10px 0;
    }
    .submit-bloc .custom-control {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .upload-doc-bloc-content {
      width: 75%;
    }
    .upload-bloc, .upload-btn {
      display: block;
      width: 100%;
    }
    .upload-bloc {
      margin-right: 0;
    }
    .upload-btn {
      margin-top: 20px;
    }
  }
}
