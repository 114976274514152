.dashboard-content{
  .card-body{
    img.rounded{
      display: inline-block;
      height: 50px;
      vertical-align: middle;
    }
  }
  .owl-carousel.carouselFid{
    .owl-nav button.owl-prev{
      left: 0;
    }
    .owl-nav button.owl-next{
      right: 0;
    }
    .owl-stage-outer{
      overflow-x: hidden;
      .owl-item{
        width: 460px !important;
        margin: 40px 40px 0 40px;
      }
      .cart-section {
        height: 290px !important;

      }
      .user-card {
        display: table;
        position: relative;
        width: 100%;
        height: 280px;
        background: #6775a4;
        background: linear-gradient(135deg, #433e85 5%, #3f4d99 86%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#433e85", endColorstr="#3f4d99",GradientType=1 );
        border-radius: 16px;
        padding: 24px;
        margin: 0 auto 24px;
      }
      .fid-card:nth-child(n) {
        display: none;
      }
      .fid-card:nth-child(1) {
        position: absolute;
        display: block !important;
        opacity: 0.5;
        transform: scale(0.855) rotate(0deg) translate(0px, -49px);
        background: #b4bde2;
        box-shadow: none;
      }
      .fid-card:nth-child(2) {
        position: absolute;
        display: block !important;
        background: #aab0da;
        opacity: 1;
        transform: scale(0.94) rotate(0deg) translate(0px, -20px);
        box-shadow: none;
      }
      .fid-card:last-child {
        position: absolute;
        display: block !important;
        background: #f6F6F6;
        top: 0;
        opacity: 1;
        transform: scale(1);
        border: 1px solid #dfdfe642;
        text-shadow: -1px 1px 0px #00000080;
        box-shadow: -1px 1px 0px 0px #9091a5, 4px -4px 20px 0px #00000047;
        background: linear-gradient(135deg, #433e85 5%, #3f4d99 86%);
      }
      .fid-card .fid-card-header {
        position: absolute;
        top: 20px;
        right: 24px;
        left: 24px;
        height: 24px;
      }
      .fid-card .logo {
        width: 60px;
        border-radius: 12px;
      }
      .fid-card .fid-card-body {
        position: absolute;
        top: 40%;
        right: 24px;
        left: 24px;
        transform: translateY(-50%);
      }
      .fid-card .card-name {
        text-align: center;
        color: #ffffff;
        text-shadow: -1px 1px 0px #00000080;
      }
      .fid-card .card-name .marker {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
        color: #94a4b5;
      }
      .fid-card .fid-card-footer {
        position: absolute;
        right: 24px;
        left: 24px;
        bottom: 20px;
        margin: auto;
        text-align: center;
      }
      .fid-card .fid-card-footer svg:not(:root) {
        overflow: hidden;
      }
      .fid-card .fid-card-footer .barcode {
        border-radius: 6px;
      }
    }
  }
  .pme-bloc, .fidelity-section-carousel {
    .slick-dots {
      bottom: -20px;
    }
  }
  .pme-section{
    padding: 20px 0;

    .pme-bloc{
      .row:last-of-type{
        border: none;
      }
      .row{
        padding: 6px;
        img{
          width: 48px;
          margin: auto;
        }
        p.commerce-name{
          font-weight: 800;
          text-transform: capitalize;
          margin: 0;
        }
        p.montant, span.montant{
          font-weight: bold;
          color: #5a699c;
          font-size: 0.25em;
          text-align: center;
        }
        .divMontant{
          font-size: 80px;
        }
      }
    }
  }
  .cardMessagerie{
    table.vgt-table{
      thead{
        tr{
          th{
            color: #fff !important;
            background: #212529 !important;
            border-color: #32383e !important;
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6 !important;
            padding: 0.75rem;
            border-top: 1px solid #dee2e6 !important;
            font-size: 1.4rem;
            button{
              span{
                color: #fff;
              }
            }
          }
        }
      }
      tbody{
        tr:nth-of-type(odd){
          background-color: rgba(0,0,0,.05);
        }
        td{
          padding: .75rem;
          vertical-align: top;
          border-top: 1px solid #dee2e6;
          font-size: 1.4rem;
        }
      }
    }
  }
  .kdo-section{
    .card-body{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .rib-bloc{
    .rib{
      border-radius: 4px;
      margin-bottom: 32px;
      border-right: 1px solid #e7e7e7;
      border-top: 1px solid #e7e7e7;
    }
    .rib:nth-of-type(1){
      border-top: none;
    }
    .rib:nth-of-type(2){
      border-top: none;
    }
    .rib:nth-of-type(2n+0) {
      border-right: none;
    }
    .marker {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      color: #94a4b5;
    }
  }
  .cb-bloc{
    .cart-section {
      position: relative;
      margin: 0 auto;
      padding: 0;
      outline: none;
      cursor: grab;
    }
    .placeholder {
      border: solid 1px #e0e6f1;
      background: #e0e6f070 !important;
      box-shadow: none;

      .card-placeholder {
        p {
          text-shadow: none;
        }
      }
    }
    .user-card:not(.user-card-loading) {
      display: table;
      position: relative;
      background: #6775a4;
      background: linear-gradient(135deg, #433e85 5%, #3f4d99 86%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#433e85", endColorstr="#3f4d99",GradientType=1 );
      padding: 24px;
      margin: 0 auto 24px;
      text-shadow: -1px 1px 0px #00000080;
      box-shadow: -1px 1px 0px 0px #9091a5, 4px -4px 20px 0px #00000047;
      .card-logo{
        display: block;
        width: 64px;
      }
      .payment-card-body{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        left: 24px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-shadow: -1px 1px 0px #00000080;
      }
      .payment-card-footer{
        position: absolute;
        right: 24px;
        left: 24px;
        bottom: 24px;
        p{
          color: #fff;
          font-weight: 600;
          margin-bottom: 0;
          text-shadow: -1px 1px 0px #00000080;
        }
      }
    }
    .card-placeholder, .card-loader {
      position: initial;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }
}
// Récap des informations utilisateurs
.user-info-recap {
  display: flex;
  width: 100%;
  padding: 20px;
  border: 1px solid $grey;
  border-radius: 10px;
  gap: 20px;

  .user-info {
    &__avatar {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      overflow: hidden;

      i {
        font-size: 8rem;
      }
    }
    &__content {
      width: calc(100% - 80px);
      row-gap: 10px;
    }
    &__name {
      font-size: 2rem;
      font-weight: 700;
    }
    &__label-data {
      div {
        font-size: 1.4rem;
        font-weight: 600;
        color: $dark;

        &:first-child {
          font-size: 1.2rem;
          color: $grey;
        }
      }
    }
  }
}
.user-info-edit {
  row-gap: 15px;
}
// Pour l'affichage Fullscreen
#vue_onboarding, #vue_cashless, #vue_fidelite {
  .user-popup {
    width: 200px;
    padding: 10px 15px;
    top: 100%;
    right: 0;
    background: white;
    text-align: center;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    .user-name {
      display: none;
    }
    a {
      color: $red;
    }
  }
}
// Menu utilisateur
.btn-nav-user-mobile {
  display: none;
}
@media (max-width: 992px) {
  #vue_user {
    padding-top: 40px;
  }
  .btn-nav-user-mobile, aside.nav-user {
    position: fixed;
    top: 95px;
    background: $dark;
    color: white;
    transition: top 0.3s ease-in-out;

    &.stick-top {
      top: 0;
    }
  }
  .btn-nav-user-mobile {
    display: flex;
    padding: 10px 15px;
    left: 0;
    font-size: 1.6rem;
    align-items: center;
    z-index: 15;

    span {
      margin-left: 10px;
      font-weight: 700;
    }
  }
  aside.nav-user {
    right: 100%;
    padding-top: 40px;
    transition: all 0.3s ease-in-out;
    z-index: 10;

    &.show {
      right: 0;
    }
    h3 {
      display: none;
    }
    .bg-white {
      margin-bottom: 1rem;
      background: $dark !important;
      color: white;
    }
    li {
      &:last-child {
        a {
          border: none;
        }
      }
      a {
        color: white;

        &:before {
          border-left: 4px solid white;
        }
        &.active {
          color: $dark;

          &:before {
            border-left: 4px solid $dark;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  #vue_onboarding, #vue_fidelite {
    .user-popup {
      .user-name {
        color: var(--primary-color);
        display: block;
      }
    }
  }
  .btn-nav-user-mobile, aside.nav-user {
    top: 60px;
  }
  .user-info-recap {
    padding: 20px 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .user-info {
      &__avatar {
        width: 50px;
        height: 50px;
        border-radius: 25px;

        i {
          font-size: 5rem;
        }
      }
      &__content {
        width: 100%;
      }
      &__name {
        text-align: center;
      }
    }
  }
  .user-info-edit {
    row-gap: 5px;
  }
}
@media screen and (min-width: 769px) {
  .mobile-only{
    display: none;
  }
  .desktop-only{
    display: block;
  }
  .cb-bloc{
    .cart-section{
      width: 460px !important;
    }
    .placeholder{
      padding: 16px 32px;
      position: relative;
    }
    .user-card{
      width: 100%;
      border-radius: 16px;
      height: 280px;
    }
  }
}
@media screen and (max-width: 768px) {
  .mobile-only{
    display: block;
  }
  .desktop-only{
    display: none;
  }
  .cb-bloc{
    .cart-section {
      width: 290px !important;
      margin: 16px 10px 8px;
    }
    .user-card {
      height: 175px;
      width: 100%;
      border-radius: 8px;
    }
  }
}

.datatableMessagerie tbody tr td:last-of-type {
  min-height: 33px;
}
