

.marquesContainer {
  h2 {
    text-align: center;
    text-transform: uppercase;
  }

  .boutonMarques {

    text-align: center;
    text-transform: uppercase;
    margin-top: 50px;

  }
}

#bgPagesMarques{
  position: relative;
  z-index: 0;
  padding-bottom: 60px;
  .container:first-of-type{
    padding-top: 32px;
    padding-bottom: 32px;
    h2{
      color: var(--white-color);
    }
  }
}
#vue_marques{
  .searchMarques{
    position: relative;
    margin-top: -40px;
  }
}
