#vue_login, #vue_updatePassword, #vue_cashless, #vue_slider_images, #vue_slider_images_admin, #section-offre-cadeaux, #vue-achat-courriers, #vue_fidelite, #vue_paiement, #modalAdminPro, .add-actu-main-bloc, .edit-actu-main-bloc { // TEMPORAIRE
  .btn {
    &:not(.icon-up-dir, .icon-down-dir, .as-link) {
      padding: 10px 15px;
      border-radius: 10px;
      text-align: center;
      text-transform: none;
      color: white;
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      transition: all 0.3s ease-in-out;
    }
    &-suppr {
      width: 50px;
      margin: 0 auto;
      background-color: $red;
      border: none;

      @media (max-width: 576px) {
        width: auto;
      }
    }
    &.darker {
      background-color: var(--primary-color-dark);
      border-color: var(--primary-color-dark);
    }
    &.lighter {
      background-color: var(--primary-color-light);
      border-color: var(--primary-color-light);
    }
    &.dark {
      background-color: $dark;
      border-color: $dark;

      &:hover {
        background-color: $dark;
        border-color: $dark;
      }
    }
    &.disabled {
      color: $dark;
      background-color: $grey;
      border-color: $grey;
    }
    &.outline {
      color: var(--primary-color);
      background-color: transparent;
      font-weight: 400;

      &:hover {
        color: white;
        background-color: var(--primary-color);
      }
      &.disabled {
        color: $grey;
        border-color: $grey;
      }
    }
    &.outline-dark {
      border-color: $dark;
      color: $dark;
      background-color: transparent;

      &:hover {
        color: white;
        background-color: $dark;
      }
      &.disabled {
        color: $grey;
        border-color: $grey;
      }
    }
    &.outline-light {
      border-color: $light-grey;
      color: $grey;
      background-color: transparent;

      &:hover {
        color: $dark;
        background-color: $light-grey;
      }
      &.disabled {
        color: $grey;
        border-color: $grey;
      }
    }
    &.cancel {
      border: none;
      background-color: transparent;
      color: $red;
    }
    &.tiny {
      padding: 10px 15px;
      border-radius: 0;
    }
  }
  .btn-icon {
    display: flex;
    width: 42px;
    height: 42px;
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    color: white;
  }
  a.btn {
    display: block;
  }
  .as-link {
    color: $dark;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
  }
}
.btn-primary-reverse {
  background-color: white;
  color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
}
.btn {
  &.btn-secondary-alt {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);

    &:active, &:focus {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
    }

    &.color-dark {
      color: $dark;

      &:active, &:focus {
        color: $dark;
      }
    }
  }
}
.btn {
  &-add, &-save, &-valid, &-param {
    &:before {
      font-family: 'FontAwesome', sans-serif;
      margin-right: 5px;
    }
    &.load:before {
      content: none;
    }
  }
  &-add:before {
    content: '\f067';
  }
  &-save:before {
    content: '\f0c7';
  }
  &-valid {
    background-color: $green;
    border-color: $green;

    &:before {
      content: '\f00c';
    }
  }
  &-param:before {
    content: '\f085';
  }
}
.as-btn {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
button {
  cursor: pointer;
}
