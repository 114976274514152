#modalDisclaimer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 9999;

  .modal {
    position: relative;
    display: block;
    width: 600px;
    margin: 50px auto 0 auto;
    background: white;

    &-options {
      position: absolute;
      top: 30px;
      right: 30px;

      i {
        cursor: pointer;
      }
    }

    &-title, &-content, &-actions {
      width: 100%;
      box-sizing: border-box;
    }

    &-title {
      padding: 30px;
      border-bottom: 1px solid $light;
      text-transform: uppercase;
      h5 {
        margin: 0;
      }
    }

    &-content {
      margin: 0;
      padding: 30px;
      border: none;
      box-shadow: none;
    }
    
    &-actions {
      padding: 15px 30px;
      text-align: right;
      border-top: 1px solid $light;
    }
  }
}

.modal-laterale {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33,33,33,0.8);
  z-index: 9999;

  &-bloc {
    position: absolute;
    top: 0;
    left: 100%;
    width: 50%;
    height: 100vh;
    padding: 30px 30px 15px 30px;
    background-color: white;
    transition: 0.3s left ease-in-out;

    &.opened {
      left: 50%;
    }
    .modal {
      &-content {
        margin: 0;
        border: none;
        box-shadow: none;
      }
      &-header {
        position: relative;
        height: auto;
        padding: 0;
        border: none;

        .close-btn {
          font-size: 2.5rem;
          color: $dark;
          transition: color 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
      &-title, &-body-title {
        font-family: $font-1;
        font-weight: 700;
        text-transform: uppercase;
        color: $dark;
      }
      &-title {
        font-size: 2.6rem;
      }
      &-body-title {
        font-size: 1.8rem;
        margin-bottom: 15px;
      }
      &-body {
        height: calc(100vh - 215px);
        max-height: none;
        padding: 0;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(33,33,33,0.2);
          border-radius: 5px;
        }
      }
      &-actions {
        padding-top: 15px;
        text-align: right;
      }
    }
    .separateur {
      width: 100px;
      height: 6px;
      margin: 30px 0;
      background-color: $dark;
    }
  }
}
.modal-simple {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100dvh;
  padding: 20px 0;
  top: 0;
  left: 0;
  justify-content: center;
  background: rgba(33,33,33,0.9);
  z-index: 100;
  transition: all 0.3s ease-in-out;
  opacity: 0;

  &-bloc {
    max-height: calc(100dvh - 40px);
    background: white;
    transition: all 0.3s ease-in-out;
    scale: 0;

    &.tiny {
      width: 576px;
    }
    &.medium {
      width: 992px;
    }
    &.large {
      width: 1400px;
    }
    &.fullscreen {
      width: 100vw;
    }
  }
  &.animate-in {
    opacity: 1;

    .modal-simple-bloc {
      scale: 1;
    }
  }
  .modal-header, .modal-actions {
    height: 70px;
  }
  .modal-title {
    font-family: $font-1;
    font-weight: 700;
    font-size: 1.8rem;
  }
  .modal-actions {
    display: flex;
    padding: 0 3rem;
    justify-content: space-between;
    align-items: center;
  }
  .separateur {
    width: 100%;
    height: 1px;
    background-color: $light-grey;
  }
  // Custom slots
  .modal-header-custom {
    height: 70px;
    padding: 0 3rem;
  }
}
// Petit ajustement pour les petits écrans bureau
@media (max-width: 1400px) {
  .modal-simple-bloc {
    &.large {
      width: 100%;
    }
  }
}

@media (max-width: 1280px) {
  .modal-laterale {
    &-bloc {
      width: 75%;
      &.opened {
        left: 25%;
      }
    }
  }
}

@media (max-width: 992px) {
  .modal-laterale {
    &-bloc {
      width: 100%;
      padding: 20px;
      &.opened {
        left: 0;
      }
      .modal-body {
        height: calc(100dvh - 205px);
      }
    }
  }
  .modal-simple-bloc {
    &.medium {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  #modalDisclaimer {
    .modal {
      width: 100%;
    }
  }
  .modal-simple {
    height: 100dvh;
    padding: 0;

    &-bloc {
      height: 100%;
      max-height: 100%;
    }
    .modal-body {
      height: calc(100% - 140px);
      overflow-y: auto;
    }
  }
}

@media (max-width: 576px) {
  .modal-simple-bloc {
    &.tiny {
      width: 100%;
    }
  }
}
