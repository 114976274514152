@include respond-to('small'){
  .sliderProducts, .sliderCommerces{
    .owl-carousel:not(.product-single-carousel) .owl-nav button.owl-next, .owl-carousel:not(.home-slider) .owl-nav button.owl-next{
      right: 0;
    }
    .owl-carousel:not(.product-single-carousel) .owl-nav button.owl-prev{
      left: 0;
    }
  }
}
/* Override styles Slick Carousel */
.slick-slider {
  .slick-arrow {
    &.slick-prev, &.slick-next {
      &:before {
        font-family: $fa;
        font-weight: 900;
        color: $grey;
      }
    }
    &.slick-next {
      &:before {
        content: '\f054'
      }
    }
    &.slick-prev {
      &:before {
        content: '\f053'
      }
    }
  }
  .in-slider {
    padding: 0 5px;

    figure {
      background: transparent;
    }
  }
}
/* Concerne le slider accueil et son administration */
#vue_slider_images_admin {
  .slider-admin-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
  .infos-slider {
    padding: 15px;
    background-color: $orange;
    color: white;

    p:last-child {
      margin-bottom: 0;
    }
  }
  .btn-help {
    position: absolute;
    display: flex;
    bottom: -50px;
    right: 30px;
    padding: 10px 15px;
    border: 1px solid $dark;
    align-items: center;
    gap: 5px;
    border-radius: 50px;
    background-color: white;
    color: $dark;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $dark;
      color: white;
    }
    span {
      line-height: 1em;
    }
  }
  .modal-body-help {
    padding-left: 15px;
    margin-bottom: 15px;
    border-left: 5px solid;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
/* Concerne le slider de la page d'accueil */
#vue_home {
  #vue_slider_images {
    .slick-prev {
      left: 25px;
      z-index: 10;
    }
    .slick-next {
      right: 25px;
      z-index: 10;
    }
  }
}
.slider-bloc {
  position: relative;
  height: 500px;

  .custom-dots {
    bottom: 15px;

    li {
      opacity: 0.5;

      &.slick-active {
        opacity: 1;

        button {
          background-color: var(--primary-color)
        }
      }
      button {
        width: 10px;
        height: 10px;
        margin: 5px auto;
        border-radius: 10px;
        border: 1px solid white;
        background-color: $dark;

        &:before {
          content: none;
        }
      }
    }
  }
  img {
    width: auto;
    height: 500px;
    margin: 0 auto;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center top;
    -o-object-position: center top;
  }
}
.slides-bloc {
  position: relative;

  .single-slide {
    position: relative;
    height: 150px;
    margin-top: -1px;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
  }
  .slide {
    &-disabled {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255,255,255,0.8);
      z-index: 9999;
    }
    &-ordre {
      position: relative;
      cursor: move;

      svg {
        width: 30px;
        height: 150px;
        fill: $grey;
      }
      p {
        position: absolute;
        width: 30px;
        bottom: 0;
        left: 10px;
        margin: 0;
        text-align: center;
        font-family: $font-1;
        font-size: 3rem;
        font-weight: 700;
        color: $light;
      }
    }
    &-img {
      max-height: 149px;
      padding: 10px;
      overflow: hidden;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
      .slim[id*='cropper'] {
        cursor: pointer;
      }
      .slim-btn-group {
        display: none;
      }
    }
    &-upload {
      width: 100%;
      max-height: 149px;
      padding: 10px;
      overflow: hidden;

      &-bloc {
        height: 100%;
        margin: 0;
        border: 2px dashed $light;
        color: $dark;
        text-align: center;
        cursor: pointer;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
      }
      .upload-txt {
        margin: 0;
      }
      input {
        display: none;
      }
      i {
        font-size: 4rem;
      }
    }
    &-error {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        font-weight: 700;
        color: $red;
      }
    }
    &-data, &-actions {
      display: flex;
      flex-direction: column;
    }
    &-data {
      padding: 10px;
      justify-content: start;

      &> div:last-child {
        .input-bloc {
          margin-bottom: 0 !important;
        }
      }
    }
    &-actions {
      justify-content: center;
    }
    &-loader {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(33,33,33,0.8);
      z-index: 9999;
    }
  }
}

@media (max-width: 1280px) {
  .slider-bloc {
    height: 350px;

    img {
      height: 350px;
    }
  }
}

@media (max-width: 992px) {
  .slick-slider {
    .slick-arrow:before {
      display: none;
    }
  }
  .slider-bloc {
    height: 250px;

    img {
      height: 250px;
    }
  }
  .slider-bloc {
    .owl-theme {
      .owl-item {
        img {
          height: auto;
          min-height: 150px
        }
      }
    }
  }
  #vue_slider_images_admin {
    .btn-help {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .slider-bloc {
    height: 200px;

    img {
      height: 200px;
    }
  }
}

@media (max-width: 576px) {
  .slider-bloc {
    height: auto;

    img {
      height: auto;
    }
  }
  .slides-bloc {
    .single-slide {
      position: relative;
      height: auto;
    }
    .slide {
      &-ordre {
        svg {
          height: 120px;
        }
        p {
          top: 0;
          bottom: initial;
        }
      }
      &-actions {
        position: absolute;
        top: 10px;
        right: 0;

        .btn.tiny {
          padding: 10px;
          width: auto;
        }
      }
      &-upload-bloc {
        max-width: 100%;
        max-height: 160px;
        margin: 10px 0;
      }
    }
  }
}
