/* Spécifique pour le loader du panier */
#vue_paiement {
  .col {
    &-product, &-price, &-quantity {
      display: table-cell;
      color: $dark;
    }
    &-product {
      width: auto;
    }
    &-price {
      width: 160px;
      padding: 0 10px;
      text-align: right;
    }
    &-quantity {
      width: 110px;
    }
    &-total-price {
      min-width: 75px;
    }
  }
  .cart-table {
    &__header {
      display: table;
      width: 100%;
      height: 50px;
      padding: 0 10px;
      border-bottom: 1px solid $light-grey;

      .col {
        &-product, &-price, &-quantity {
          vertical-align: middle;
          font-size: 1.3rem;
          color: $grey;
          text-transform: uppercase;
        }
        &-product {
          padding-left: 100px;
        }
      }
    }
    &__body {
      .product-single {
        display: table;
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid $light-grey;
      }
      .col {
        &-price, &-quantity {
          vertical-align: middle;
        }
        &-product {
          vertical-align: top;

          &__image {
            width: 80px;
            margin: 0;
            padding: 0;

            a {
              display: block;
              width: 80px;
            }
            img {
              max-height: 80px;
            }
          }
          &__title {
            p {
              font-size: 1.6rem;
              font-weight: 700;
            }
          }
          &__variant {
            padding: 0;
            color: var(--primary-color);

            .variant-libelle {
              margin-right: 5px;
              font-weight: 400;
            }
          }
        }
        &-price {
          font-size: 1.8rem;
          font-weight: 700;

          .promo-price {
            font-size: 1.4rem;
            font-weight: 400;
            color: $grey;
          }
        }
      }
    }
    .cart-loader {
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(255,255,255,0.9);
      z-index: 1000;
    }
    .txt-loader {
      position: absolute;
      width: 100%;
      top: calc(50% + 25px);
      left: 0;
      text-align: center;
      font-family: $font-2;
      font-weight: 700;
      color: $light-grey;
    }
    .delete-btn, .text-red {
      color: $red;
    }
  }
  .infos-header {
    height: 50px;
    border-bottom: 1px solid $light-grey;
    padding: 0 15px;

    h3 {
      font-size: 1.6rem;
      color: $dark;
    }
  }
  .delivery-single {
    background-color: white;

    &__header {
      height: 50px;
      padding: 0 15px;
      border-bottom: 1px solid $light-grey;

      h3 {
        font-size: 1.6rem;
      }
    }
  }
  .checkout-steps {
    background-color: white;

    .step-title {
      display: flex;
      height: 50px;
      padding: 0 15px;
      border-bottom: 1px solid $light-grey;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 700;
    }
    .payment-step {
      padding: 0 15px;
    }
  }
  .hipay-form-container {
    text-align: left;
  }
  .input-group-prepend, .input-group-append {
    &.bootstrap-touchspin-injected {
      display: none;
    }
  }
  .cb-single {
    &-container {
      position: relative;
      display: flex;
      width: 100%;
      height: 65px;
      margin-bottom: 10px;
      padding: 0 15px;
      border: 1px solid $grey;
      border-radius: 10px;
      align-items: center;
      overflow: hidden;
      transition: all 0.3s ease-in;

      &.active {
        border-color: var(--primary-color);
      }
      &.error {
        border-color: $red;

        .cb-single__state {
          background: $red;
        }
      }
    }
    &__logo {
      margin-right: 10px;
    }
    &__name, &__holder, &__expiration {
      font-size: 1.4rem;
    }
    &__name {
      width: 30%;
      font-weight: 700;
    }
    &__holder {
      width: 30%;
    }
    &__expiration {
      width: 60px;
      text-align: right;
    }
    &__cvc {
      display: flex;
      width: calc(40% - 130px);
      justify-content: center;

      .input-red {
        input {
          border-color: $red;
        }
        label {
          color: $red;
        }
      }
      .input-bloc {
        margin-bottom: 0.8rem;

        &:focus-within label {
          top: -8px;
        }

        input {
          width: 50px;
          height: 30px;
          padding: 8px 0 0 0;

          &:not(:placeholder-shown) + label {
            top: -8px;
          }
        }
        label {
          top: 10px;
        }
      }
      .cvc-txt {
        font-weight: 700;
        color: $green;
      }
    }
    &__action {
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
    }
    &__state {
      position: absolute;
      padding: 5px 10px;
      top: 0;
      right: 0;
      border-radius: 0 0 0 10px;
      font-size: 1.2rem;
      line-height: 1em;
      background: var(--primary-color);
      color: white;

      span {
        font-weight: 700;
      }
    }
  }
}
//-- Panier header --//
.cart-dropdown-bloc {
  position: relative;

  .dropdown-menu {
    display: block;
    width: 300px;
    padding: 0;
    margin-top: 10px;
    right: 0;
    left: unset;
    border: none;
    border-radius: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  .cart-header-title {
    width: 100%;
    padding: 15px 0;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    font-family: $font-1;
    font-weight: 700;
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  .cart-empty {
    padding-bottom: 10px;
    text-align: center;

    img {
      width: 50%;
      max-width: 150px;
      height: auto;
      margin: 0 auto 10px auto;
    }
    p {
      font-size: 1.4rem;

      &:nth-child(2) {
        margin-bottom: 10px;
        font-size: 1.6rem;
      }
    }
  }
  // Rewrite styles de _dropdowns.scss
  .product {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 1.6rem 1rem;
    border-bottom: 1px solid #e6ebee;
    box-shadow: none;

    &-details {
      font-size: 1.3rem;
    }
    &-title {
      padding-right: 1.5rem;
      margin-bottom: 8px;
      font-size: 1.3rem;
      line-height: 19px;

      a {
        color: #696969;
      }
    }
    &-image-container {
      position: relative;
      max-width: 80px;
      margin: 0 0 0 auto;
      border: 1px solid #ededed;

      img {
        object-fit: none;
      }
    }
  }
  .btn-remove {
    position: absolute;
    top: -9px;
    right: -3px;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: inherit;
    background-color: #fff;
    box-shadow: 0 2px 6px rgb(0 0 0 / 50%);
    text-align: center;
    line-height: 2rem;
  }
}

@media (max-width: 992px) {
  #vue_paiement {
    .cart-table {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 768px) {
  #vue_paiement {
    .cart-table__header {
      display: none;
    }
    .cart-table__body {
      .product-single, .col-product {
        display: block;
      }
      .col-product {
        width: 100%;
        padding: 0;

        &__title {
          padding-right: 0;

          p {
            margin-bottom: 5px;
          }
        }
      }
      .col-price {
        display: inline-flex;
        width: calc(100% - 105px);
        height: 42px;
        padding-right: 0;
        padding-left: 90px;
        align-items: center;
        text-align: left;
        vertical-align: top;
        gap: 5px;

        .promo-price {
          margin-top: 5px;
          font-size: 1.3rem;
        }
      }
      .col-quantity {
        display: inline-block;
        width: 100px;
        padding-right: 0;
        vertical-align: top;
      }
    }
    .cb-single {
      &-container {
        height: inherit;
        padding: 15px;
        flex-wrap: wrap;
      }
      &__name {
        width: calc(50% - 60px);
      }
      &__holder {
        width: 50%;
      }
      &__expiration {
        width: calc(50% - 60px);
        height: 40px;
        margin-left: 60px;
        line-height: 4rem;
        text-align: left;
      }
      &__cvc {
        width: calc(50% - 30px);
        justify-content: flex-start;
      }
    }
  }
}
@media (max-width: 576px) {
  #vue_paiement {
    .cb-single {
      &__name, &__holder {
        width: calc(100% - 60px);
      }
      &__holder {
        margin-top: 10px;
        margin-left: 60px;
      }
      &__expiration, &__cvc {
        margin-top: 5px;
      }
      &__cvc {
        justify-content: center;
      }
    }
  }
}
