.slim .slim-btn-group {
  z-index: 1040;
}

#add-article-pic .slim.add-logo-item .slim-area {
  position: absolute;
  top: 0;
}

.drop-area[data-state="empty"], .drop-area[data-state="empty,file-over"] {
  .slim-status {
    height: 100%;
  }
}
