//-- Payment --//
.add-cb-bloc, .choose-cb-bloc {
  padding: 20px;

  .btn {
    display: block;
    margin-left: auto;
  }
}
.choose-cb-bloc {
  margin-top: 20px;
}

.choose-cb-bloc {
  position: relative;

  &.active {
    border: 1px solid var(--primary-color);

    &:after {
      background: var(--primary-color);
      position: absolute;
      top: 0;
      right: 0;
      width: 2.7rem;
      height: 2.7rem;
      padding-top: .2rem;
      color: #fff;
      font-family: 'porto', sans-serif;
      font-size: 1.6rem;
      line-height: 2.1rem;
      text-align: center;
      content: '\e84e';
    }
  }
}
