#vue_paiement {
  .my-date-picker {
    label {
      margin: 0 0 15px;
    }
    .vdp-datepicker {
      width: 9em;
    }
  }
  .vue__time-picker {
    input.display-time {
      width: 100%;
      height: 42px;
      padding: 0 1rem;
      border-color: #dfdfdf;
      color: var(--grey-darker-color);
      font-family: "Open Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5;
    }
    .dropdown ul li:not([disabled]).active {
      background: var(--primary-color);
    }
    .controls {
      align-items: center;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      background-color: #e9ecef;
      font-size: 1rem;
      line-height: 1.5;

      .clear-btn {
        margin-bottom: -5px;
        padding: 0.5rem 1rem;
      }
      .char {
        color: var(--primary-color);
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }
}
// --Responsive
@media (max-width: 576px) {
  #vue_paiement {
    .timepicker-bloc {
      display: block !important;
      margin-top: 10px;
    }
    .my-date-picker {
      .vdp-datepicker {
        width: 10em;
      }
    }
  }
}