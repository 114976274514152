/*Desktop -start*/

#infoFid {
  .fidelity-section .card .user-card .fidelity-bloc {
    .no-gutters {
      padding: 32px;
      border-bottom: 1px solid #e0e6f1;
    }

    .desktop-only .donut-size {
      font-size: 12em;

      .pie-wrapper {
        font-size: 80px;
        position: relative;
        width: 1em;
        height: 1em;
        margin: 0 auto;

        .label {
          font-weight: bold;
          color: #5a699c;
          position: absolute;
          top: 0.4em;
          right: 0.4em;
          bottom: 0.4em;
          left: 0.4em;
          display: block;
          background: none;
          border-radius: 50%;
          color: #7F8C8D;
          font-size: 0.25em;
          line-height: 2.6em;
          text-align: center;
          cursor: default;
          z-index: 2;
        }

        .pie {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          clip: rect(0, 1em, 1em, 0.5em);

          .half-circle {
            border: 8px solid #5e53f3;
            border-top-width: 8px;
            border-right-width: 8px;
            border-bottom-width: 8px;
            border-left-width: 8px;
            transition: all 1s cubic-bezier(0.92, 0.18, 0.46, 1.28);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            clip: rect(0em, 0.5em, 1em, 0em);
            background-color: white;
          }
        }

        .shadow {
          border: 8px solid #e0e6f1;
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }
    }
  }

  > div > div.card > div.user-card.placeholder > div > div > div {
    &.col-2.colImage > img {
      width: 48px;
      margin: auto;
    }

    &.col-8.text-left > p {
      &.commerce-name {
        font-weight: 800;
        text-transform: capitalize;
        margin: 0;
      }

      &.text-lighter {
        color: #7a8da3;
        font-size: 14px;
      }
    }
  }
}

/*Mobil -start*/

#infoFid > div > div.card > div.user-card.placeholder > div > div > div.col-2.colImage {
  .pie-wrapper {
    .label {
      font-weight: bold;
      color: #5a699c;
      position: absolute;
      top: 0.4em;
      right: 0.4em;
      bottom: 0.4em;
      left: 0.4em;
      display: block;
      background: none;
      border-radius: 50%;
      color: #7F8C8D;
      font-size: 0.25em;
      line-height: 2.6em;
      text-align: center;
      cursor: default;
      z-index: 2;
    }

    .pie {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip: rect(0, 1em, 1em, 0.5em);

      .half-circle {
        border: 8px solid #5e53f3;
        transition: all 1s cubic-bezier(0.92, 0.18, 0.46, 1.28);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        clip: rect(0em, 0.5em, 1em, 0em);
        background-color: white;
      }
    }

    .shadow {
      border: 8px solid #e0e6f1;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  .donut {
    font-size: 12em;
  }

  .pie-wrapper {
    font-size: 60px;
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  #infoFid > div > div.card > div.user-card.placeholder > div > div > div.col-8.text-left > p.text-lighter {
    font-size: 12px;
  }
}

