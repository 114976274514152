@media (min-width: 992px){
  main:not(.sidebar-opened) .commerces .sidebarListeCarte.sidebar-shop{
    display: none;
  }
  main.sidebar-opened .commerces .sidebarListeCarte.sidebar-shop{
    display: block;
  }
  main.sidebar-opened .commerces .main-content-wrap .col-lg-7{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (max-width: 991px){
.commerces .mobile-sidebar {
  padding-left: 2rem !important;
}
}
.sidebar-shop{
  .sidebar-wrapper.noTop{
    top: 0!important
  }
}
main .commerces .main-content-wrap .col-lg-5{
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 2rem;
}
@media screen and (min-width: 1220px){
  main .commerces .sidebarListeCarte.sidebar-shop .sidebar-wrapper{
    min-width:290px;
  }
}
@media screen and (max-width: 1219px){
  main .commerces .sidebarListeCarte.sidebar-shop .sidebar-wrapper{
    min-width:275px;
  }
}
@media screen and (max-width: 1200px){
  main .commerces .sidebarListeCarte.sidebar-shop .sidebar-wrapper{
    min-width:230px;
  }
}
@media screen and (max-width: 991px){
  main .commerces .sidebarListeCarte.sidebar-shop .sidebar-wrapper{
    min-width:auto;
  }
}
.commerces {
  .colCommercant.col-6 {
    max-width: calc(50% - 3rem);
  }
  .colCommercant:nth-of-type(4n + 1){
    margin-left: 0 !important;
  }
  .colCommercant:nth-of-type(4n){
    margin-right: 0 !important;
  }
  @media (min-width: 768px){
    .colCommercant.col-md-4 {
      max-width: calc(33.333333% - 3rem);
    }
  }
  @media (min-width: 1200px){
    .colCommercant.col-xl-3 {
      max-width: calc(25% - 3rem);
    }
  }
  .text-activation {
    color: white;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
    font-weight: 600;
    cursor: pointer;
  }

  .activation {
    background-color: var(--black-color);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .product-widget .product-details.contact-commerces{
    max-width: 100%;
    width: auto;
  }
  .product-default.left-details.product-widget.d-block{
    .horaireCommerces{
      p{
        margin-bottom: 0;
      }
    }
  }
  h4{
    font-size: 1.6rem;
  }
  .bloc-contact-commerces{
    padding: 10px;
    .btn{
      text-transform: none;
      font-family: 'Open Sans';
    }
    .contact-link{
      background: var(--white-color);
      .btn{
        color: var(--primary-color);
        position: relative;
        //&:last-of-type{
        //  &:after{
        //    display: none;
        //  }
        //}
        //&:after{
        //  content: ' ';
        //  height: 80%;
        //  width: 1px;
        //  position: absolute;
        //  right: 0;
        //  top: 50%;
        //  transform: translateY(-50%);
        //  background-color: var(--primary-color);
        //}
      }
    }
  }
  .map-responsive {
    overflow: hidden;
    position: relative;
    height: 200px;
    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  .custom-control{
    margin-top:0;
    margin-bottom:0;
  }
  .col-commerces{
    box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
    &:not(last-of-type){
      //border-bottom: 1px solid rgba(204, 204, 204, 0.5);
      padding-bottom: 1.4rem;
    }
    &:not(first-of-type){
      padding-top: 1.5rem;
    }
  }
  .product-default:not(.product-small){
    figure{
      max-width: 100px;
      height: 100px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .product-default.product-small{
    figure{
      background:none;
    }
  }
  .product-default{
    .cheques-kdo-commerces{
      img{
        height: 30px;
      }
    }
    .product-details{
      h2 a{
        color: var(--primary-color);
        font-weight: bold;
        font-size: 20px;
      }
      .categorie-commerce{
        font-size: 1.2rem;
      }
      .horaires, .horaires-commerces{
        margin-bottom: 10px;
        .commerces-open{
          color: var(--primary-color);
        }
        .horaire {
          font-size: 12px;
          font-weight: bolder;
          //display: inline-block;
        }
        .status {
          font-size: 12px;
          font-weight: bolder;
          margin-left: 5px;
          margin-right: 5px;
          //display: inline-block;
          color: var(--primary-color);
        }
        .status::after {
          content: '\2022';
          margin-left: 5px;
        }
        .horaire_ouverture {
          font-size: 12px;
          font-weight: bolder;
        }
      }
      .infos-commerces.d-flex{
        min-height: 100px;
        max-height: 145px;
        align-items: flex-start;
        .product-title-boutique{
          text-align: left;
          line-height: 13px;
        }
      }
    }
    &.product-widget{
      margin-bottom: 0;
    }
  }
  .product-action{
    margin-top: 10px;
    .btn{
      padding: 1rem 2rem;
      font-size: 1.1rem;
    }
  }
}

@include respond-to('small'){
  .commerces {
    .linkCommerce{
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    .product-default.left-details.product-widget.d-flex{
      flex-wrap: wrap;
      .product-details{
        max-width: calc(100% - 100px - 1.5rem);
      }
    }
    .bloc-contact-commerces{
      .btn{
        height: 34px;
        line-height: 24px;
      }
      .btn:not(.btnTel){
        //width: 48px;
        font-size: 22px !important;
      }
    }
    .product-default .product-details .infos-commerces.d-flex{
      margin-bottom: 3rem !important;
      min-height: auto;
    }
    .product-default:not(.product-small) figure{
      width: 64px;
      height: 64px;
      margin-left: 0;
    }
    .product-default.left-details.product-widget.d-flex .product-details{
      margin-left: 0px;
    }
    .opened-state, .closed-state{
      font-weight: bold;
    }
    .hidden-desktop.d-flex{
      display: flex !important;
    }
    .bloc-contact-commerces.w-100.d-flex.hidden-desktop{
      flex-direction: row;
      align-items: center;
      .text-left{
        margin-bottom: 0;
      }
    }
    .product-default .product-details .infos-commerces.d-flex .product-title-boutique{
      text-transform: none;
      font-size:1.7rem;
      margin-bottom: 0.5rem!important;
      a{
        font-size: inherit;
      }
    }
    .bloc-contact-commerces{
      flex-direction: column;
      .contact-link{
        margin-top: 2rem;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 500px){
  .commerces {
    .bloc-contact-commerces{
      .contact-link{
        flex-direction: column;
        .btn:after{
          display:none;
        }
      }
    }
  }
}


//.h4-title-boutiques {
//  h2 {
//    margin-left: 460px;
//    text-transform: uppercase;
//  }
//
//}
//
//.h4-card {
//  text-align: left;
//  text-transform: uppercase;
//}
//
//.custom-control-label {
//  text-align: center;
//}
@include respond-to('small'){
  .main.commerces{
    .toolbox-item.toolbox-sort{
      display: none;
    }
    .contentCommerces{
      position: relative;
        .btnShowListe{
          color:#282d3b;
        }
      .colListeCommerces{
        order: 2;
        position: absolute;
        z-index: 2;
        background: var(--white-color);
        height: 300px;
        bottom: 0;
        margin-bottom: 0;
        padding-bottom: 20px;
        .mobilBtnShow{
          display: flex;
          position: sticky;
          z-index: 9;
          background: var(--white-color);
          top: 0;
          left: 0;
          right: 0;
        }
        .btnSwitch{
          font-size: 1.4rem;
          text-transform: uppercase;
          color: #282d3b;
          font-weight: 700;
          padding: 1.2rem 0;
        }
        .btnSwitch:last-of-type{
          margin-left: auto;
        }
        &.commerceFullHeight{
          height: 100%;
          top: 0;
        }
        .rowCommerce{
          margin-left: 0;
          margin-right: 0;
        }
      }
      .colCarteCommerces{
        order: 1;
        padding: 0;
        z-index: 1;
      }
    }
  }
}