.order {
  .shipping-address-box.active .address-box-action {
    display: none;
  }

  .shipping-address-box {
    margin-top: 1rem;
  }

  .table.table-totals {
    border-top: 0;
  }
}

#pinWrapperBasket {
  margin-bottom: 2.5rem;
}

.sidebar-basket {
  .widget {
    border: none;
    background: var(--white-color);
    box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
    border-radius: 4px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-top: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .widget-title {
      display: flex;
      height: 50px;
      margin: 0;
      padding: 0 15px;
      border-bottom: 1px solid $light-grey;
      align-items: center;

      h3 {
        margin-bottom: 0;
        font-size: 1.6rem;
        color: $dark;
      }
    }
    .checkout-methods{
      margin-bottom: 2rem;
    }
    .widget-body {
      .content {
        margin: 0;
        padding: 0.75rem 2rem;

        .table.table-totals {
          tfoot {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #vue_paiement {
    .shipping-address-box.bg-white.bloc-card {
      display: block;
    }
  }
}

.commandeLivraison .lignePanierLivraisonSelect:not(:last-of-type) {
  margin-bottom: 2rem;
}

@media screen and (max-width: 991px) {
  .lignePanierLivraison.row {
    padding-left: 1rem !important;
  }
}