.header {
  .header-contact {
    margin-left: 4em;
  }
  .menu-header-user {
    .user-popup {
      min-width: 280px;
      top: 35px;
      right: 0;
      z-index: 10;
      background: var(--white-color);
      box-shadow: 0 2px 4px 0 rgba(74, 144, 226, 0.1);
      border: 1px solid #e2e4e8;
      border-radius: 4px;
      padding: 10px 0;
      .logout {
        color: red;
      }
      .user-popup-info {
        padding: 5px 20px;
      }
      h4.separator {
        border-left: none;
        font-weight: 800;
        font-size: 10px;
        text-transform: uppercase;
        margin: 5px 20px;
        color: #bec4d8;
        border-bottom: 1px solid #bec4d8;
      }
      .profileLink {
        display: block;
        padding: 5px 20px;
      }
      .profileLink:hover {
        background: #f6f9fc;
        text-decoration: none;
      }
      .profileLink.myTerminal {
        display: flex;
        align-items: center;
      }
      .profileLink.myTerminal img {
        margin-left: -8px;
      }
    }
  }
  .header-icon.header-link {
    img {
      border-radius: 50%;
      width: 34px;
      height: 34px;
    }
  }
  .monCompteLink {
    text-transform: uppercase;
    margin: 0;
    font-size: 1.1rem;
    color: #777;
    margin-right: 2.2rem;
    span {
      display: block;
      font-size: 1.5rem;
      font-weight: 700;
      white-space: nowrap;
      color: black;
    }
  }

  .search-results {
    position: absolute;
    background: var(--white-color);
    z-index: 50;
    box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
    width: 100%;
    padding: 10px 5px;
    top: 45px;
    border-radius: 15px;

    .search-result-items-title {
      background: #ebebeb;
      color: var(--primary-color);
      font-size: 1.5rem;
      font-weight: 600;
      padding: 5px 10px;
      border-radius: 10px;
    }

    .search-result-item {
      padding: 5px;
      display: block;
      font-size: 1.5rem;
      text-transform: none;
      color: var(--primary-color);
      font-weight: 700;
      //&:nth-of-type(even) {
      //  background: #ebebeb;
      //}
      .findPdvBy {
        font-size: 1.2rem;
        color: #90949c;
        font-weight: 500;
      }
    }
    .result-item-img {
      width: 32px;
      margin-right: .8rem;
    }
  }
  .header-middle {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .header-logo {
    height: 100px;
    max-width: 100px;
    .logo {
      img {
        display: block;
        height: 100%;
      }
    }
  }
  .header-logo-small {
    .logo {
      padding: 0 !important;
      img {
        max-height: 60px;
        transform: none;
        transform-origin: unset;
      }
    }
  }
  .header-center {
    .container {
      display: none;
      input.search-input {
        width: 200px;
      }
      @include respond-to('medium') {
        display: block;
      }
    }
  }

  #mkp-shop-selected, .header-user-container {
    margin-right: 1.5rem;
  }
  .cart-dropdown-bloc .dropdown-menu {
    right: 0;

    &::before {
      content: none;
    }
  }

  .mmenu-active {
    .mobile-menu-container {
      transform: inherit;
    }
  }
  .header-middle {
    padding-top: 1em;
    padding-bottom: 1em;
    .header-left {

    }
    @include respond-to('medium') {
      padding-top: 2.5em;
      padding-bottom: 2.5em;
    }
  }

  .sticky-header {
    .logo {
      display: none;
      padding: 10px 0;
    }
  }
  .header-content{
    .header-search {
      .search-input {
        width: 30rem;
        min-width: 30rem;
        font-size: 1.6rem;
        color: $dark-grey;
      }
      .header-search-wrapper {
        .closeSearchHeaderDesktop {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 50px;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
    .image-logo img {
      max-height: 100px;
      max-width: 100px;
      @include respond-to('medium') {
        max-width: 150px;
      }
    }
    a.dropdown-basket::before {
      display: inline-block;
      content: '\E87F';
      font-family: 'porto';
      font-size: 3rem;
      line-height: 1;
      margin: 4px 0 0;
      color: var(--grey-darker-color);
    }
    .header-icon.header-link {
      cursor: pointer;
    }

    .fa.fa-user {
      font-size: 25px;
      margin-right: 15px;
      cursor: pointer;
      @include respond-to('medium') {
        font-size: 30px;
        margin-right: inherit;
      }
    }
  }
  .header-bottom {
    .main-nav {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
@include respond-to('small') {
  .header .monCompteLink span{
    display: none;
  }

}

@media (max-width: 575px){
  .header-icon.header-link.mr-4 {
    margin-right: 1.2rem !important;
  }
}
@include respond-to('small'){
  .header-content{
    .part-4-header{
      text-align: right;

      .header-user-container{
        margin-left: 0 !important;

        .header-icon{
          margin-right: 0 !important;
        }
        .monCompteLink {
          display: none;
        }
      }
      a.dropdown-basket:after {
        display: none;
      }
    }
    .image-logo{
      img{
        height: 95px;
      }
    }

    .mobile-menu-toggler{
      order: 1;
    }
    .header-search{
      order: 2;
      margin-left: 2.2rem;
      .header-search-wrapper.show{
        .close-search {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 55px;
        }
        .search-input {
          //min-width: auto;
          width: 100%;
        }
        left: -2.3rem;
        right: inherit;
        .search-input{
          width: 100%;
        }
      }
    }
  }
}
ul li div.megamenu ul li.actif{
  text-decoration: underline;
  a{
    color: var(--primary-color);
  }
}