#vue_login, #vue_updatePassword {
  .content {
    padding: 20px 0;
    background: #EFEFEF;
  }

  .img-enseigne {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    img {
      width: auto;
      max-width: 40%;
      height: auto;
      max-height: 150px;
    }
  }

  .bloc {
    &-connect, &-updatepassword {
      position: relative;
      width: 500px;
      margin: 0 auto;
      padding: 30px;
      background: white;
    }
    &-login {
      form {
        margin: 0;
      }
      .divider {
        position: relative;
        width: 100%;
        margin: 20px 0;
        text-align: center;
        color: #D1D1D1;
      }
      .divider.line::before, .divider.line::after {
        content: "";
        position: absolute;
        width: 40%;
        height: 1px;
        top: calc(50% - 1px);
        background: #D1D1D1;
      }
      .divider.line::before {
        left: 0;
      }
      .divider.line::after {
        right: 0;
      }
    }
    &-actions {
      position: absolute;
      top: 20px;

      .with-arrow {
        position: relative;
        padding: 15px 0 0 15px;
        font-size: 1.2rem;
        cursor: pointer;

        &:before {
          content: '\f177';
          position: absolute;
          top: -5px;
          left: 0;
          font-family: 'FontAwesome', sans-serif;
          font-size: 3rem;
        }
      }
    }
    &-title, &-txt {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
      i {
        margin-bottom: 20px;
        font-size: 6rem;
        color: $green;
      }
      h1 {
        margin: 0;
        color: $dark;
        font-family: $font-1;
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }
    }
    &-txt {
      p {
        margin: 0;
        color: $dark;
        text-align: center;
      }
      .txt-login {
        margin-top: 10px;
      }
      .txt-disclaimer {
        color: var(--primary-color);
        text-decoration: underline;
        span {
          cursor: pointer;
        }
      }
    }
    &-btn {
      margin-top: 30px;

      &-options {
        margin-top: 20px;
      }

      &-social {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .btn-social {
          display: flex;
          width: 50px;
          height: 50px;
          margin: 0 10px;
          flex-direction: column;
          justify-content: center;
          border-radius: 50px;
          text-align: center;
          font-size: 2.6rem;
          cursor: pointer;

          &:hover i {
            transform: none;
          }

          &#social-connect-google {
            background-color: #EFEFEF;
            svg {
              margin-left: 12px;
            }
          }
          &#social-connect-facebook {
            background-color: $col-fb;
            color: white;
          }
          &#social-connect-apple {
            background-color: $dark;
            color: white;
          }
        }
      }

      button {
        width: 100%;
      }
    }
    &-register {
      form {
        margin: 0;
      }
      .form-check-label {
        font-size: 1.4rem;
        margin-left: 5px;
      }
    }
    &-register-validation {
      text-align: center;

      i.fa-check-circle {
        margin-bottom: 20px;
        color: limegreen;
        font-size: 5rem;
      }
    }
    &-validation {
      margin-top: 20px;
    }
    &-resend-email {
      form {
        margin: 0;
      }
    }
  }

  .txt-success {
    text-align: center;
    color: limegreen;
    font-weight: 600;
  }

  .inline-checkbox {
    margin-bottom: 1rem;
  }

  .alert-error {
    display: flex;
    padding: 10px;
    font-size: 0;
    background-color: $red;
    margin-bottom: 20px;

    &-icon, p {
      vertical-align: top;
      color: white;
    }
    &-icon {
      display: inline-flex;
      width: 10%;
      align-items: center;
      font-size: 2.6rem;
    }
    p {
      display: inline-block;
      width: 90%;
      margin: 0;
      font-size: 1.4rem;
    }
  }
}

//-- Spécifique page paiement --//
#vue_paiement {
  #vue_login {
    .content {
      background: white;
    }
  }
}
//-- Spécifique page Achat Titres Cadeaux --//
#section-offre-cadeaux, #vue-achat-courriers {
  #vue_login {
    .content {
      background: white;
    }
  }
}
//-- RESPONSIVE --//
@media (max-width: 576px) {
  #vue_login, #vue_updatePassword {
    .bloc {
      &-connect, &-updatepassword {
        width: 100%;
        padding: 30px 20px;
      }
      &-title {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}
