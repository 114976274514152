// Ajoute cette classe au body pour enlever le scroll temporairement
body.stop-scroll {
  height: 100vh;
  overflow: hidden;
}
main {
  //background-color: $light;
}
.home {
  padding-bottom: 30px;

  @include respond-to('small') {
    .home-slider .home-slide .slide-bg {
      min-height: auto;
    }
  }
  .background-dark {
    background-color: $dark;

    h2 {
      color: white;
    }
  }
  .bloc-video {
    video {
      display: block;
      width: 850px;
      height: auto;
      margin: 0 auto;
    }
  }
  .feature-boxes-container {
    .feature-box {
      display: flex;
      height: 100%;
      margin: 0;
      flex-direction: column;
      align-items: center;

      &-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        &__btn {
          margin-top: 2rem;
        }
      }
    }
  }
  .container {
    &.info-groupe {
      .row {
        .feature-box{
          .fa{
            border: 1px solid var(--primary-color);
            color: var(--grey-lighter-color);
          }
        }
      }
    }
  }
  .product-default.marques figure{
    background: none;
    min-height: 100px;
    height: auto;
    width: 80px;
  }
  .containerLastPdv{
    .tab-products-carousel .product-default figure{
      background: none;
    }
    .pdv{
      .imgPdv{
        height: 200px;
        img{
          max-width: 100%;
          max-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .info-boxes-container{
    .row{
      @include respond-to('medium'){
        flex-direction: row;
        justify-content: center;
      }
      @include respond-to('small'){
        width: 100%;
        max-width: none;
      }
    }
    .info-box-bloc {
      display: flex;
      justify-content: center;
      flex-direction: row;
      > div {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .info-box{
      display: flex;
      align-items: center;
      padding: 1rem 0;
      width: auto; // 100%;
      color: var(--grey-lighter-color);
      @include respond-to('medium'){
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        justify-content: center;
      }
      i{
        color: var(--grey-darker-color);
        font-size: 3.7rem;
        margin-right: 1.8rem;
        min-width: 3.7rem;
        text-align: center;
      }
      h4{
        color: var(--grey-darker-color);
        font-weight: 600;
        letter-spacing: .025em;
      }
      p{
        font-size: 1.3rem;
        line-height: 1.6;
        letter-spacing: .01em;
      }
    }
  }
  .owl-carousel.marques-carousel .owl-nav.disabled, .owl-carousel.marques-carousel .owl-dots.disabled{
    display: block;
  }
  .home-product-tabs .tab-content {
    padding-bottom: 4rem;
    border-top: .2rem solid var(--white-color);
    border-bottom: 1px solid var(--white-color);
  }
  .product-default{
    .btn-quickview, .btn-icon-wish {
      line-height: 36px;
    }
  }
  // Spécial Neuville
  .promo-banner.neuville {
    a {
      margin-left: 2rem;
    }
  }
  .promo-section.fidelite-section {
    position: relative;
    &:before {
      content: '';
      display: block;
      background-color: rgba(32, 32, 32, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    h1, h2, h3, h4, h5, h6, p{
      color: var(--white-color);
    }
    h3{
      font-weight: 700;
      font-size: 5.5rem;
      line-height: 1;
      letter-spacing: .01rem;
      margin-bottom: 1.2rem;
      text-transform: uppercase;
    }
    h4{
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: .08rem;
      margin-bottom: 2.2rem;
    }
  }
}
.homeMyPresquIle {
  .first {
    .container{
      height:120px;
      background-color: rgb(41, 51, 138);
      justify-content: center;
      h2{
        color: rgb(237, 107, 106);
        margin-bottom: 0;
      }
    }
  }
  .second{
    .container{
      padding: 50px;
      .text-wrapper{
        line-height: 150%;
        .buttonCarte{
          background-color: #29338a;
          color: white;
          font-weight: bold;
          padding: 5px 10px;
          border-radius: 25px;
        }
      }
      .imageAccueil{
        .photo-accueil{
          border-radius: 20px;
          width: auto;
        }
      }
    }
  }
  .third{
    background-color: #f7f7f7;
    .container{
      background-color: #fff;
      width: 28em;
      border-radius: 20px;
      flex-direction: column;
      padding: 20px;
      margin: auto;
      .image-carte-cadeaux-demat{
        margin-left: auto;
        margin-right: auto;
        transform: rotateZ(-12deg);
        top: -60px;
        position: relative;
        max-width: 105px;
      }
      .feuille{
        max-width: 50px;
      }
      .top-achat{
        h5{
          text-align: center;
        }
        .text-no-limit{
          background-color: #29338a;
          color: white;
          padding: 5px 10px;
          font-weight: bold;
          margin-right: auto;
          margin-left: auto;
          width: fit-content;
        }
        .paragraphe-carte-cadeaux{
          text-align: center;
        }
      }
      .buttonCarte{
        background-color: #29338a;
        color: white;
        padding: 5px 10px;
        border-radius: 25px;
        width: fit-content;
        font-weight: bold;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

.marketplace-home {
  .tab-products-carousel {
    .product-default figure{
      background: none !important;
    }
  }
}

// Coeur du Var
#carte-cadeau {

  .container-fluid .section-title h1 {
    font-size: 3rem;
  }

  .container-fluid .carte-cadeau-argument-list ul li {
    font-size: 15px;
  }

  #avantages-details {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h2 {
      text-transform: uppercase;
    }

    ul li {
      font-size: 15px;
    }

    .particulier-icon, .entreprise-icon {
      max-width: 12rem;
    }

    img {
      width: 9rem;
    }
  }
}

.home_coeur-du-var .containerLastPdv .sliderCommerces .slick-slider {
  .slick-disabled {
    display: none !important;
  }

  .slick-next::before {
    color: #96368b;
    opacity: 1;
    padding-left: 1rem;
  }

  .slick-prev::before {
    color: #96368b;
    opacity: 1;
    padding-right: 2rem;
  }

  .slick-next {
    -webkit-animation: bounce-right 2s infinite;
    animation: bounce-right 2s infinite;
    top: 11.5rem;
  }

  .slick-prev {
    -webkit-animation: bounce-left 2s infinite;
    animation: bounce-left 2s infinite;
    top: 11.5rem;
  }

  @keyframes bounce-right {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(-15px);
      transform: translateX(-15px);
    }
    60% {
      -ms-transform: translateX(-5px);
      transform: translateX(-5px);
    }
  }
  @keyframes bounce-left {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(15px);
      transform: translateX(15px);
    }
    60% {
      -ms-transform: translateX(5px);
      transform: translateX(5px);
    }
  }
}

// Fin coeur du Var
@media (max-width: 992px) {
  .home {
    .info-boxes-container {
      .info-box-bloc {
        display: block;
        font-size: 0;
        > div {
          margin: 0 auto;
          &:last-child {
            width: 100%;
          }
        }
      }
      .info-box {
        display: inline-flex;
        width: 50%;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 576px) {
  .home {
    .info-boxes-container {
      .info-box {
        width: 100%;
        justify-content: left;
        padding: 1.6rem 0 1.6rem 20px;
      }
    }
    // Spécial Neuville
    .promo-banner.neuville {
      .banner-txt {
        background-color: rgba(255,255,255,0.5);
        padding: 20px 0;
      }
      a {
        margin-top: 3rem;
        margin-left: 0;
      }
    }
  }
}
