$vs-dropdown-z-index: 1041;

@import "vue-select/src/scss/vue-select.scss";

.vselect-h-42 input {
  height: 42px;
}
.vselect-h-50 .vs__search {
  height: 50px;
}
.style-chooser input {
  height: 28px;
}
.style-chooser .vs__dropdown-toggle {
  border-radius: 0;
  background: white;
}
//-- Avec le style de input-bloc --//
.component-input-bloc {
  .input-bloc {
    &.input-plain {
      .v-select {
        & + label {
          top: -8px;
          font-size: 1.2rem;
          font-weight: 700;
          color: var(--primary-color);
          z-index: 0;
        }
        &.vs--open input.vs__search, &.vs--single, .vs__search {
          height: 42px;
        }
        .vs__search:focus {
          padding: 0 15px;
        }
        .vs__actions {
          height: 42px;
          right: 10px;
          bottom: 0;
          top: initial;
        }
        .vs__selected {
          position: absolute;
          padding: 13px 0 0 0;
          top: -2px;
          left: 15px;
          z-index: 5;

          &-options {
            align-items: flex-end;
          }
        }
      }
      .vs--open, .vs--searching {
        & + label {
          opacity: 1;
        }
      }
    }
    .v-select {
      margin-top: auto;
      font-size: 1.4rem;
      z-index: initial;

      &.vs--single {
        height: 50px;
      }
      &.vs--multiple {
        input {
          height: 50px;
        }
        &.vs--open {
          input.vs__search {
            height: 30px;
            margin-top: 20px !important;
          }
        }
        .vs__selected {
          margin: 20px 10px 0 0;
          padding: 5px 10px;
          font-size: 1.4rem;
          line-height: 1em;

          .vs__deselect {
            margin-left: 10px;

            svg {
              fill: $red;
            }
          }
        }
      }
      &.vs--open, &.vs--loading {
        input.vs__search {
          height: 30px;
          margin-top: auto !important;
        }
      }
      &.no-padding {
        .vs__selected {
          padding: 0;
        }
      }
      .vs {
        &__search {
          height: 30px;
          margin: 0;
          margin-top: auto !important;
          z-index: 0;

          &::placeholder {
            color: $grey;
            font-size: 1.4rem
          }
          &:focus {
            margin: 0;
            padding: 15px 0 0 0;
            font-size: 1.4rem;
          }
        }
        &__selected {
          margin: 0;
          padding: 20px 0 0 0;
          font-size: 1.4rem;
        }
        &__dropdown-toggle {
          height: 50px;
          padding: 0;
          border-radius: 0;
          border: none;
        }
        &__dropdown-menu {
          z-index: 10;
        }
        &__selected-options {
          padding: 0;
        }
        &__actions {
          position: absolute;
          height: 50px;
          padding: 0;
          top: 0;
          right: 0;
          z-index: 5;
        }
        &__clear {
          fill: #990000;
        }
        &__open-indicator {
          fill: $grey;
        }
      }
      .vs__dropdown-toggle {
        height: 100%;
        background-color: initial;
      }
      label {
        z-index: 0;
      }
      input {
        &:not(:placeholder-shown) {
          height: 5px;
          margin-top: 0 !important;
        }
      }
    }
  }
}