.adresse-data-container {
  position: relative;

  .adresse-data-select {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    border: 1px solid $grey;
    background-color: white;
    z-index: 15;

    &.with-margin {
      top: calc(100% - 2rem);
    }
  }
  .select {
    &-message, &-result {
      padding: 10px 15px;
    }
    &-result {
      text-align: left;
      cursor: pointer;

      &-label {
        font-weight: 700;
      }
      &-context {
        font-size: 1.2rem;
      }
    }
  }
}
