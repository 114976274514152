ul.tabs__header {
  display: block;
  list-style: none;
  padding: 0;

  li {
    padding: 15px 30px;
    display: inline-block;
    cursor: pointer;

    &.tab__selected {
      font-weight: bold;
      border-bottom: 3px solid var(--primary-color);
    }
  }
}
.tabs {
  .tab {
    background-color: #fff;
  }
  li {
    background-color: #fff;
    color: #888;
    font-weight: bold;
    font-size: 1.6rem;

    &.tab__selected {
      color: var(--primary-color);
      font-size: 1.6rem;
    }
  }
}
