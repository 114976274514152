
.separation {
  height: 200px;
  width: 5px;
  margin-top: 0;
  margin-bottom: 0;
  background: var(--white-color);
}

.textBonPlan {
  font-size: 10px;
}

.white-space-pre-line {
  white-space: pre-line;
}

body.modal-open {
  overflow: hidden;
  height: 100vh;

  .sticky-header.fixed {
    z-index: 0;
  }

  .sidebar-wrapper {
    z-index: 0;
    position: inherit !important;
  }
}

.history-row [class*='col-'] {
  padding: 0;
}

body.modal-open .modal {
  overflow-y: hidden;
}

.fa-pen {
  cursor: pointer;
}

.el-input__prefix, .el-input__suffix {
  display: none;
}

.el-input__inner {
  border: 1px solid #dfdfdf;
}

body.bodycg {
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.4rem !important;
}

.sidebar-shop .pin-wrapper {
  height: auto !important;
}

.owl-carousel:not(.product-single-carousel) .owl-nav button.owl-prev {
  left: -4rem;
}

.owl-carousel:not(.product-single-carousel) .owl-nav button.owl-next, .owl-carousel:not(.home-slider) .owl-nav button.owl-next {
  right: -4rem;
}

.owl-carousel.home-slider .owl-nav button.owl-prev {
  left: 1vw;
}

.owl-carousel.home-slider .owl-nav button.owl-next {
  right: 1vw;
}

body .card {
  box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
  border-radius: 4px;
  border: none;
}

body .card .card-header {
  border: none;
  background: none;
}

body .card .card-header {
  border-bottom: 1px solid #dddfe4 !important;

  h4 {
    margin-bottom: 0;
  }
}

.btn-icon {
  width: 32px;
  height: 32px;
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.actu {

  margin-top: 30px;
  margin-bottom: 20px;
}

.card-body {

  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.bons-plans {

  margin-top: 30px;
  margin-bottom: 30px;
}

.btn-social-login,
.main .form-control::placeholder,
label,
.page-header h1,
.page-title,
.pagination,
.newsletter-popup-content .btn,
.product-default .btn-add-cart,
.newsletter-popup-content .btn,
.product-single-qty .bootstrap-touchspin .form-control,
.inner-quickview figure .btn-quickview,
.hidden-description .btn-quickview,
.hidden-description .btn-add-cart,
.nav-tabs .nav-item .nav-link,
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
.font2,
.top-notice,
.sidebar-shop .widget-title,
.add-wishlist,
.product-single-details .price-box,
main .sticky-header .price-box,
.post-media .post-date,
.home-product-tabs .product-title {
  font-family: $font-2;//"Open Sans", sans-serif;
}

.menu > li > a:hover {
  text-decoration: none;
}

.h5-text-pro {
  text-transform: uppercase;
  font-size: 16px;
}

.feature-box-Fid {
  color: var(--white-color);
  margin-top: 20px;

}

footer .footer-payments {
  max-width: 250px;
}

.feature-box-Ch {
  color: var(--white-color);
  margin-top: 20px;

}

.actu-tabs {
  margin-bottom: 3.5rem;
}

.product-title-boutique {
  font-size: 2.1rem;
  text-transform: uppercase;
  text-align: center;
}

.boutonMarketplace {
  text-align: center;
}

.home-product-tabs .owl-theme .owl-nav .owl-prev {
  left: -4rem;
}

.home-product-tabs .owl-theme .owl-nav .owl-next {
  right: -4rem;
}

.shipping-address-box.active {
  border-color: var(--primary-color);
}

.shipping-address-box.active:after {
  background: var(--primary-color);
}

.proposition {
  margin-top: 40px;
  margin-bottom: 50px;

}

.proposition-tabs {
  margin-bottom: 3.5rem;
}

.home-product-tabs .owl-theme .owl-nav .owl-prev {
  left: -4rem;
}

.home-product-tabs .owl-theme .owl-nav .owl-next {
  right: -4rem;
}

.nav-produitsPro {
  margin-bottom: 30px;
  margin-left: 20px;
}

.boutonProduits {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.boutonBoutiques {
  text-align: center;
}

.boutonBonPlan {

  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.btn-see-all {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 12px 40px;
  display: block;
  width: 174px;
  margin: 70px auto 30px auto;

  &:after {
    content: '\25ba';
    padding-left: 35px;
  }
}

.checkout-progress-bar {
  li.active, li.current {
    &:before, & > span:before {
      background-color: var(--primary-color);
    }
    span {
      color: $dark;
    }
  }
}

.main-nav .menu > li:hover, .main-nav .menu > li.active {
  border-top-color: var(--primary-color);
}

.main-nav .menu > li:hover > a, .main-nav .menu > li.show > a, .main-nav .menu > li.active > a {
  color: var(--primary-color);
}

.register-validation, .validation-inscription, .password-forgotten, .validation-password, .update-password, .resend-email, .register-pdv, .supprimer-compte, .validation-commande {
  padding: 20px;

  i.fa-check-circle, i.fa-envelope {
    text-align: center;
    margin: 0 auto 20px;
    display: block;
    font-size: 60px;
    color: var(--primary-color);
  }

  i.fa-check-circle {
    color: $green;
  }

  h2.title, h1 {
    font-size: 2.5rem;
    text-align: center;
    color: $dark;
    font-weight: 700;
  }
}

.register-logo {
  width: 130px;
  margin: 0 auto;
}

.input-valid {
  border-color: $green;
}

.input-error {
  border-color: $red;
}

.input-error {
  .vs__dropdown-toggle {
    border-color: $red;
  }
}

.error-message {
  color: $red;
}

.accordion.bg-white {
  .accordion-action {
    position: relative;
    padding: 1.2rem 1.5rem;
    background: var(--white-color);

    span.fa-play {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &[aria-expanded="true"] {
      background: var(--primary-color);
      color: var(--white-color);

      .icon {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  .accordion-body {
    padding: 1.2rem 1.5rem;
  }
}

.socials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
/* --- BOUTONS RÉSEAUX SOCIAUX --- */
a.btn-social,
.btn-social {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--white-color);
  transition: background-color .3s;

  &.btn-xs {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
  &.btn-sm {
    width: 36px;
    height: 36px;
    font-size: 18px;
  }
  &.btn-lg {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}
.btn-social .fa, .btn-social i {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: scale(1);
  transition: all .25s;
}
.btn-social:hover, .btn-social:focus {
  color: var(--white-color);
  outline: none;
  text-decoration: none;
}
.btn-social:hover .fa, .btn-social:focus .fa, .btn-social:hover i, .btn-social:focus i {
  transform: scale(1.3);
}
.btn-facebook,
.btn-instagram,
.btn-pinterest,
.btn-twitter,
.btn-youtube,
.btn-linkedin,
.btn-snapchat,
.btn-tiktok,
.btn-whatsapp,
.btn-twitch,
.btn-messenger,
.btn-telegram {
  cursor: pointer;
  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: brightness(1.2);
  }
}
.btn-web {
  background-color: var(--primary-color);
}
.btn-facebook {
  background-color: #1778f2;
}
.btn-instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}
.btn-twitter-x {
  background-color: #0d0d0d;
}
.btn-linkedin {
  background-color: #0e76a8;
}
.btn-pinterest {
  background-color: #cc2127;
}
.btn-youtube {
  background-color: #c4302b;
}
.btn-tripadvisor {
  background-color: #0fd28a;
}
.btn-lafourchette {
  background-color: #00665c;
}
.btn-tiktok {
  background-color: #000000;
}
.btn-snapchat {
  background-color: #fffc00;
}
.btn-whatsapp {
  background-color: #25d366;
}
.btn-twitch {
  background-color: #6441a5;
}
.btn-messenger {
  background: linear-gradient(45deg, #1778f2 0%, #d6249f 100%);
}
.btn-telegram {
  background-color: #0088cc;
}
.btn-google {
  border: 1px solid $dark;
}
.btn-michelin {
  background-color: #D3072B;
}
.btn-gault-millau {
  border: 1px solid #D72233;
}
.btn-doctolib {
  background-color: #117ACB;
}

/* --- BOUTONS GÉNÉRIQUES --- */
.btn-custom {
  // En attendant un nettoyage complet des styles
  .btn-icon-circle {
    background-color: var(--primary-color);
  }
}
.btn-icon-circle {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: white;
  border: none;

  &.btn-snapchat {
    color: black;
  }
  img {
    max-width: 20px;
    max-height: 20px;
  }
}

// --- MODAL --- //
@media (min-width: 997px) {
  .modal-dialog {
    &.modal-xl {
      max-width: 1100px;
    }
  }
}
@media (max-width: 564px) {
  .modal-dialog {
    max-width: 100vw;
    margin: 0;
  }
}

.subtitle {
  font: 700 1.8rem/1 "Open Sans", sans-serif;
}

.menu li .menu-title a {
  font-family: "Open Sans", sans-serif;
}

.footer .widget-title {
  font: 400 1.6rem/1.1 'Open Sans', sans-serif;
}

.contact-info-label,
.product-default .btn-add-cart,
.inner-quickview figure .btn-quickview,
.hidden-description .btn-quickview,
.hidden-description .btn-add-cart,
.banner .banner-subtitle span,
.page-title,
.btn,
.btn-social-login,
.nav.nav-tabs .nav-item .nav-link,
.newsletter-popup-content .btn,
.home-slide-content h1,
.home-slide-content h2,
.home-slide-content h3,
.home-slide-content h4,
.promo-section h3,
.promo-content .promo-title,
.history-header h5,
.add-cart {
  font-family: "Open Sans", sans-serif;
}

.hidden {
  display: none;
}

.hidden-desktop {
  display: none !important;
  @include respond-to('small') {
    display: block !important;
  }
}

@include respond-to('small') {
  .header-icon.header-link.mr-0 {
    margin-right: 2.2rem !important;
  }
  iframe.iframe-video {
    max-width: 100%;
    height: auto;
  }
}

.hidden-mobil {
  display: initial !important;
  @include respond-to('small') {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .header-bottom.sticky-header {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .header-search .form-control {
    width: 100%;
  }
}

.bloc-contact-commerces {
  .btn {
    padding: 0.5rem 1rem;
  }

  .btn-outline-secondary:not(.disabled):hover, .btn-outline-secondary:not(.disabled).focus {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--white-color);
  }
}

.product-reduc {
  background-color: #62b959;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bolder;
  color: var(--white-color);
  position: absolute;
  top: 0.8rem;
  left: 1.6rem;
  padding: 7px 10px 7px 5px;
}

.modal {
  background: rgba(0, 0, 0, 0.7);
}

.vs__dropdown-toggle .vs__search {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
}

.modalAjoutArticle {
  overflow: hidden !important;
}

.product-default.bg-white figure {
  background: none;
}

.product-default {
  &:hover {
    figure {
      box-shadow: none !important;
    }
  }

  figure {
    width: 250px;
    height: 250px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    img {
      transform: translate(-50%, -50%);
      top: 50% !important;
      left: 50% !important;
      position: absolute !important;
      max-height: 100%;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  .price-box {
    margin-top: 10px;
  }

  .product-details {
    .category-list {
      height: 20px;
    }

    .product-description {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    h2.product-title {
      a {
        color: var(--primary-color);
      }
    }

    .product-action {
      .btn-see-product {
        background-color: var(--primary-color);
        border: none;
        color: var(--white-color);
        font-size: 11px;
        font-weight: bold;
        padding: 10px 27px;
        border-radius: 50px;
      }
    }
  }
}

.logoInModal {
  max-width: 80px;
}

.bg-color {
  background: var(--primary-color);
  color: white;

  .card-header {
    color: var(--white-color);
  }
}

.bg-white {
  background: var(--white-color);
  color: $dark;
}

.modal.fade.show {
  z-index: 1041;
}

.modal-backdrop.show {
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
}

input.form-control, textarea {
  padding: 0.5rem 1rem;
  color: var(--grey-darker-color);

}

input.form-control::placeholder, textarea::placeholder {
  color: var(--grey-ligther-color);
}

input.form-control {
  height: 42px;
}

.btn {
  padding: 0.5rem 1rem;
}

.modal-footer {
  .btn {
    padding: 1rem 1.5rem;
  }
}

.btn-primary.disabled, .btn-primary:disabled {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

@include respond-to('small') {
  body.modal-open .modal {
    overflow-y: scroll;
  }
}

.popupFb {
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  top: 0;
  left: 0;
  color: var(--black-color);
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;

  .bloc {
    max-width: 796px;
    margin: auto;
    padding: 40px;
    background: var(--white-color);
    position: relative;
  }
}

.opened-state {
  color: #448523;
}

.closed-state {
  color: #ff7889;
}

.card-header .card-edit {
  color: var(--primary-color);
}

.modal-content {
  h5 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  }
}

.header-bottom.sticky-header.fixed {
  margin-right: 0 !important;
}

.vgt-compact td:before {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.cart-dropdown-bloc .product-details {
  width: 70% !important;
}

.cart-dropdown-bloc .product-image-container {
  width: 62px !important;
  height: 62px;
}

// Permet de faire des faux header dans les tables
.fake-head {
  color: #606266 !important;
  vertical-align: bottom !important;
  border-bottom: 1px solid #dcdfe6 !important;
  font-weight: bold;
  background: linear-gradient(#f4f5f8, #f1f3f6) !important;
}

table.vgt-table {
  font-size: 1em !important;
}

.commandesSection {
  .commandesTable.vgt-table {
    thead {
      th {
        color: #fff;
        background: #212529;
        border-color: #32383e;
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        padding: .75rem;
        border-top: 1px solid #dee2e6;
        font-size: 1.4rem;

        &:before {
          border-top: 5px solid #fff;
        }

        &:after {
          border-bottom: 5px solid #fff;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: .75rem;
          font-size: 1.4rem;
          vertical-align: top;
          border-top: 1px solid #dee2e6;
        }

        &:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, .05);
        }
      }
    }
  }

  .vgt-wrap__footer.vgt-clearfix {
    padding: 0.75rem;
    background: #212529;
    color: #fff;

    .footer__row-count__label,
    .footer__row-count__select,
    .footer__navigation__page-btn,
    .footer__navigation__page-info {
      font-size: 1.4rem;
      color: #fff;
    }

    .footer__navigation__page-btn .chevron.right:after,
    .footer__navigation__page-btn.disabled .chevron.right:after,
    .footer__navigation__page-btn.disabled:hover .chevron.right:after,
    .footer__navigation__page-btn:hover .chevron.right:after {

    }

    .footer__navigation__page-btn .chevron.right::after {
      border-left: 6px solid #fff;
    }

    .footer__navigation__page-btn .chevron.left::after {
      border-right: 6px solid #409eff;
    }

    .footer__navigation__page-btn.disabled .chevron.left:after,
    .footer__navigation__page-btn.disabled:hover .chevron.left:after {
      border-right-color: #fff;
    }

    .footer__navigation__page-btn.disabled .chevron.right:after,
    .footer__navigation__page-btn.disabled:hover .chevron.right:after {
      border-left-color: #606266;
    }
  }
}

.bg-gray {
  background: #F0F2F5;
}

@include respond-to('small') {
  .commandesSection .commandesTable.vgt-table thead th {
    padding: .75em 1.5em .75em .75em;
  }
}

.cat-list li {
  margin-bottom: 0;
}

.pac-container {
  z-index: 1050 !important;
}

.vue-slider-rail {
  background-color: #F0F2F5 !important;
}

.vue-slider-process {
  background-color: var(--primary-color) !important;
}

.vue-slider-dot-handle {
  border: 2px solid var(--primary-color) !important;
}

.commandeLivraison .lignePanierLivraisonSelect:not(:last-of-type) {
  margin-bottom: 2rem;
}

@media screen and (max-width: 991px) {
  .lignePanierLivraison.row {
    padding-left: 1rem !important;
  }
}

.blocAddActu .vs__search {
  height: 42px;
}

.min-w-13 {
  min-width: 13rem;
}

.min-w-14 {
  min-width: 14rem;
}

.table.table-cart.dense tr td {
  padding: 0.8rem 1.5rem;
}

.vs__dropdown-menu {
  z-index: 1050 !important;
}

// Spécifique tableau don (obligé d'utiliser des !important)
.expanded {
  .actions-array {
    i {
      transform: rotate(180deg)
    }
  }
}
.expanded-area-don {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #dcdfe6;

  &-in {
    position: absolute !important;
    display: flex;
    width: calc(100% - 20px);
    top: 10px;
    left: 10px;
    padding: 10px !important;
    border: none !important;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    background: white;
  }
  .data-single {
    display: flex;
    width: calc(50% - 10px);

    &__label {
      font-weight: 700;
    }
  }
}
@media(max-width: 576px) {
  .expanded-area-don {
    &-in {
      display: flex !important;
      width: calc(100% - 20px) !important;
      column-gap: 0;
    }
    .data-single {
      width: 100%;
    }
  }
}
