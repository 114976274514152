.bordered {
  border: 1px solid var(--primary-color);
}
.product-container {
  .owl-carousel.product-single-carousel {
    .owl-nav button.owl-prev, .owl-nav button.owl-next {
      color: var(--white-color);
    }
  }
  .prod-full-screen i {
    color: var(--white-color);
  }
  .product-slider-container {
    img {
      height: 380px;
      object-fit: contain;
    }
  }
}
.product-default {
  .product-info {
    font-size: 1.1rem;
    text-transform: initial;
  }
  .product-details {
    padding: 0 15px;
  }
  .btn-add-cart {
    background-color: var(--primary-color);
    color: white;
  }
}
.product-details {
  text-align: left;
  width: 250px;
  max-width: 100%;
  margin: 0 auto;

  .category-list, .product-title, .product-description, .product-seller, .price-box {
    width: 100%;
  }
  .product-title {
    a {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
  .product-seller {
    font-size: 1.2rem;
  }
  .price-box {
    text-align: right;

    .product-price {
      color: red;
    }
  }
}
.cart-dropdown-bloc {
  .product-details {
    .product-title {
      a {
        font-weight: inherit;
        font-size: inherit;
      }
    }
  }
}
.sliderProducts .tab-products-carousel img{
  object-fit: contain;
}

.breadcrumb-product {
  padding: 0 20px;
}
.product-single-container {
  padding: 20px;

  .owl-carousel {
    padding: 0;
  }
  h5 {
    font-weight: 400;
  }
}
.product-single-details {
  margin: 0;

  .product-title {
    margin-bottom: 10px;
    padding-top: 10px;
  }
}

.product-tabs-container {
  padding: 10px 20px 20px 20px;
}

.marque-bloc {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  gap: 10px;

  img {
    width: 35px;
  }
  p {
    line-height: 1em;
  }
}

.non-achetable {
  p {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary-color);
  }
}
.product-single-share {
  .jssocials-share-link {
    font-size: 1.8rem;

    .facebook {
      color: #1778f2;
    }
    .twitter-x {
      color: #0d0d0d;
    }
    .linkedin {
      color: #0e76a8;
    }
    .pinterest {
      color: #cc2127;
    }
  }
}
// Sticky-Header
.sticky-header.fixed {
  #product-socials, .divider {
    display: none;
  }
  .container {
    justify-content: space-between;
  }
  .container-details {
    display: flex;
    align-items: flex-start;
    width: 35%;
  }
  .sticky-detail {
    width: calc(100% - 80px);
  }
  .sticky-product-name {
    width: 100%;
  }
  .product-title {
    width: 100%;
    margin: 0;
    padding-top: 0;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  #detail-article-declinaisons {
    .container {
      gap: 20px;
    }
  }
  .product-filters-container {
    .product-single-filter {
      margin-bottom: 0;

      li:not(.active) {
        display: none;
      }
    }
  }
  .non-achetable {
    p {
      margin: 0;
    }
  }
}

#vue_produits_relatifs {
  padding: 0 20px;

  .product-default {
    figure {
      background: none;
    }
  }
  .home-product-tabs {
    .tab-content {
      padding: 0 10px;
    }
  }
}
@media (max-width: 768px) {
  #vue_produits_relatifs {
    .home-product-tabs {
      .tab-content {
        padding: 0;
      }
    }
    .product-default {
      .product-details {
        padding: 0 5px;
      }
    }
  }
}
