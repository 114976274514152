.search-section {

  height: 600px;
  padding-top: 7rem;
  padding-bottom: 7.5rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;

  .search-bloc {
    //display: grid;
    margin: auto;
    background-color: var(--white-color);
    height: 70px;
    max-width: 800px;
    //grid-template-columns: repeat(10, 1fr);
    //grid-template-rows: repeat(2, 1fr);

    input, select.select-research {
      height: 70px;
      max-width: inherit;
      justify-self: stretch;
      align-self: stretch;
    }

    .select-research {
      //grid-column: 1 / 5;
    }

    .text-research {
      //grid-column: 5 / 11;
    }
    .form-control{
      padding: 1.25rem 1.5rem;
      font-size: 1.3rem;
    }

    .btn {
      height: 60px;
      //grid-column: 1 / 11;
      background-color: var(--primary-color);
      color: var(--white-color);
      padding: 1rem 2rem;
    }


    @include respond-to('medium') {
      .select-research {
        //grid-column: 1 / 3;
      }

      .text-research {
        //grid-column: 3 / 10;
      }
      .btn {
        //grid-column: 10 / 10;
        display: block;
        margin: 5px;
      }
    }
  }

  .container {
    max-width: inherit;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }


  h1, h2, h3, h4 {
    color: var(--white-color);
    text-transform: uppercase;
  }

  h1 {
    font-size: 1.9em;
  }

  h2 {
    font-weight: 100;
    font-size: 1.6em;
  }

  @media (min-width: 645px) {
    h1 {
      font-size: 3.5em;
    }
    h2 {
      font-size: 2em;
    }
  }


  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(32, 32, 32, 0.75);
  }
}
.search-section.search-section-not-home{
  height: 100px;
  padding: 0;
  h2, h1{
    display: none;
  }
  .search-bloc{
    height: 50px;
    input, select.select-research{
      height: 50px;
    }
    .btn{
      height: 40px;
    }
  }
}
