#mentions-bloc {
  padding: 20px;

  .update-date, .application-date, h2 {
    font-weight: 700;
  }
  .update-date {
    margin-bottom: 30px;
    color: $grey;
  }
  .application-date {
    margin-bottom: 30px;
    color: $green;
  }
  .accordion-header {
    .nav-link {
      position: relative;

      &.collapsed {
        &:after {
          transform: rotate(180deg)
        }
      }
      &:after {
        content: '\f077';
        position: absolute;
        right: 15px;
        font-family: $fa;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .nav {
    border-bottom: 1px solid $grey;
  }
  .nav-link {
    padding: 10px 15px;
    border: none;
    font-weight: 700;
    text-align: left;
    background: transparent;
    color: $dark;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: white;
      background: var(--primary-color);
    }
    &:not(.sub-item) {
      border-top: 1px solid $grey;
    }
    &.active {
      color: white;
      background: var(--primary-color);
    }
    &.sub-item {
      position: relative;
      margin-left: 30px;

      &:before {
        content: '\e3fe';
        position: absolute;
        left: -15px;
        top: 7px;
        color: $dark;
        font-family: $fa;
        transform: rotate(180deg);
      }
    }
  }
  .nav-link-head {
    padding: 10px 15px;
    font-weight: 700;
    text-align: left;
    color: $dark;
    border-top: 1px solid $grey;
  }
  .tab-pane {
    h2:first-child {
      margin: 0 0 15px 0;
    }
  }
  h1, h2, h3, h4 {
    font-family: $font-1;
  }
  h1 {
    margin-bottom: 5px;
    font-size: 3rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  h2 {
    margin: 30px 0 15px 0;
    font-size: 1.8rem;
  }
  h3 {
    margin: 15px 0 5px 0;
    font-size: 1.4rem;
  }
  p, span, li {
    color: $dark;
  }
  ul:not(.nav-tabs) {
    padding-left: 20px;
    list-style: disc;
  }
}
// Est-ce utilisé ?
.conditiongenerales{
    .row.propostion.nav-pro{
      .nav-tabs{
          li.nav-item{
              width: 100%;
            &:last-child{
              margin-right: 3.4rem;
            }
          }
      }
    }
}