#scroll-top {
  z-index: 995;
}
.sponsor-img{
  display: none;
}
.footer-bottom{
  border-top: 1px solid var(--grey-darker-color);
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.footer-ribbon{
  position: absolute;
  padding: 1rem;
  left: 1.5rem;
  top: -7.7rem;
  color: var(--white-color);
  font-size: 2.1rem;
  min-width: 13rem;
  //font-family: 'Shadows Into Light', cursive;
  line-height: 1;
  letter-spacing: .01em;
  background-color: var(--primary-color);
  text-align: center;
  z-index: 10;
}
.widget-newsletter{
  border: none;
}
// Coeur du Var
.footer-coeur-du-var {
  background: rgb(230,50,53);
  background: linear-gradient(90deg, rgba(230,50,53,1) 20%, rgba(150,54,139,1) 100%);
  .container .footer-bottom {
    color: white;
    border-top: 1px solid white;
  }
  .footer-middle {
    color: white;
  }
}
// Fin Coeur du Var
@media screen and (max-width: 544px){
  .footer-bottom{
    flex-direction: column;
  }
}