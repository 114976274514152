//-- VAR ROOT --//
:root {
  --primary-color-dark: color-mix(in lab, var(--primary-color), #000 20%);
  --primary-color-darker: color-mix(in lab, var(--primary-color), #000 40%);
  --primary-color-darkest: color-mix(in lab, var(--primary-color), #000 60%);
  --primary-color-light: color-mix(in lab, var(--primary-color), #FFF 20%);
  --primary-color-lighter: color-mix(in lab, var(--primary-color), #FFF 40%);
  --primary-color-lightest: color-mix(in lab, var(--primary-color), #FFF 60%);
}
//-- COULEURS --//
$dark: #333;
$dark-grey: #6B6B6B;
$grey: #888;
$light-grey: #DDD;
$light: #F0F2F5;
$red: #990000;
$red-rgba: rgba(153, 0, 0, 0.5);
$orange: #FF9900;
$green: #009900;
$col-fb: #1778f2;
$col-cash: var(--primary-color); //#5F5AEF;

//-- FONTS --//
$font-1: "Poppins", sans-serif;
$font-2: "Roboto", sans-serif;
$font-3: "Open Sans", sans-serif;
$fa: "FontAwesome", sans-serif;
